import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BPCommonSideBar from "./components/BPCommonSideBar";
import FloorPlanDtlsBar from "./components/FloorPlanDtlsBar";
import PSSideBar from "./components/PSSideBar";
import { UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import FloorPlanDtls from "./components/FloorPlanDtls";
import "./BuildProject.css";
import LocationsSideBar from "./components/LocationsSideBar";
import CustomSelect from "../../components/constants/CustomSelect";
import ProductSideBar from "./components/ProductSideBar";
import QrcodeBeaconSideBar from "./components/QrCodeBeaconSideBar";
import TraversableSideBar from "./components/TraversablePathDtlsBar";
import TraversablePathTools from "./components/TraversablePathTools";
import AmenitySideBar from "./components/AmenitySideBar";
import SafetySideBar from "./components/safetySideBar";
import VerticalTransportSideBar from "./components/VerticalTransportSideBar";
import VerticalTransportModal from "./components/VerticalTransportModal";
import AdvertisementSideBar from "./components/AdvertisementSidebar";
import { ChangeSvgColorPassingBE } from "./CustomSvg";
import { useDrop } from "react-dnd";
import { postRequest, getRequest, getRequestForFile } from "../../hooks/axiosClient";
import { fabric } from "fabric";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environmentaldatas } from "../../constant/defaultValues";
import { MdPanTool } from "react-icons/md";
import { getCurrentUser, decode } from "../../helpers/utils";
import FontFaceObserver from "fontfaceobserver";
import { standardFontSize, standardFonts } from "../../components/constants/standardFonts";
const { image_url } = environmentaldatas;
import PaymentForm from "../../components/stripe/payment";
import Pencil from "../../assets/icons/pencil.png";
import Eraser from "../../assets/icons/erase-cursor-large.svg";

import { getVerticalOrHorizontalMove } from "./Helpers/getVerticalOrHorizontalMove";
import createCornerPoint from "./Helpers/createCornerPoint";
import {
  bringFabricObjectsToFrontByName,
  bringToFrontPinNameNodes,
  sendToBackObjects,
  removeFabricObjectsByName,
  removeFabricObjectsBId,
  HoverCursorChanger,
  changeSelectionAllObjs,
  changeFabricObjectSelectionByName,
  removeFabricObjectsEncId,
  reinitializeFabricCanvas,
  changePropertyById,
  getFabricObject,
  findObjectById,
  changeSelectionById,
  findPinNameGroup,
  findObjectByEnc_id,
} from "./Helpers/bringFabricObjects";
import {
  navigationPathZoomLevel,
  tracingLengthZoomLevel,
} from "./Helpers/tracingLengthZoomLevel";
import {
  calculateDistance,
  isInsideRadius,
  getPolygonVertices,
  getSquareCoordinates,
  getObjectSizeWithStroke,
  hexToRgb,
  normalizeValue,
  scaleVertices,
  getRectangleVertices,
  removeDuplicatePoints,
  getTypeByName,
  // lineToPolygon,
  getLineVertices,
  adjustPositionIfNeeded
} from "./Helpers/calculateDistance";
import { updateText, updateTracing, updateTracingCircle } from "./Helpers/updateText&Tracing";
import { anchorWrapper } from "./Helpers/anchorWrapper";
import {
  getAmenityPin,
  getBeaconPin,
  getLocationPin,
  getProductPin,
  getSafetyPin,
  getVerticalPin,
} from "./Helpers/getPinIcons";
import addPins from "./Helpers/addPins";
import generateNodeName from "./Helpers/generateNodeName";
import drawLine from "./Helpers/drawLine";
import addConnectionBtwnEdges from "./Helpers/addConnectionBtwnEdges";
import { removeLine, removeNode } from "./Helpers/removeLine&Node";
import nodePositionUpdate from "./Helpers/nodePositionUpdate";
import handleCursor from "./Helpers/handleCursor";
import controllPan from "./Helpers/controllPan";
import { dragNodeAndItsPath, dragNodeOnMainPath, dragPathAndItsNodes } from "./Helpers/dragNodeAndItsPath";
// import  dragNodeOnMainPath  from './Helpers/movePath';
import handleMouseWheel from "./Helpers/handleMouseWheel";
import { checkPinConnection, renderAmenity, renderBeacon, renderLocation, renderProduct, renderSafetie, renderText, renderTracing, renderTracingCircle, renderTraversiblePath, renderVT } from "./Helpers/renderObjs";
import initCanvas from "./Helpers/canvas/initCanvas";
import { addNodePoint, addPolyLine } from "./Helpers/addNodeOrLine";
import {
  dayMap,
  initialValues,
  objPinNames,
  objPinNamesOnly,
} from "./Helpers/constants/constant";
import {
  getAdvertisement,
  getAmenity,
  getBeacon,
  getFloors,
  getLocation,
  getProduct,
  getSafety,
  getSafetyIconDropDown,
  getTraversablePins,
  getVerticalTransport,
  getVerticalTransportCurrentFloor,
  getVerticalTransportIconDropDown,
} from "./Helpers/apis/getPins";
import {
  EnableDisable,
  discardClick,
  publishClick,
  revertPackage,
  totalPinCountApi,
  uploadTraversibleData,
} from "./Helpers/apis/otherApis";
import TotalPinsDiv from "./Helpers/pageDiv/TotalPinsDiv";
import deleteObjects from "./Helpers/deleteSelectedObjects";
import showObjLength from "./Helpers/fabric/showObjLength";
import {
  updateAmenityPin,
  updateBeaconPin,
  updateProductPin,
  updateSafetyPin,
  updateVerticalPin,
} from "./Helpers/updatePins";
import {
  editAd,
  editAmenity,
  editBeacon,
  editLocation,
  editProduct,
  editSafety,
  editVerticaltransport,
  handleTraversibleData,
} from "./Helpers/apis/PinsEdit";
import canvasBGimage from "./Helpers/canvas/canvasBGimage";
import canvasBackGroundColor from "./Helpers/canvas/canvasBGcolor";
import { dijkstra } from "./Helpers/algorithm/dijkstra";
import { dijkstraWithLength } from "./Helpers/algorithm/dijkstraWithLength";
import highligthNodes from "./Helpers/pathComponent/highligthNodes";
import pathLine from "./Helpers/pathComponent/pathLines";
import { onSelectVT } from "./Helpers/pathCalculations/checkPathCalculation";
import EditProjectModal from "./Helpers/pageDiv/editProjectNameModal";
import ProjectHeaderDiv from "./Helpers/pageDiv/headerDiv";
import CanvasDiv from "./Helpers/pageDiv/canvasDiv";
import Graph from "./Helpers/pathCalculations/graph";
import {
  highLightSelectedPaths,
  nodeLinesSelected,
} from "./Helpers/pathComponent/highLightSelectedPaths";
import { removeEmptyNode } from "./Helpers/removeEmptyNodes";
import ReferenceImageModal from "./Helpers/modal/ReferenceImageModal";
import moment from "moment";
import { map } from "lodash";

var obj,
  polyline,
  polyObj,
  mouseDown = false,
  mouseDown2 = false,
  mouseDownShape = false,
  mouseDownSelect = false,
  pts = [],
  lastPt = 1,
  originalObjCenterPoints,
  polyBtn,
  poly = false,
  bgColor,
  id = -1,
  draggingCanvas = false,
  lastPosX = 0,
  lastPosY = 0,
  key1,
  key2,
  lastTraversibleUndoIndex,
  activeText;
//------------------- restric subpath drawing -------------------
let nodeNameArray = []
//------------------- restric subpath drawing -------------------
let boundaryAttributes;
let drawingLine;
let prevSelectedBoundary;
let prevMouseClick;
let viewportTransform;
const graph = new Graph();
const ViewFloor = () => {
  let { id } = useParams();
  id = id && decode(id);

  let isEnterKey = false;

  let firstClick = {
    location: { x: null, y: null },
    verticalTransport: { x: null, y: null },
    safety: { x: null, y: null },
    amenity: { x: null, y: null },
    beacon: { x: null, y: null },
    product: { x: null, y: null },
  };

  const canvas = useRef(null);
  const canvasContainerRef = useRef(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("settings");
  const [isEdit, setIsEdit] = useState(false);
  const [selFloorPlanDtls, setSelFloorPlanDtls] = useState();
  const [floorPlans, setFloorPlans] = useState([]);
  const [floorPlansPathSort, setFloorPlansPathSort] = useState([]);
  const [floorPlanSelect, setFloorPlanSelect] = useState([]);
  const [floorID, setFloorID] = useState(null);
  const [floorIDs, setFloorIDs] = useState(null);
  const [traversibleHistory, setTraversibleHistory] = useState([]);
  const [dropDownFloor, setDropDownFloor] = useState();
  const [selLocationDtls, setSelLocationDtls] = useState({});
  const [selBeaconDtls, setSelBeaconDtls] = useState({});
  const [selAmenityDtls, setSelAmenityDtls] = useState({});
  const [selSafetyDtls, setSelSafetyDtls] = useState({});
  const [selVerticalDtls, setselVerticalDtls] = useState({});
  const [selAd, setSelAd] = useState({});
  const [locations, setLocations] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [beaconList, setBeaconList] = useState([]);
  const [amenityList, setAmenityList] = useState([]);
  const [safetyList, setSafetyList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [adList, setAdList] = useState([]);
  const [selProductDtls, setSelProductDtls] = useState({});
  const [products, setProducts] = useState([]);
  const [beacons, setBeacons] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [safeties, setSafeties] = useState([]);
  const [verticalTransports, setVerticalTransports] = useState([]);
  const [verticalTransportlist, setVerticalTransportlist] = useState([]);
  const [allVerticalTransports, setAllVerticalTransports] = useState([]);
  const [allPointsAndEdges, setAllPointsAndEdges] = useState([]);
  const [aminityIcons, setAminityIcons] = useState([]);
  const [verticalIcons, setVerticalIcons] = useState([]);
  const [safetyIcons, setSafetyIcons] = useState([]);
  const [totalPinsUsed, setTotalPinsUsed] = useState();
  const [tracings, setTracings] = useState([]);
  const [tracingCircle, setTracingCircle] = useState([]);
  const [tempPolygon, setTempPolygon] = useState([]);
  const [mapDivSize, setMapDivSize] = useState({ width: 0, height: 0 });
  const [projectSettings, setProjectSettings] = useState({});
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [addNewLocation, setAddNewLocation] = useState(false);
  const [addNewQrCodeBeacon, setAddNewQrCodeBeacon] = useState(false);
  const [addNewTraversablePath, setAddNewTraversablePath] = useState(true);
  const [addNewAmenity, setAddNewAmenity] = useState(false);
  const [addNewSafety, setAddNewSafety] = useState(false);
  const [addNewVertical, setAddNewVertical] = useState(false);
  const [addNewAd, setAddNewAd] = useState(false);
  const [showAlret, setShowAlret] = useState(false);
  const [addNewFloor, setAddNewFloor] = useState(false);
  const [selTracingId, setSelTracingId] = useState(false);
  const [tracingIntialValue, setTracingIntialValue] = useState({
    fill_color: null,
    border_color: null,
    border_thick: null,
  });
  const [textStyleValue, setTextStyleValue] = useState();
  const [toolActive, setToolActive] = useState("Draw");
  const [toolTraversible, setToolTraversible] = useState("Draw");
  const [selectedPaths, setSelectedPaths] = useState(false);
  const [posits, setPosits] = useState([]);
  const [dropValues, setDropValues] = useState([]);
  const [selTraversibleDetails, setSelTraversibleDetails] = useState();
  const [verticalFloorId, setVerticalFloorId] = useState(null);
  const [savingTimer, setSavingTimer] = useState(false);
  const [typeId, setTypeId] = useState("1");
  const [isPublish, setIsPublish] = useState("");
  const [isDiscard, setIsDiscard] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [canvasUpdated, setCanvasUpdated] = useState(false);
  const [zoomInOut, setZoomInOut] = useState(1);
  const [undoRedoPath, setUndoRedoPath] = useState({});
  const [texts, setTexts] = useState([]);
  const [selObject, setSelObject] = useState();
  const [currentZoom, setCurrentZoom] = useState(1);
  const [canvasCenter, setCanvasCenter] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [searchTerm, setSearchTerm] = useState("");
  const [panTool, setPanTool] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [images, setImages] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [isBoundary, setIsBoundary] = useState(false);
  const [hours, setHours] = useState(selLocationDtls?.hours ?? {});

  const validationSchema = Yup.object().shape({
    project_name: Yup.string().required("This field is required."),
  });
  const [projectSettingData, setProjectSettingData] = useState(initialValues);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalVertical, setModalVertical] = useState(false);


  // ------------------------- canvas clustering code -------------------------
  const [canvasObjectList, setCanvasObjectList] = useState(new Map());
  // const storedObjects = new Map();
  const [storedObjects, setStoredObjects] = useState(new Map());
  const [mouseTrigger, setMousetrigger] = useState(0)
  const cellSize = 250;
  const clustersMap = useRef(new Map());
  const zoomThreshold = 1;
  const visibleObjects = useRef(new Set());
  const cornersVisible = useRef(false);
  // ------------------------- canvas clustering code over -------------------------

  const [selectedObjects, setSelectedObjects] = useState([])


  const toggleVertical = () => {
    getProjectById();
    setModalVertical(!modalVertical);
  };

  const toggleVerticalClose = () => {
    const updatedVerticalTransport = verticalTransports?.map((item) => {
      const { noAccess, ...rest } = item;
      return rest; // Keep 'noAccess' property unchanged
    });
    setVerticalTransports(updatedVerticalTransport);
    setModalVertical(false);
  };

  const [planDetails, setPlanDetails] = useState();
  const [stripeModal, setStripeModal] = useState(false);
  const toggleStripe = () => setStripeModal(!stripeModal);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [isCommonSidebarVisible, setCommonSidebarVisible] = useState(true);

  const [floorPlanModal, setFloorPlanModal] = useState(false);
  const toggleReferanceImg = () => setFloorPlanModal(!floorPlanModal);
  const [selImageOrSvgValues, setSelImageOrSvgValues] = useState();
  const [fileKey, setFileKey] = useState(Date.now());
  const [loadingScale, setLoadingSacle] = useState(false);
  const [svgFile, setSvgFile] = useState();

  const dragPin = useRef(null)

  let messageContents = `<b>Step 1: Scan the QR code</b><br>`
  messageContents += `Open your device's camera and aim your camera at the QR code provided.<br>`
  messageContents += `Wait for the QR code to be recognised, and tap the link that appears on your screen.<br><br>`
  messageContents += `<b>Step 2: Select Your Destination</b><br>`
  messageContents += `On the main screen, tap the “To:” search box and select either the product or place that you are looking for.<br>`
  messageContents += `Once you've made your selection, review the details of the selected product or place and tap the "Set as destination" button to confirm.<br><br>`
  messageContents += `<b>Step 3: Get Directions</b><br>`
  messageContents += `After selecting your destination, tap the "Find my way" button to generate the best route to your chosen destination.<br>`
  messageContents += `Follow the displayed directions to reach your desired product or place.
`;
  const [prefilledMessage, setPrefilledMessage] = useState(messageContents);

  const onSideBarIconClick = (tabName, type) => {
    // setStripeModal(false);
    getProjectById();
    if (viewportTransform) {
      canvas.current.viewportTransform = viewportTransform;
    }

    if (tabName == "traversable") {
      if (floorPlanSelect?.length == 0) {
        toast.warning("Please select a floor plan to navigate.");
        return;
      }
    }
    if (tabName == "advertisements") {
      getAdvertisementList();
    }
    setActiveTab(tabName);
    setCommonSidebarVisible(false);
    // canvasBackgroundImageHandler(null);
    setSelObject();
    setSearchTerm("");
    // setActiveTab(tabName);
    setSelProductDtls();
    setSelLocationDtls();
    setSelBeaconDtls();
    setSelAmenityDtls();
    setSelSafetyDtls();
    setselVerticalDtls();
    setSelAd();
    setSelTraversibleDetails();
    // setTempPolygon([])
    setAddNewLocation(false);
    setAddNewProduct(false);
    setAddNewQrCodeBeacon(false);
    setAddNewAmenity(false);
    setAddNewSafety(false);
    setAddNewTraversablePath(true);
    setAddNewVertical(false);
    setAddNewAd(false);
    // setLocations([])
    // setProducts([])
    setPanTool(false);
    setFloorIDs(null);
    setAddNewFloor(false);
    if (type !== 1) {
      setVerticalFloorId(null);
    }
    obj = "";
    setToolActive(null);
    firstClick = {
      location: null,
      verticalTransport: null,
      safety: null,
      amenity: null,
      beacon: null,
      product: null,
    };
    setSavingTimer(false);
    if (tabName === "verticalTransport") {
      getVerticalTransportList(projectSettings?.enc_id);
    }
    if (tabName === "traversable") {
      getTraversablePins(id, setDropValues);
      setToolTraversible("Draw");
    }
    const lastAddedFloor = floorPlanSelect[0];
    // console.log("floors null", lastAddedFloor);

    const floor = floorPlanSelect.find((el) => el.enc_id == floorID);
    if (floorID) {
      // console.log("floors null2", lastAddedFloor);

      getFloorPlanByid(floorID, tabName, "0", "default");
      setDropDownFloor({
        value: floor?.enc_id,
        label: floor?.floor_plan,
        id: floor?.enc_id,
        plan: floor?.plan,
        dec_id: floor?.dec_id,
      });
    } else {
      if (lastAddedFloor) {
        console.log("floors null", lastAddedFloor);

        setFloorID(lastAddedFloor?.enc_id);
        getFloorPlanByid(lastAddedFloor?.enc_id, tabName, "0", "default");

        handleEnableDisable(lastAddedFloor?.enc_id);
        setDropDownFloor({
          value: lastAddedFloor?.enc_id,
          label: lastAddedFloor?.floor_plan,
          id: lastAddedFloor.enc_id,
          plan: lastAddedFloor.plan,
          dec_id: lastAddedFloor?.dec_id,
        });
      } else {
        console.log("floors null");
        setFloorID(null);
        handleEnableDisable(lastAddedFloor?.enc_id);
        setDropDownFloor();
      }
    }
    /* For bulk pinload */
    // if (floorPlans?.length === 0) {
    //   if (tabName === 'products') {
    //     getProductList()
    //   } else if (tabName === 'locations')
    //     getLocationList()
    // }
  };

  useEffect(() => {
    // Set the scroll position to the top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProjectById();
    getFloorDropdown();
    viewportTransform = undefined;
  }, [id]);

  useEffect(() => {
    if (id && floorPlanSelect) {
      const lastAddedFloor = floorPlanSelect[0];
      const floor = floorPlanSelect.find((el) => el.enc_id == floorID);
      if (floorID) {
        getFloorPlanByid(floorID, activeTab, "0", "default");
        setDropDownFloor({
          value: floor?.enc_id,
          label: floor?.floor_plan,
          id: floor?.enc_id,
          plan: floor?.plan,
          dec_id: floor?.dec_id,
        });
      } else {
        if (lastAddedFloor) {
          setFloorID(lastAddedFloor?.enc_id);
          getFloorPlanByid(lastAddedFloor?.enc_id, "0", "default");

          handleEnableDisable(lastAddedFloor?.enc_id);
          setDropDownFloor({
            value: lastAddedFloor?.enc_id,
            label: lastAddedFloor?.floor_plan,
            id: lastAddedFloor.enc_id,
            plan: lastAddedFloor.plan,
            dec_id: lastAddedFloor?.dec_id,
          });
        }
      }
    }
  }, [id, floorPlanSelect]);

  const clearPinsList = () => {
    setLocationList([]);
    setProductList([]);
    setBeaconList([]);
    setAmenityList([]);
    setSafetyList([]);
  };

  useEffect(() => {
    if (selFloorPlanDtls) {
      setDropDownFloor({
        value: selFloorPlanDtls?.enc_id,
        label: selFloorPlanDtls?.floor_plan,
        id: selFloorPlanDtls.enc_id,
        plan: selFloorPlanDtls.plan,
        dec_id: selFloorPlanDtls?.dec_id,
      });
    }

    if (selFloorPlanDtls?.enc_id) {
      // console.log(selFloorPlanDtls?.enc_id,"keys")
      setStoredObjects(prevState => {
        const newMap = new Map(
          // [...prevState].filter(([key, _]) => !key.split[1] == selFloorPlanDtls?.enc_id)
          [...prevState].filter(([key, _]) => {
            // console.log(key,selFloorPlanDtls?.enc_id,"keys")
            return key.includes(selFloorPlanDtls?.enc_id)
          })
        );
        // console.log(newMap,"newmap")
        // console.log(prevState,"newmap")
        return newMap;
      });
    }

  }, [selFloorPlanDtls]);

  const handleResize = () => {
    const { clientWidth, clientHeight } =
      window.document.getElementById("map-div");
    setMapDivSize({ height: clientHeight, width: clientWidth });
  };

  const onMapDivClick = (e) => {
    const { clientWidth, clientHeight } =
      window.document.getElementById("map-div");

    if (!mapDivSize.height) {
      setMapDivSize({ height: clientHeight, width: clientWidth });
    }
  };

  useEffect(() => {
    if (selProductDtls?.position) {
      setShowAlret(true);
    } else {
      setShowAlret(false);
    }
  }, [selProductDtls]);

  const onLevelDDChange = (selected) => {
    console.log(selected, "selected");
    setFloorID(null);
    if (selected) {

      setSearchTerm("");
      setSelTracingId();
      setVerticalFloorId(null);
      setFloorIDs();
      setFloorIDs(selected?.id);
      setFloorID(selected?.id);
      getFloorPlanByid(selected?.id, activeTab, "0", "default");
      setDropDownFloor(selected);
      handleEnableDisable(selected?.id);
      setSelProductDtls();
      setSelLocationDtls();
      setSelBeaconDtls();
      setSelAmenityDtls();
      setSelSafetyDtls();
      setSelAd();
      setselVerticalDtls();
      setAddNewAmenity(false);
      setAddNewLocation(false);
      setAddNewProduct(false);
      setAddNewQrCodeBeacon(false);
      setAddNewSafety(false);
      setAddNewVertical(false);
      setAddNewAd(false);
      resetCanvasTransform();
      setToolTraversible("Draw");
      setSelTraversibleDetails((prev) => ({
        ...prev,
        is_miltiple: false,
        isNext: null,
      }));
    }
  };

  const onLevelDDChangeVT = (selected) => {
    console.log(selVerticalDtls, "selVerticalDtls");
    if (
      selVerticalDtls?.connectionPins[
        selVerticalDtls?.connectionPins.length - 1
      ]?.value &&
      !selVerticalDtls?.connectionPins[
        selVerticalDtls?.connectionPins.length - 1
      ]?.position
    ) {
      toast.warning("Please click on map to add Vertical Transport");
      return;
    }
    setVerticalFloorId(null);
    setFloorIDs(selected?.id);
    setFloorID(selected?.id);
    getFloorPlanByid(selected?.id, activeTab, "0", "default");
    setDropDownFloor(selected);
    handleEnableDisable(selected?.id);
  };

  useEffect(() => {
    // getVerticalTransportList()
    if (addNewVertical) {
      getFloorPlanByid(verticalFloorId ?? floorID, activeTab, "0", "default");
      setLocations([]);
      setProducts([]);
      setBeacons([]);
      setAmenities([]);
      setSafeties([]);
      setTracings([]);
      setTexts([]);
      setVerticalTransports([]);
    }
  }, [verticalFloorId]);

  const onMove = useCallback(
    (key, left, top, type, idx) => {
      if (type == "VCP") {
        onDragFinishedPVCP(key, idx, left, top);
      }
    },
    [setTracings, tracings]
  );
  const onDragFinishedPVCP = (polygonId, coordinatesId, left, top) => {
    let tempCoordinates = [...tracings[polygonId]];

    tempCoordinates[coordinatesId].xa = mapDivSize.width / left;
    tempCoordinates[coordinatesId].ya = mapDivSize.height / top;
    let tempPolygons = [...tracings];
    tempPolygons[polygonId] = tempCoordinates;
    setTracings([...tempPolygons]);
  };

  const [, drop] = useDrop(
    () => ({
      accept: "node",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = item.left + delta?.x;
        const top = item.top + delta?.y;
        onMove(item.id, left, top, item.type, item.idx);
        return undefined;
      },
    }),
    [onMove]
  );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getFloorDropdown = async (type) => {
    try {
      const response = await getRequest(`dropdown-floor-plan/${id}`);
      const data = response.data ?? [];
      setFloorPlanSelect(data);
      if (type == "discard") {
        const lastAddedFloor = data[0];
        if (lastAddedFloor) {
          getFloorPlanByid(lastAddedFloor?.enc_id, activeTab, "0", "default");
          setDropDownFloor({
            value: lastAddedFloor?.enc_id,
            label: lastAddedFloor?.floor_plan,
            id: lastAddedFloor?.enc_id,
            plan: lastAddedFloor?.plan,
            dec_id: lastAddedFloor?.dec_id,
          });
        } else {
          if (data?.length === 0) {
            setDropDownFloor(null);
            reinitializeFabricCanvas(canvas);
            handleTraversibleData(null, graph, setSelTraversibleDetails, findShortestPath, renderTraversiblePaths, selTraversibleDetails);
          }
          reinitializeFabricCanvas(canvas);
          setDropDownFloor(null);
          removeListItems("noFloors");
        }
      } else {
        if (data?.length === 0) {
          setDropDownFloor(null);
        }
        reinitializeFabricCanvas(canvas);
        removeListItems();
      }
    } catch (error) {
      ////// console.log(error);
    }
  };

  const removeListItems = (type) => {
    setTracings([]);
    setTracingCircle([]);
    setTexts([]);
    setLocations([]);
    setProducts([]);
    setBeacons([]);
    setAmenities([]);
    setSafeties([]);
    setVerticalTransports([]);

    if (type === "noFloors") {
      setLocationList([]);
      setProductList([]);
      setBeaconList([]);
      setAmenityList([]);
      setSafetyList([]);
    }
  };

  const removePins = () => {
    removeFabricObjectsByName(canvas, "product");
    removeFabricObjectsByName(canvas, "location");
    removeFabricObjectsByName(canvas, "boundary");
    removeFabricObjectsByName(canvas, "amenity");
    removeFabricObjectsByName(canvas, "beacon");
    removeFabricObjectsByName(canvas, "safety");
    setLocations([]);
    setProducts([]);
    setBeacons([]);
    setAmenities([]);
    setSafeties([]);
    setVerticalTransports([]);
  };

  useEffect(() => {
    getFloorDropdown();
  }, []);

  const getFloorPlanByid = async (id, tab, val, type, pinsId) => {
    let value;
    try {
      setAddNewFloor(false);
      const response = await getRequest(`floor-plan/${id}`);
      const data = response.data ?? [];

      value = {
        ...data,
        floor_plan: data?.floor_plan,
        refImg: data?.cropped_path_base64,
        plan: data?.cropped_image,
        border_color: data?.border_color,
        fill_color: data?.fill_color,
        border_thick: data?.border_thick,
        width: data?.width ? Number(data?.width) : null,
        height: data?.height ? Number(data?.height) : null,
      };
      const decodedTexts = JSON.parse(data?.text);
      var decodedString = JSON.parse(data?.tracings);
      var arrayOfObjects = JSON.parse(decodedString);
      var arrayOfTexts = decodedTexts ? JSON.parse(decodedTexts) : "";

      var decodedCircle = JSON.parse(data?.circle_data);
      var objectCircle = JSON.parse(decodedCircle);

      const modifiedData = data?.vertical_transports?.map((el) => ({
        ...el,
        position: el?.positions ? JSON.parse(el?.positions) : "",
      }));
      setTexts(arrayOfTexts ?? []);
      setZoomInOut(data?.img_size ? JSON.parse(data?.img_size) : zoomInOut);
      setVerticalTransports(modifiedData);
      setTracings(arrayOfObjects ?? []);
      setTracingCircle(objectCircle ?? [])
      setTempPolygon([]);
      setIsEdit(true);
      // setAddNew(true)
      if (val === "0") {
        // console.log(value,"flrplndtls");
        setSelFloorPlanDtls(value);
      }
      handleTraversibleData(
        value,
        graph,
        setSelTraversibleDetails,
        findShortestPath,
        renderTraversiblePaths,
        selTraversibleDetails
      );
      if (tab == "floorDetails" && type !== "default") {
        setAddNewFloor(true);
        setToolActive("Draw");
      } else {
        setAddNewFloor(false);
      }
      // if (tab === "floorDetails" && type !== "default") {
      // canvasBackgroundImageHandler(value?.plan);
      // } else {
      //   canvasBackgroundImageHandler(null);
      // }
      getSvgFileAsRefImage(value?.enc_id)
      if (value?.show_image == 1 && value?.plan) {
        canvasBackgroundImageHandler(value?.plan);
      } else {
        canvasBackgroundImageHandler(null);
      }
      stopPathDrawing();
    } catch (error) {
      ////// console.log(error);
    } finally {
      if (val !== "1") {
        getLocationList(id);
        getProductList(id);
        getBeaconList(id);
        getAmenityList(id);
        getSafetyList(id);
      }

      getVerticalTransportList(projectSettings?.enc_id);
      // getAdvertisementList();

      if (pinsId) {
        const delay = 800;
        setTimeout(() => {
          checkEditPin(pinsId, tab);
        }, delay);
        setSelFloorPlanDtls(value);
      }
    }
  };

  const getSvgFileAsRefImage = async (enc_id) => {
    try {
      const response = await getRequest(`get-svg/${enc_id}`);
      const svgBlob = response.data;
      // console.log(svgBlob, 'getSvgFileAsRefImage')
      setSvgFile(svgBlob)
      setSelFloorPlanDtls((prev) => ({
        ...prev,
        get_svg: svgBlob
      }))
    } catch (error) {
    }
  }

  const checkEditPin = (pinsData, tab) => {
    setFloorID(pinsData?.fp_id);
    if (tab === "locations") {
      onEditLocation(pinsData);
    } else if (tab === "products") {
      onEditProduct(pinsData);
    } else if (tab === "beacons") {
      onEditBeacon(pinsData);
    } else if (tab === "amenitys") {
      onEditAmenity(pinsData);
    } else if (tab === "safety") {
      onEditSafety(pinsData);
    } else if (tab === "advertisements") {
      onEditAd(pinsData);
    }
  };

  const getLocationList = async (floor) => {
    getLocation(floor, id, setLocations, setLocationList);
  };

  const getProductList = async (floorID) => {
    getProduct(floorID, id, setProducts, setProductList);
  };

  const getBeaconList = async (floorID) => {
    getBeacon(floorID, id, setBeacons, setBeaconList);
  };

  const getAmenityList = async (floorID) => {
    getAmenity(floorID, id, setAmenities, setAmenityList);
  };

  const getSafetyList = async (floorID) => {
    getSafety(floorID, id, setSafeties, setSafetyList);
  };

  const getVerticalTransportList = async (floorID) => {
    getVerticalTransport(floorID, setVerticalTransportlist);
  };


  const getAdvertisementList = async () => {
    getAdvertisement(id, setAdList);
  };

  useEffect(() => {
    totalPinCount();
  }, [locations, products, beacons]);

  const totalPinCount = async () => {
    totalPinCountApi(id, setTotalPinsUsed);
  };

  const renderTracings = (tracingArray) => {
    let toolActive;
    setToolActive((prev) => {
      toolActive = prev;
      return prev;
    });

    let trace = tracingArray ?? tracings;
    if (trace?.length > 0) {
      renderTracing(
        canvas,
        trace,
        projectSettings,
        toolActive,
        addNewFloor,
        activeTab
      );
    }
  };


  const renderRectangle = (rectangles) => {
    if (rectangles.length > 0) {
      rectangles.forEach((rect, idx) => {
        const centerX = rect.left;
        const centerY = rect.top;

        let polyObj = new fabric.Rect({
          name: 'rect',
          left: centerX, // Use the position directly
          top: centerY, // Use the position directly
          width: rect.width ?? 0,
          height: rect.height ?? 0,
          fill: rect.fill_color,
          stroke: rect.border_color,
          strokeWidth: Number(rect.border_thick),
          originX: "center", // Set origin to center for correct rotation
          originY: "center",
          angle: rect.angle || 0, // Apply angle
          selectable: false,
          evented: false,
          strokeLineJoin: 'bevil',
          hoverCursor: `crosshair`,
          scaleX: rect.scaleX,
          scaleY: rect.scaleY,
        });

        console.log(polyObj, 'renderRectangles');
        setTimeout(() => {
          canvas?.current?.add(polyObj).renderAll();
        }, 4000);
      });
    }
  };


  const renderTracingCircles = (tracingArray) => {
    let toolActive;
    setToolActive((prev) => {
      toolActive = prev;
      return prev;
    });

    let trace = tracingArray ?? tracingCircle
    if (trace?.length > 0) {
      renderTracingCircle(canvas, trace, projectSettings, toolActive, addNewFloor, activeTab);
    }
  }

  const renderTexts = (textArray) => {
    let toolActive;
    setToolActive((prev) => {
      toolActive = prev;
      return prev;
    });
    let textObjs = textArray ?? texts
    if (texts?.length > 0) {
      renderText(canvas, textObjs, toolActive, addNewFloor, activeTab);
    }
  };

  const renderLocations = () => {
    if (locations?.length > 0) {
      renderLocation(
        canvas,
        locations,
        projectSettings,
        activeTab,
        addNewLocation,
        selLocationDtls,
        checkConditionDrag
      );
    }
  };

  const [, dropProduct] = useDrop({
    accept: 'productpin',
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const pointer = canvas.current?.getPointer({ clientX: clientOffset.x, clientY: clientOffset.y });
      console.log(clientOffset, monitor, pointer, item, 'clientOffset')
      // getFloorPlanByid(item?.fp_id, 'products', "0", "default", item?.product);
      // return
      const prod = item?.product;
      console.log(prod, 'dropProduct')
      let obj
      let fillColor = prod?.product_color ?? projectSettings?.product_color;
      let productIcon = getProductPin(fillColor)
      let path = fabric.loadSVGFromString(
        productIcon,
        function (objects, options) {
          obj = fabric.util.groupSVGElements(objects, options);
          obj.set({
            left: pointer?.x - obj.width / 2,
            top: pointer?.y - obj.height / 2,
            selectable: false,
            name: "product",
            id: prod.product_name,
            enc_id: prod?.enc_id,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            hoverCursor:
              activeTab === "products" && addNewProduct ? "grab" : "default"
          });
          canvas.current.add(obj).renderAll();
        }
      );
      console.log(obj, 'selProductDtls')
      const specArray = prod?.specifications ? JSON.parse(prod?.specifications) : [];
      const filteredSpecificationsArray = specArray;
      const TagsArray = JSON.parse(prod?.tags);
      const specification = prod?.specifications ? filteredSpecificationsArray : [];
      setSelProductDtls((prev) => ({
        ...prev,
        ...prod,
        enc_id: prod?.enc_id,
        product_name: prod?.product_name,
        product_color: fillColor,
        position: { x: pointer?.x, y: pointer?.y },
        isDrop: true,
        tags: TagsArray === null ? [] : TagsArray,
      }));
      setSpecifications(specification);
      setPanTool(false)
      // setTimeout(() => {
      document.getElementById("productSubmitBtn").click();
      // console.log(button, 'button')
      // }, 1000);
    },
  });

  const [, dropLocation] = useDrop({
    accept: 'LocationPin',
    drop: (item, monitor) => {
      console.log(item, 'LocationPin')
      const clientOffset = monitor.getClientOffset();
      const pointer = canvas.current?.getPointer({ clientX: clientOffset.x, clientY: clientOffset.y });
      console.log(clientOffset, monitor, pointer, item, 'clientOffset')
      // getFloorPlanByid(item?.fp_id, 'products', "0", "default", item?.product);
      // return
      const prod = item?.location;
      let obj
      let fillColor = prod?.location_color ?? projectSettings?.location_color;
      let productIcon = getLocationPin(fillColor)
      let path = fabric.loadSVGFromString(
        productIcon,
        function (objects, options) {
          obj = fabric.util.groupSVGElements(objects, options);
          obj.set({
            left: pointer?.x - obj.width / 2,
            top: pointer?.y - obj.height / 2,
            selectable: false,
            name: "location",
            id: prod.location_name,
            enc_id: prod?.enc_id,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            hoverCursor:
              activeTab === "locations" && addNewLocation ? "grab" : "default"
          });
          canvas.current.add(obj).renderAll();
        }
      );

      let promotionData = prod.promotions ? JSON.parse(prod.promotions) : [];
      promotionData?.forEach((el) => {
        el.image_path = el.image_path ? image_url + el.image_path : null;
        el.start_date = el.start_date ? moment(el.start_date).toDate() : "";
        el.end_date = el.end_date ? moment(el.end_date).toDate() : "";
      });
      setPromotions(promotionData);
      const converted = {};
      Object.keys(dayMap).forEach((day) => {
        const isOpen = prod[`${day}_open`] == 1;
        if (isOpen) {
          const dayPrefix = dayMap[day];
          0;
          const from = prod[`${day}_start`];
          const to = prod[`${day}_end`];
          converted[dayPrefix] = { from, to };
        }
      });
      setHours(converted ?? {});
      setSelLocationDtls((prev) => ({
        ...prev,
        ...prod,
        enc_id: prod?.enc_id,
        location_name: prod?.location_name,
        location_color: fillColor,
        position: { x: pointer?.x, y: pointer?.y },
        isDrop: true,
        tags: prod.tags ? JSON.parse(prod.tags) : [],
      }));
      setIsBoundary(false)
      setPanTool(false)
      // setTimeout(() => {
      document.getElementById("locationSubmitBtn")?.click();
      // console.log(button, 'button')
      // }, 1000);
    },
  });

  const renderProducts = () => {
    // let Tempproducts
    // setProducts((prev) => {
    //   Tempproducts = prev;
    //   return prev;
    // });
    const filterPin = products.filter(item => item.position !== null);
    // const filterPinTemp = Tempproducts?.filter(item => item.position !== null);
    // console.log(products, 'products modifiedData')
    // let productPins = tempProduct ?? products
    if (products.length > 0) {
      renderProduct(
        canvas,
        products,
        projectSettings,
        activeTab,
        addNewProduct,
        selProductDtls,
        checkConditionDrag
      );
    }
  };

  const renderBeacons = () => {
    if (beacons?.length > 0) {
      renderBeacon(
        canvas,
        beacons,
        projectSettings,
        activeTab,
        addNewQrCodeBeacon,
        selBeaconDtls,
        checkConditionDrag
      );
    }
  };

  const renderAmenitys = () => {
    if (amenities?.length > 0) {
      renderAmenity(
        canvas,
        amenities,
        projectSettings,
        activeTab,
        addNewAmenity,
        selAmenityDtls,
        amenityList,
        selFloorPlanDtls,
        checkConditionDrag
      );
    }
  };

  const renderSafeties = () => {
    if (safeties.length > 0) {
      renderSafetie(
        canvas,
        safeties,
        projectSettings,
        activeTab,
        addNewSafety,
        selSafetyDtls,
        checkConditionDrag
      );
    }
  };

  const renderVerticalTransport = () => {
    if (verticalTransports.length > 0) {
      renderVT(
        canvas,
        verticalTransports,
        projectSettings,
        activeTab,
        addNewVertical,
        selVerticalDtls,
        verticalFloorId,
        checkConditionDrag
      );
    }
  };

  const renderTraversiblePaths = (type, visible = false, mouseDown = false) => {
    removeFabricObjectsByName(canvas, "node");
    removeFabricObjectsByName(canvas, "path");
    if (activeTab === "traversable" && !isCommonSidebarVisible) {
      renderTraversiblePath(
        canvas,
        graph,
        projectSettings,
        checkPinConnectOrNot,
        type,
        visible,
        mouseDown
      );
    }
  };

  const checkPinConnectOrNot = () => {
    // console.log(graph, 'graph')
    checkPinConnection(canvas, graph, activeTab, isCommonSidebarVisible)
  };

  const deleteSubPath = (obj) => {
    // console.log(obj, 'checkNodePinOrNot')
    if (obj.name === 'node') {
      let edges = graph.getEdges();
      const linesDeleted = Object.keys(edges[obj?.id])
      const sortNode = linesDeleted.filter(item => !item.includes('_'))
      const includePin = linesDeleted.some(item => item.includes('_'))
      console.log(sortNode, linesDeleted, 'linesDeleted')
      if (includePin && sortNode?.length === 2) {
        const node1 = sortNode[0]
        const node2 = sortNode[1]
        addConnectionBtwnEdges(node1, node2, graph, canvas);
      }
    } else if (obj?.name === 'path' && obj?.id?.includes('_')) {
      const key1 = obj?.id.split("$")[1];
      const key2 = obj?.id.split("$")[2];
      let edges = graph.getEdges();
      // console.log()
      let checkNodePinOrNot = null;
      if (!(key1?.includes('_'))) {
        checkNodePinOrNot = key1
      } else if (!(key2?.includes('_'))) {
        checkNodePinOrNot = key2
      }
      console.log(checkNodePinOrNot, 'checkNodePinOrNot')
      if (checkNodePinOrNot) {
        const linesDeleted = Object.keys(edges[checkNodePinOrNot] || {});
        console.log(key1, key2, graph, linesDeleted, 'checkNodePinOrNot')
        const node1 = linesDeleted[0];
        const node2 = linesDeleted[1];
        if (linesDeleted?.length >= 2) {
          addConnectionBtwnEdges(node1, node2, graph, canvas);
        }
        removeNode(checkNodePinOrNot, true, graph, canvas);
      }
    }
  }

  /* Almost correct */
  const generateAutoConnections = () => {
    // console.log(selFloorPlanDtls.enc_id, "selFloorPlanDtls")
    let currentFloorId = selFloorPlanDtls.enc_id

    renderTraversiblePaths(undefined, true)
    const positions = graph.positions;
    const edges = graph.edges
    const pins = []
    const paths = []

    // console.log(edges, 'edffff')
    const connectedPins = new Set();
    for (let node in graph.edges) {
      // console.log(graph.edges[node], 'ppppp')
      Object.keys(graph.edges[node]).forEach(neighbor => {
        if (positions[neighbor]) {
          connectedPins.add(neighbor);
        }
      });
    }
    // const connectedLines = findConnectedLines(connectedPins);
    if (connectedPins.size === 0) {
      toast.warning('Please draw the main path first, then try again to generate auto subpaths.')
      return
    }
    const newPoints = []
    const visibleArea = updateVisibleArea(canvas.current);

    // canvas.current.forEachObject(obj => {
    let objectArray = Array.from(storedObjects?.values()).flat();
    objectArray.forEach((obj) => {
      if (objPinNamesOnly.includes(obj.name) && obj.types !== 'text_field' && obj.fp_id === currentFloorId) {
        // if (isInsideViewport({ x: obj.left, y: obj.top }, visibleArea)) {
        const pinName = `${obj.name}_${obj.enc_id}`;
        const isConnected = connectedPins.has(pinName);
        if (!isConnected) {
          const point = obj?.getCenterPoint()
          let shortDistance;
          let shortDistPoint;
          let originalLine;

          if (point?.x == 0 && point?.y == 0) return

          Object.keys(edges)?.forEach(key => {
            Object.keys(edges[key])?.forEach(key2 => {
              const lineCoord1 = positions[key]
              const lineCoord2 = positions[key2]
              const shortestPoint = shortestDistanceBetweenLineAndPoint(lineCoord1?.x, lineCoord1?.y, lineCoord2?.x, lineCoord2?.y, point?.x, point?.y, graph.positions)
              if (!key.includes("_") && !key2.includes("_")) {
                if (shortDistance && shortDistPoint) {
                  if (shortDistance > shortestPoint?.distance) {

                    shortDistPoint = shortestPoint?.closestPoint
                    shortDistance = shortestPoint?.distance
                    originalLine = findObjectById(`path$${key}$${key2}`, canvas) || findObjectById(`path$${key2}$${key}`, canvas) || null;
                  }
                  // console.log(originalLine?.id,findObjectById(`path$${key}$${key2}`, canvas), 'shortestDistanceBetweenLineAndPoint')
                } else {
                  shortDistPoint = shortestPoint.closestPoint
                  shortDistance = shortestPoint.distance,
                    originalLine = findObjectById(`path$${key2}$${key}`, canvas) || null;
                  // console.log(originalLine?.id, 'shortestDistanceBetweenLineAndPoint')
                }
              }
            })
          })

          newPoints.push({
            point: shortDistPoint,
            pinName,
            pinPoint: point,
            originalLine: originalLine
          })

        }
        // } else {
        //   // console.log("object is out of viewport")
        // }
      }
    })

    processAndDrawConnections(newPoints, canvas);
    //.current = false;
  };

  const isInsideViewport = (object, viewport) => {
    return (
      object?.x >= viewport?.x1 &&
      object?.x <= viewport?.x2 &&
      object?.y >= viewport?.y1 &&
      object?.y <= viewport?.y2
    );
  }



  const processAndDrawConnections = (newPoints, canvas) => {
    // console.log(newPoints,"newPointsnewPoints")

    const lineAndPoints = {}
    let sortedNodesArray = []
    newPoints?.forEach(pointDetails => {
      const pointToCreate = pointDetails?.point;
      const pointName = pointDetails?.pinName
      const pinCoords = pointDetails?.pinPoint
      let nodeName = generateNodeName(graph);
      onCreateNode(pointToCreate, nodeName);
      // console.log(pointDetails?.originalLine, 'pointDetails.originalLine')
      const lineId = pointDetails?.originalLine?.id
      console.log(lineId, "lineId")
      if (lineId) {
        if (lineAndPoints[lineId]) {
          lineAndPoints[lineId]?.push({ coord: pointToCreate, pointName: nodeName, pinName: pointName, pinCoords: pinCoords })
        } else {
          lineAndPoints[lineId] = [{ coord: pointToCreate, pointName: nodeName, pinName: pointName, pinCoords: pinCoords }]
        }
      }

    })
    // console.log(Object.keys(lineAndPoints), 'lineAndPoints')
    Object.keys(lineAndPoints)?.forEach(lineId => {
      const nodesOnLine = lineAndPoints[lineId]
      const [node1, node2] = lineId?.replace('path$', '')?.split('$');
      const lineStartPoint = graph?.positions[node1]
      const lineEndPoint = graph?.positions[node2]
      const sortedNodes = sortCoordinatesAlongLine(nodesOnLine, lineStartPoint, lineEndPoint)
      // console.log(sortedNodes, 'sortedNodes')
      sortedNodesArray = [...sortedNodesArray, ...sortedNodes];
      sortedNodes?.forEach((node, index) => {
        if (index == 0) {
          addConnectionBtwnEdges(node1, node.pointName, graph, canvas);
        } else {
          addConnectionBtwnEdges(sortedNodes[index - 1].pointName, node.pointName, graph, canvas);
        }
      })
      addConnectionBtwnEdges(sortedNodes[sortedNodes?.length - 1]?.pointName, node2, graph, canvas);
      removeFabricObjectsBId(canvas, lineId)
      const oppositeId = `path$${node2}$${node1}`
      removeLine(lineId, graph, canvas);
      removeLine(oppositeId, graph, canvas);
    })
    connetPinAndNodes(sortedNodesArray)
    setOriginalInetoNode(lineAndPoints)

    renderTraversiblePaths(undefined, true)
    setSelTraversibleDetails((prev) => ({
      ...prev,
      edges_data: graph.getEdges(),
      points_data: graph.getPositions(),
      post: true
    }));
    stopPathDrawing();
  };

  const connetPinAndNodes = (newPoints) => {
    // console.log(newPoints, 'sortedNodesArray')
    newPoints?.forEach(pointDetails => {
      const pointToCreate = pointDetails?.pinCoords;
      const pinName = pointDetails?.pinName
      const nodeCoords = pointDetails?.coord
      const nodeName = pointDetails?.pointName
      onCreateNode(pointToCreate, pinName);
      addConnectionBtwnEdges(pinName, nodeName, graph, canvas);
    })
  }

  const setOriginalInetoNode = (lineAndPoints) => {
    // let nodeMap = {};
    // Object.keys(lineAndPoints).forEach((path) => {
    //   lineAndPoints[path].forEach((ele) => {
    //     const nodeName = ele?.pointName;
    //     nodeMap[nodeName] = path;
    //     // graph.addSubPath(nodeName, path)
    //   });
    // });
    // console.log(nodeMap, 'setOriginalInetoNode');

  }


  function shortestDistanceBetweenLineAndPoint(x1, y1, x2, y2, x0, y0, positions, threshold = 1e-5) {
    // Calculate the components of the line vector
    const A = x0 - x1;
    const B = y0 - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    // Calculate the dot product of the point and the line vector
    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = dot / len_sq;

    // Find the point on the line closest to the given point
    let xx, yy;

    if (param < 0 || (x1 === x2 && y1 === y2)) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    // Check if the closest point is overlapping with any existing point
    const isOverlapping = isPointOverlapping(xx, yy, positions, threshold);
    if (isOverlapping) {
      // Slightly adjust the closest point to avoid overlap
      // You can adjust along the line vector or perpendicular to it
      xx += threshold;
      yy += threshold;
    }

    // Calculate the distance from the point to the closest point on the line
    const dx = x0 - xx;
    const dy = y0 - yy;
    const distance = Math.sqrt(dx * dx + dy * dy);

    return {
      distance: distance,
      closestPoint: { x: xx, y: yy }
    };
  }

  function isPointOverlapping(x, y, positions, threshold) {
    for (let key in positions) {
      const pos = positions[key];
      const dist = Math.sqrt((x - pos.x) ** 2 + (y - pos.y) ** 2);
      if (dist < threshold) {
        return true;
      }
    }
    return false;
  }

  function findConnectedLines(nodes) {
    let edges = graph.getEdges();
    let pathSet = new Set();

    nodes.forEach((node) => {
      let id = node;
      if (edges[id]) {
        // console.log(edges[id], 'edges[id]')
        Object.keys(edges[id]).forEach((key) => {
          let path1 = findObjectById(`path$${key}$${id}`, canvas);
          let path2 = findObjectById(`path$${id}$${key}`, canvas);
          if (path1) {
            pathSet.add(path1);
          }
          if (path2) {
            pathSet.add(path2);
          }
        });
      }
    });
    return Array.from(pathSet);
  }

  /* Sir */
  // const generateAutoConnections = () => {
  //   const positions = graph.positions;
  //   const connectedPins = new Set();

  //   // Populate connectedPins set
  //   for (let node in graph.edges) {
  //     Object.keys(graph.edges[node]).forEach(neighbor => {
  //       if (positions[neighbor]) {
  //         connectedPins.add(neighbor);
  //       }
  //     });
  //   }

  //   const connectedLines = findConnectedLines(connectedPins);

  //   canvas.current.forEachObject(obj => {
  //     if (objPinNamesOnly.includes(obj.name) && obj.types !== 'text_field') {
  //       const pinName = `${obj.name}_${obj.enc_id}`;
  //       const isConnected = connectedPins.has(pinName);

  //       if (!isConnected) {
  //         const position = obj.getCenterPoint();
  //         let nearestPoint = null;
  //         let minDistance = Infinity;
  //         let nearestLine = null;

  //         console.log(`Processing ${pinName} at position:`, position);

  //         connectedLines.forEach(line => {
  //           const polylinePoints = extractPolylinePoints(line);
  //           for (let i = 0; i < polylinePoints.length - 1; i++) {
  //             const start = polylinePoints[i];
  //             const end = polylinePoints[i + 1];
  //             const nearest = nearestPointOnSegment(start, end, position);
  //             const distance = calculateDistance(position, nearest);

  //             console.log(`Distance from ${pinName} to segment ${start} -> ${end} is ${distance}`);

  //             if (distance < minDistance) {
  //               nearestPoint = nearest;
  //               nearestLine = line;
  //               minDistance = distance;
  //             }
  //           }
  //         });

  //         if (nearestLine) {
  //           let nodeName = generateNodeName(graph);
  //           let existingNode = null;

  //           for (let [key, value] of Object.entries(graph.positions)) {
  //             if (Math.abs(value.x - nearestPoint.x) < 0.001 && Math.abs(value.y - nearestPoint.y) < 0.001) {
  //               existingNode = key;
  //               break;
  //             }
  //           }

  //           console.log(`Nearest point for ${pinName} is ${nearestPoint}. Existing node: ${existingNode}`);
  //           if (existingNode) {
  //             // Connect the pin to the existing node
  //             createNodeBtwPolylineAuto({ target: nearestLine }, nearestPoint, nearestLine, existingNode, 'auto');
  //           } else {
  //             onCreateNode(position, pinName);
  //             createNodeBtwPolylineAuto({ target: nearestLine }, nearestPoint, nearestLine, null, 'auto');
  //           }

  //           // Explicitly draw connection line
  //           // drawLine(position, nearestPoint, 'connection', `connection${pinName}_${nodeName}`, canvas);

  //           graph.restoreEdges(graph.edges);
  //           graph.restorePositions(graph.positions);
  //           renderTraversiblePaths();
  //         } else {
  //           console.warn(`No nearest line found for ${pinName}`);
  //         }
  //       }
  //     }
  //   });

  //   renderTraversiblePaths();
  // };

  // function extractPolylinePoints(line) {
  //   return [
  //     { x: line.x1, y: line.y1 },
  //     { x: line.x2, y: line.y2 }
  //   ];
  // }

  // const createNodeBtwPolylineAuto = (e, mouse, lineData, existingNode, type) => {
  //   removeLine(e?.target?.id, graph, canvas, "nodeBTWline");

  //   let nodeName = existingNode ?? generateNodeName(graph);

  //   if (lastTraversibleUndoIndex !== undefined) {
  //     setTraversibleHistory((prev) =>
  //       prev.splice(lastTraversibleUndoIndex - 1)
  //     );
  //     lastTraversibleUndoIndex = undefined;
  //   }

  //   if (!e.target || ["backgroundRect", "tracing", "boundary", "text", "path"].includes(e.target?.name)) {
  //     if (!existingNode) {
  //       let node = addNodePoint(mouse, nodeName);
  //       canvas.current.add(node);

  //       let center = e.target?.getCenterPoint();
  //       const coords = e.target && !["backgroundRect", "line_starter_poly", "tracing", "path", "boundary", "text"].includes(e.target.name)
  //         ? { x: center?.x, y: center?.y }
  //         : { x: mouse?.x, y: mouse?.y };

  //       poly = false;
  //       polyBtn = "";
  //       lastPt = 1;
  //       pts = [];
  //       polyline = addPolyLine(pts, 'line_starter_poly');
  //       canvas.current.add(polyline);
  //       onCreateNode(coords, nodeName);
  //       addConnectionBtwnEdges(key1, key2, graph, canvas);
  //     }

  //     const [node1, node2] = lineData?.id.split("$").slice(1);
  //     let node1position = posits[node1];
  //     let node2position = posits[node2];

  //     graph.addNode(node1);
  //     graph.addEdge(nodeName, node1);
  //     graph.addEdge(node2, nodeName);

  //     drawLine(
  //       { x: mouse.x, y: mouse.y },
  //       node1position,
  //       "path",
  //       `path$${node1}$${nodeName}`,
  //       canvas
  //     );
  //     drawLine(
  //       { x: mouse.x, y: mouse.y },
  //       node2position,
  //       "path",
  //       `path$${node2}$${nodeName}`,
  //       canvas
  //     );
  //     stopPathDrawing();
  //   }
  // };

  // function nearestPointOnSegment(segmentStart, segmentEnd, point) {
  //   const l2 = Math.pow(segmentEnd.x - segmentStart.x, 2) + Math.pow(segmentEnd.y - segmentStart.y, 2);
  //   if (l2 === 0) return segmentStart;
  //   let t = ((point.x - segmentStart.x) * (segmentEnd.x - segmentStart.x) + (point.y - segmentStart.y) * (segmentEnd.y - segmentStart.y)) / l2;
  //   t = Math.max(0, Math.min(1, t));
  //   return {
  //     x: segmentStart.x + t * (segmentEnd.x - segmentStart.x),
  //     y: segmentStart.y + t * (segmentEnd.y - segmentStart.y)
  //   };
  // }

  // function findConnectedLines(nodes) {
  //   let edges = graph.edges;
  //   let pathSet = new Set();
  //   nodes.forEach(node => {
  //     if (edges[node]) {
  //       Object.keys(edges[node]).forEach(key => {
  //         let path1 = findObjectById(`path$${key}$${node}`, canvas);
  //         let path2 = findObjectById(`path$${node}$${key}`, canvas);
  //         if (path1) {
  //           pathSet.add(path1);
  //         }
  //         if (path2) {
  //           pathSet.add(path2);
  //         }
  //       });
  //     }
  //   });
  //   return Array.from(pathSet);
  // }


  // const generateAutoConnections = () => {
  //   const positions = graph.positions;
  //   const connectedPins = new Set();

  //   // Populate connectedPins set
  //   for (let node in graph.edges) {
  //     Object.keys(graph.edges[node]).forEach(neighbor => {
  //       if (positions[neighbor]) {
  //         connectedPins.add(neighbor);
  //       }
  //     });
  //   }

  //   const connectedLines = findConnectedLines(connectedPins);

  //   canvas.current.forEachObject(obj => {
  //     if (objPinNamesOnly.includes(obj.name) && obj.types !== 'text_field') {
  //       const pinName = `${obj.name}_${obj.enc_id}`;
  //       const isConnected = connectedPins.has(pinName);

  //       if (!isConnected) {
  //         const position = obj.getCenterPoint();
  //         let nearestPoint = null;
  //         let minDistance = Infinity;
  //         let nearestLine = null;

  //         console.log(`Processing ${pinName} at position:`, position);

  //         connectedLines.forEach(line => {
  //           const polylinePoints = extractPolylinePoints(line);
  //           for (let i = 0; i < polylinePoints.length - 1; i++) {
  //             const start = polylinePoints[i];
  //             const end = polylinePoints[i + 1];
  //             const nearest = nearestPointOnSegment(start, end, position);
  //             const distance = calculateDistance(position, nearest);

  //             console.log(`Distance from ${pinName} to segment ${start} -> ${end} is ${distance}`);

  //             if (distance < minDistance) {
  //               nearestPoint = nearest;
  //               nearestLine = line;
  //               minDistance = distance;
  //             }
  //           }
  //         });

  //         if (nearestLine) {
  //           let nodeName = generateNodeName(graph);
  //           let existingNode = null;

  //           for (let [key, value] of Object.entries(graph.positions)) {
  //             if (Math.abs(value.x - nearestPoint.x) < 0.001 && Math.abs(value.y - nearestPoint.y) < 0.001) {
  //               existingNode = key;
  //               break;
  //             }
  //           }

  //           console.log(`Nearest point for ${pinName} is ${nearestPoint}. Existing node: ${existingNode}`);
  //           if (existingNode) {
  //             // Connect the pin to the existing node
  //             addConnectionBtwnEdges2(pinName, existingNode, graph, canvas);
  //           } else {
  //             onCreateNode(position, pinName);
  //             connectNodeToPath(nodeName, nearestPoint, nearestLine, graph, canvas);
  //           }

  //           // Explicitly draw connection line
  //           drawLine(position, nearestPoint, 'connection', `connection${pinName}_${nodeName}`, canvas);

  //           graph.restoreEdges(graph.edges);
  //           graph.restorePositions(graph.positions);
  //           renderTraversiblePaths();
  //         } else {
  //           console.warn(`No nearest line found for ${pinName}`);
  //         }
  //       }
  //     }
  //   });

  //   renderTraversiblePaths();
  // };

  // function extractPolylinePoints(line) {
  //   return [
  //     { x: line.x1, y: line.y1 },
  //     { x: line.x2, y: line.y2 }
  //   ];
  // }

  // const addConnectionBtwnEdges2 = (key1, key2, graph, canvas) => {
  //   const posits = graph.getPositions();
  //   const position1 = posits[key1];
  //   const position2 = posits[key2];

  //   if (!position1 || !position2) {
  //     console.error(`Cannot add edge. One of the nodes is missing: key1=${key1}, key2=${key2}`);
  //     return;
  //   }

  //   console.log(`Adding edge between ${key1} and ${key2}`);

  //   graph.addNode(key1);
  //   graph.addNode(key2);
  //   graph.addEdge(key1, key2);
  //   drawLine(position1, position2, "path", `path$${key1}$${key2}`, canvas);
  // };

  // const connectNodeToPath = (nodeName, nearestPoint, nearestLine, graph, canvas) => {
  //   const [prefix, node1, node2] = nearestLine.id.split("$");
  //   const node1Position = graph.positions[node1];
  //   const node2Position = graph.positions[node2];

  //   if (node1Position && node2Position) {
  //     // Remove the original edge
  //     graph.removeEdge(node1, node2);

  //     // Add the new node to the graph
  //     graph.addNode(nodeName);
  //     graph.positions[nodeName] = nearestPoint;

  //     // Connect the new node into the path
  //     graph.addEdge(node1, nodeName);
  //     graph.addEdge(nodeName, node2);

  //     // Draw the new lines
  //     drawLine(node1Position, nearestPoint, "path", `path$${node1}$${nodeName}`, canvas);
  //     drawLine(nearestPoint, node2Position, "path", `path$${nodeName}$${node2}`, canvas);

  //     console.log(`Connected ${nodeName} between ${node1} and ${node2}`);
  //   } else {
  //     console.error(`Positions for nodes ${node1} or ${node2} are undefined`);
  //   }
  // };

  // function nearestPointOnSegment(segmentStart, segmentEnd, point) {
  //   const l2 = Math.pow(segmentEnd.x - segmentStart.x, 2) + Math.pow(segmentEnd.y - segmentStart.y, 2);
  //   if (l2 === 0) return segmentStart;
  //   let t = ((point.x - segmentStart.x) * (segmentEnd.x - segmentStart.x) + (point.y - segmentStart.y) * (segmentEnd.y - segmentStart.y)) / l2;
  //   t = Math.max(0, Math.min(1, t));
  //   return {
  //     x: segmentStart.x + t * (segmentEnd.x - segmentStart.x),
  //     y: segmentStart.y + t * (segmentEnd.y - segmentStart.y)
  //   };
  // }

  // function findConnectedLines(nodes) {
  //   let edges = graph.edges;
  //   let pathSet = new Set();
  //   nodes.forEach(node => {
  //     if (edges[node]) {
  //       Object.keys(edges[node]).forEach(key => {
  //         let path1 = findObjectById(`path$${key}$${node}`, canvas);
  //         let path2 = findObjectById(`path$${node}$${key}`, canvas);
  //         if (path1) {
  //           pathSet.add(path1);
  //         }
  //         if (path2) {
  //           pathSet.add(path2);
  //         }
  //       });
  //     }
  //   });
  //   return Array.from(pathSet);
  // }
  const handleMiddleMouseClick = (event) => {
    // console.log(event.button,"clicked")
    if (event.button === 1 || event.buttons === 4) {
      event.preventDefault(); // Prevent the default scroll behavior
      // ------------------------- navigation optimisation -------------------------
      renderTraversiblePaths()

      // ------------------------- navigation optimisation -------------------------

      // ------------------------- canvas clustering code -------------------------
      if (!addNewFloor && !addNewTraversablePath) {
        setMousetrigger((prev) => {
          if (prev === 0) {
            prev = prev + 1
          } else {
            prev = prev - 1
          }
          return prev
        })
      }

      // ------------------------- canvas clustering code over -------------------------
    }
  };

  const handleCreateRectangleShape = (canvas, e) => {
    let projectData
    setProjectSettings((prev) => {
      projectData = prev;
      return prev;
    });

    let strokeColor = projectData?.border_color ?? selFloorPlanDtls?.border_color;
    let strokeWidth = projectData?.border_thick ?? selFloorPlanDtls?.border_thick;
    let fillColor = projectData?.fill_color ?? selFloorPlanDtls?.fill_color;
    console.log(strokeWidth, 'strokeWidth')
    const mouse = canvas.current.getPointer(e.e);
    if (!mouseDownShape) {
      polyObj = new fabric.Rect({
        name: 'temp',
        left: mouse.x,
        top: mouse.y,
        width: 0,
        height: 0,
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: Number(strokeWidth),
        originX: "left",
        originY: "top",
        selectable: false,
        evented: false,
        strokeLineJoin: 'bevil',
        hoverCursor: `crosshair`,
      });

      canvas?.current?.add(polyObj);
      mouseDownShape = true;
    } else {
      // Update the rectangle size on mousemove
      const width = (mouse.x - polyObj.left);
      const height = (mouse.y - polyObj.top);

      polyObj.set({
        width: Math.abs(width),
        height: Math.abs(height),
      });
      const strokeWidth = polyObj.strokeWidth || 0;
      const rectPoints = [
        { x: polyObj.left + strokeWidth / 2, y: polyObj.top + strokeWidth / 2 },
        { x: polyObj.left + polyObj.width + strokeWidth / 2, y: polyObj.top + strokeWidth / 2 },
        { x: polyObj.left + polyObj.width + strokeWidth / 2, y: polyObj.top + polyObj.height + strokeWidth / 2 },
        { x: polyObj.left + strokeWidth / 2, y: polyObj.top + polyObj.height + strokeWidth / 2 }
      ];
      console.log(polyObj, 'polyObj')
      removeFabricObjectsByName(canvas, "tracing_obj_length");
      showObjLength(polyObj, rectPoints, canvas)
      // tracingLengthZoomLevel(canvas, canvas.current.getZoom())
      // polyObj.setCoords();
      canvas?.current?.renderAll();
    }
  }

  const handleCompleteRectangleShape = (evt) => {
    // const rectPoints = [
    //   { x: polyObj.left, y: polyObj.top },
    //   { x: polyObj.left + polyObj.width, y: polyObj.top },
    //   { x: polyObj.left + polyObj.width, y: polyObj.top + polyObj.height },
    //   { x: polyObj.left, y: polyObj.top + polyObj.height }
    // ];

    const strokeWidth = polyObj.strokeWidth || 0;
    const rectPoints = [
      { x: polyObj.left + strokeWidth / 2, y: polyObj.top + strokeWidth / 2 },
      { x: polyObj.left + polyObj.width + strokeWidth / 2, y: polyObj.top + strokeWidth / 2 },
      { x: polyObj.left + polyObj.width + strokeWidth / 2, y: polyObj.top + polyObj.height + strokeWidth / 2 },
      { x: polyObj.left + strokeWidth / 2, y: polyObj.top + polyObj.height + strokeWidth / 2 }
    ];

    removeFabricObjectsByName(canvas, "temp");
    let tracing = new fabric.Polygon(rectPoints, {
      objectCaching: false,
      id: new Date().toString(),
      fill: polyObj.fill,
      stroke: polyObj.stroke,
      strokeWidth: polyObj.strokeWidth,
      originX: "center",
      originY: "center",
      selectable: false,
      name: "tracing",
      position: "absolute",
      zIndex: 2000,
      perPixelTargetFind: true,
      hoverCursor: `crosshair`,
      evented: true,
      strokeLineJoin: 'bevil',

    });
    console.log(tracing, 'tracing')
    canvas?.current?.add(tracing);
    canvas.current.renderAll()
    removeFabricObjectsByName(canvas, "tracing_obj_length");
    // showObjLength(polyObj, rectPoints, canvas)
    setTracings((prev) => [...prev, { vertices: rectPoints }]);
    updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
    polyObj = '';
    mouseDownShape = false;

  }

  const handleCreateTriangleShape = (canvas, e) => {
    let projectData;
    setProjectSettings((prev) => {
      projectData = prev;
      return prev;
    });

    let strokeColor = projectData?.border_color ?? selFloorPlanDtls?.border_color;
    let strokeWidth = projectData?.border_thick ?? selFloorPlanDtls?.border_thick;
    let fillColor = projectData?.fill_color ?? selFloorPlanDtls?.fill_color;

    const mouse = canvas.current.getPointer(e.e);
    if (!mouseDownShape) {
      const initialX = mouse.x;
      const initialY = mouse.y;
      polyObj = new fabric.Triangle({
        name: 'temp',
        left: initialX,
        top: initialY,
        width: 0,
        height: 0,
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: Number(strokeWidth),
        originX: "left",
        originY: "top",
        selectable: false,
        evented: false,
        strokeLineJoin: 'bevil',
        hoverCursor: 'crosshair',
      });

      canvas?.current?.add(polyObj);
      mouseDownShape = true;
    } else {
      const width = mouse.x - polyObj.left;
      const height = mouse.y - polyObj.top;

      polyObj.set({
        width: Math.abs(width),
        height: Math.abs(height),
      });
      polyObj.setCoords();
      canvas?.current?.renderAll();
    }
  }

  const handleCompleteTriangleShape = () => {
    const trianglePoints = [
      { x: polyObj.left, y: polyObj.top + polyObj.height },
      { x: polyObj.left + polyObj.width / 2, y: polyObj.top },
      { x: polyObj.left + polyObj.width, y: polyObj.top + polyObj.height }
    ];

    removeFabricObjectsByName(canvas, "temp");
    let tracing = new fabric.Polygon(trianglePoints, {
      objectCaching: false,
      id: new Date().toString(),
      fill: polyObj.fill,
      stroke: polyObj.stroke,
      strokeWidth: polyObj.strokeWidth,
      originX: "center",
      originY: "center",
      selectable: false,
      name: "tracing",
      position: "absolute",
      zIndex: 2000,
      perPixelTargetFind: true,
      hoverCursor: 'crosshair',
      evented: true,
      strokeLineJoin: 'bevil',
    });
    canvas?.current?.add(tracing);
    canvas.current.renderAll();
    setTracings((prev) => [...prev, { vertices: trianglePoints }]);
    updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
    polyObj = '';
    mouseDownShape = false;
  }

  const handleCreateCircleShape = (canvas, e) => {
    let projectData;
    setProjectSettings((prev) => {
      projectData = prev;
      return prev;
    });

    let strokeColor = projectData?.border_color ?? selFloorPlanDtls?.border_color;
    let strokeWidth = projectData?.border_thick ?? selFloorPlanDtls?.border_thick;
    let fillColor = projectData?.fill_color ?? selFloorPlanDtls?.fill_color;

    const mouse = canvas.current.getPointer(e.e);
    if (!mouseDownShape) {
      const initialX = mouse.x;
      const initialY = mouse.y;
      polyObj = new fabric.Circle({
        name: 'temp',
        left: initialX,
        top: initialY,
        radius: 0,
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: Number(strokeWidth),
        originX: "left",
        originY: "top",
        selectable: false,
        evented: false,
        strokeLineJoin: 'bevil',
        hoverCursor: 'crosshair',
      });

      canvas?.current?.add(polyObj);
      mouseDownShape = true;
    } else {
      const radius = Math.sqrt(Math.pow(mouse.x - polyObj.left, 2) + Math.pow(mouse.y - polyObj.top, 2)) / 2;

      polyObj.set({
        radius: radius,
      });
      polyObj.setCoords();
      canvas?.current?.renderAll();
    }
  }

  const handleCompleteCircleShape = () => {
    removeFabricObjectsByName(canvas, "temp");
    const circle = new fabric.Circle({
      objectCaching: false,
      id: new Date().toString(),
      fill: polyObj.fill,
      stroke: polyObj.stroke,
      strokeWidth: polyObj.strokeWidth,
      radius: polyObj.radius,
      selectable: false,
      name: "tracing",
      position: "absolute",
      zIndex: 2000,
      left: polyObj.left,
      top: polyObj.top,
      perPixelTargetFind: true,
      hoverCursor: 'crosshair',
    });
    circle.setCoords();
    canvas?.current?.add(circle);
    canvas.current.renderAll();
    const coords = { x: polyObj.left, y: polyObj.top };
    setTracingCircle((prev) => [...prev, { vertices: coords }]);
    updateTracingCircle(canvas, setTracingCircle, setTracingIntialValue, postTrasingCircle);
    polyObj = '';
    mouseDownShape = false;
  }



  // ------------------------- canvas clustering code -------------------------

  let gridGroup;

  function updateGrid(width, height, cellsize, canvas) {

    const zoomLevel = 1;
    const baseGridSize = cellsize; // Base size for the grid, adjust as needed

    const gridSize = baseGridSize * zoomLevel; // Adjust grid size based on zoom
    const visibleArea = updateVisibleArea(canvas);

    let logicalGrid = divideIntoGridCells(visibleArea, gridSize, zoomLevel);

    // Remove the existing grid if it exists
    if (gridGroup) {
      canvas.remove(gridGroup);
    }

    // Array to store the lines
    const gridElements = [];

    // Create vertical lines
    for (let i = Math.floor(visibleArea.x1 / gridSize) * gridSize; i <= visibleArea.x2; i += gridSize) {
      const verticalLine = new fabric.Line([i, visibleArea.y1, i, visibleArea.y2], {
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false
      });
      gridElements.push(verticalLine);

      // Mark points at intersections with horizontal lines
      // for (let j = Math.floor(visibleArea.y1 / gridSize) * gridSize; j <= visibleArea.y2; j += gridSize) {
      //   const point = new fabric.Circle({
      //     left: i,
      //     top: j,
      //     radius: 3,
      //     fill: 'blue',
      //     selectable: false,
      //     evented: false,
      //     originX: 'center',
      //     originY: 'center'
      //   });
      //   gridElements.push(point); // Add point to the array

      //   //   const coordinatesText = new fabric.Text(`(${i}, ${j})`, {
      //   //     left: i + 5, // Offset the text a bit to the right of the point
      //   //     top: j - 10, // Offset the text slightly above the point
      //   //     fontSize: 12,
      //   //     fill: 'black',
      //   //     selectable: false,
      //   //     evented: false,
      //   //     originX: 'left',
      //   //     originY: 'bottom'
      //   // });
      //   // gridElements.push(coordinatesText); // Add text to the array
      // }
    }

    // Create horizontal lines within the visible area
    for (let j = Math.floor(visibleArea.y1 / gridSize) * gridSize; j <= visibleArea.y2; j += gridSize) {
      const horizontalLine = new fabric.Line([visibleArea.x1, j, visibleArea.x2, j], {
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false
      });
      gridElements.push(horizontalLine);
    }

    // Create a group of grid lines and points, and add it to the canvas
    gridGroup = new fabric.Group(gridElements, {
      selectable: false,
      evented: false
    });

    canvas.add(gridGroup);
    // canvas.sendToBack(gridGroup);// Send grid to back

    // Render the canvas
    canvas.renderAll();
    return logicalGrid
  }

  const clusturGroup = (fabricCanvas, floor_id) => {
    // console.log('clusturGroup')
    let activeTab;
    setActiveTab((prev) => {
      activeTab = prev
      return prev
    })

    let addNewFloorVar;
    setAddNewFloor((prev) => {
      addNewFloorVar = prev;
      return prev;
    });
    if (!addNewFloorVar && activeTab !== "traversable") {
      let viewport = updateVisibleArea(canvas.current);
      // const grid = divideIntoGridCells(viewport, cellSize) || [];
      let grid = updateGrid(
        viewport?.x2,
        viewport.y2,
        cellSize,
        canvas.current
      );

      // drawVisibleAreaRectangle(canvas.current,viewport)

      const clusters = groupLocationsInGrid(
        grid,
        cellSize,
        canvas.current,
        viewport,
        floor_id
      );

      clustersMap.current = new Map(
        clusters.map((cluster) => [cluster.object, cluster])
      );
      console.log(clusters, "clusters")
      updateVisibleClusters(canvas.current, canvas.current.getZoom(), viewport);
    }
  };

  const divideIntoGridCells = (viewport, cellSize, zoomLevel = 1) => {
    const { x1, y1, x2, y2 } = viewport;

    // Adjust the viewport to snap to the nearest multiple of 250 (cell size)
    const snappedX1 = snapToGrid(x1, cellSize);
    const snappedY1 = snapToGrid(y1, cellSize);
    const snappedX2 = snapToGrid(x2, cellSize);
    const snappedY2 = snapToGrid(y2, cellSize);

    // Adjust cell size based on zoom level
    const adjustedCellSize = cellSize * zoomLevel;

    // Calculate the number of rows and columns based on the adjusted viewport size
    const rows = Math.ceil((snappedY2 - snappedY1) / adjustedCellSize);
    const cols = Math.ceil((snappedX2 - snappedX1) / adjustedCellSize);

    // Create a grid that stores corner points for each cell
    const gridCells = Array.from({ length: rows }, (_, row) =>
      Array.from({ length: cols }, (_, col) => {
        const xStart = snappedX1 + col * adjustedCellSize;
        const yStart = snappedY1 + row * adjustedCellSize;
        const xEnd = xStart + adjustedCellSize;
        const yEnd = yStart + adjustedCellSize;

        const topLeft = { x: xStart, y: yStart };
        const topRight = { x: xEnd, y: yStart };
        const bottomLeft = { x: xStart, y: yEnd };
        const bottomRight = { x: xEnd, y: yEnd };

        // Return cell with its corner coordinates and an empty array for items
        return { corners: { topLeft, topRight, bottomLeft, bottomRight }, items: [] };
      })
    );
    return gridCells;
  };

  const snapToGrid = (value, gridSize) => {
    return Math.ceil(value / gridSize) * gridSize;
  };

  const updateVisibleArea = (fabricCanvas) => {
    const transform = fabricCanvas.viewportTransform;
    if (transform) {
      const viewportWidth = window.innerWidth + 250;
      const viewportHeight = window.innerHeight + 250;

      const topLeft = fabric.util.transformPoint({ x: 0, y: 0 }, fabric.util.invertTransform(transform));
      const bottomRight = fabric.util.transformPoint({ x: viewportWidth, y: viewportHeight }, fabric.util.invertTransform(transform));

      const visibleArea = {
        x1: topLeft.x - 250,
        y1: topLeft.y - 250,
        x2: bottomRight.x,
        y2: bottomRight.y,
      };

      return visibleArea;
    }
  };

  const drawVisibleAreaRectangle = (fabricCanvas, visibleArea) => {
    const rect = new fabric.Rect({
      left: visibleArea.x1,
      top: visibleArea.y1,
      width: visibleArea.x2 - visibleArea.x1,
      height: visibleArea.y2 - visibleArea.y1,
      fill: 'rgba(0, 0, 0, 0)', // Transparent fill
      stroke: 'rgba(0, 0, 255, 1)', // Red border for visibility
      strokeWidth: 2,
      name: "drawVisibleAreaRectangle"
    });

    // Remove existing visible area rectangles before adding a new one
    const existingRects = fabricCanvas.getObjects('rect').filter(obj => obj.name === 'drawVisibleAreaRectangle');
    existingRects.forEach(rect => fabricCanvas.remove(rect));

    fabricCanvas.add(rect);
    fabricCanvas.sendToBack(rect);
    fabricCanvas.renderAll();
  };

  const groupLocationsInGrid = (
    grid,
    cellSize,
    fabricCanvas,
    viewport,
    floor_id
  ) => {
    let floor;
    if (floor_id) {
      floor = floor_id;
    } else {
      setFloorID((prev) => {
        floor = prev;
        return prev;
      });
    }
    let storedItem = new Map();
    setStoredObjects((prev) => {
      storedItem = prev;
      return prev;
    });
    // console.log(storedObjects,"storedObjects")
    const combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];

    combinedArray?.forEach((object) => {
      const validTypes = [
        "product",
        "location",
        "boundary",
        "amenity",
        "beacon",
        "safety",
        "vertical",
      ];
      let object_FloorID;
      if (object.name === "boundary") {
        let obj = storedObjects.get(`${object?.id?.split("_")[1]}_${floor}`);
        if (obj) {
          let locationPin = obj.find((item) => item.isBoundary);
          object_FloorID = locationPin?.fp_id;
        }
      } else {
        object_FloorID = object.fp_id;
      }
      if (
        validTypes.includes(object.name) &&
        (object.type === "group" || object.type === "polygon") &&
        object_FloorID === floor
      ) {
        let boundingRect;
        let bounderyPinObject
        if (object.name === "boundary") {
          bounderyPinObject = combinedArray.find((item) => {
            if ((item.enc_id == object?.id?.split("_")[1]) && (item.types != "text_field")) {
              return item
            }
          })
          if (obj) {
            boundingRect = obj.getBoundingRect(true)
          } else {
            boundingRect = object.getBoundingRect(true);
          }
        } else {
          boundingRect = object.getBoundingRect(true);
        }
        const isInViewport = isObjectInViewport(boundingRect, viewport);


        if (isInViewport) {
          // Loop through the grid cells
          for (let row = 0; row < grid.length; row++) {
            for (let col = 0; col < grid[row].length; col++) {
              const cell = grid[row][col];
              let checkObjectInCell
              if (object.name === "boundary") {
                checkObjectInCell = isObjectInCell(cell.corners, bounderyPinObject)
              } else {
                checkObjectInCell = isObjectInCell(cell.corners, object)
              }
              if (checkObjectInCell) {
                let isItemPresent;
                if (object.name === "boundary") {
                  isItemPresent = findItemWithIndex(
                    grid,
                    parseInt(object?.id?.split("_")[1])
                  );
                } else {
                  isItemPresent = findItemWithIndex(grid, object.enc_id);
                }

                if (isItemPresent) {
                  let newCell = grid[isItemPresent.row][isItemPresent.col];
                  newCell.items.push(object);
                } else {
                  cell.items.push(object);
                }
                break; // Object found a cell, no need to check further cells
              }
            }
          }
        } else {
          fabricCanvas.remove(object);
        }
      }
    });
    return grid
      .flatMap((row, rowIndex) =>
        row.flatMap((cell, colIndex) => {
          if (cell.items.length > 1) {
            const uniqueEncIds = new Set(
              cell.items.map((obj) => {
                if (obj.name === "boundary") {
                  return parseInt(obj?.id?.split("_")[1]);
                } else {
                  return obj.enc_id;
                }
              })
            );
            // console.log(uniqueEncIds,cell.items,"uniqueEncIds")

            if (uniqueEncIds.size > 1) {
              let isEdited = cell.items.filter((item) => item.boundaryGroup)
              let changedArray = []

              // Create a cluster only if there are multiple unique enc_ids
              const { topLeft, bottomRight } = cell.corners;

              const centerX = (topLeft.x + bottomRight.x) / 2;
              const centerY = (topLeft.y + bottomRight.y) / 2;
              const clusterCenter = {
                x: centerX,
                y: centerY,
              };
              // console.log(isEdited,"isedited")
              if (isEdited.length > 0) {
                changedArray = cell.items.filter((item) => !item.boundaryGroup)
                const newUniqueEncIds = new Set(
                  changedArray.map((obj) => {
                    if (obj.name === "boundary") {
                      return parseInt(obj?.id?.split("_")[1]);
                    } else {
                      return obj.enc_id;
                    }
                  })
                );
                if (newUniqueEncIds.size > 1) {
                  return [
                    {
                      objType: "cluster",
                      object: new fabric.Circle({
                        left: clusterCenter.x,
                        top: clusterCenter.y,
                        radius: 15,
                        fill: "rgba(250, 246, 25,0.8)",
                        originX: "center",
                        originY: "center",
                        selectable: false,
                      }),
                      points: changedArray,
                    },
                    {
                      objType: "point",
                      object: new fabric.Circle({
                        left: clusterCenter.x,
                        top: clusterCenter.y,
                        radius: 5,
                        fill: "green",
                        originX: "center",
                        originY: "center",
                      }),
                      points: isEdited,
                    }
                  ]
                } else {
                  return [
                    {
                      objType: "point",
                      object: new fabric.Circle({
                        left: clusterCenter.x,
                        top: clusterCenter.y,
                        radius: 5,
                        fill: "green",
                        originX: "center",
                        originY: "center",
                      }),
                      points: changedArray,
                    },
                    {
                      objType: "point",
                      object: new fabric.Circle({
                        left: clusterCenter.x,
                        top: clusterCenter.y,
                        radius: 5,
                        fill: "green",
                        originX: "center",
                        originY: "center",
                      }),
                      points: isEdited,
                    }
                  ]

                }
              } else {
                return {
                  objType: "cluster",
                  object: new fabric.Circle({
                    left: clusterCenter.x,
                    top: clusterCenter.y,
                    radius: 15,
                    fill: "rgba(250, 246, 25,0.8)",
                    originX: "center",
                    originY: "center",
                    selectable: false,
                  }),
                  points: cell.items,
                };
              }
            } else {
              // If all objects have the same enc_id, return them individually
              return cell.items.map((obj) => ({
                objType: "point",
                object: new fabric.Circle({
                  left: obj.left,
                  top: obj.top,
                  radius: 5,
                  fill: "green",
                  originX: "center",
                  originY: "center",
                }),
                points: [obj],
              }));
            }
          } else if (cell.items.length === 1) {
            return {
              objType: "point",
              object: new fabric.Circle({
                left: cell.items[0].left,
                top: cell.items[0].top,
                radius: 5,
                fill: "green",
                originX: "center",
                originY: "center",
              }),
              points: cell.items,
            };
          } else {
            return null;
          }
        })
      )
      .filter(Boolean);
  };

  const findItemWithIndex = (arr, id) => {
    for (let row = 0; row < arr.length; row++) {
      for (let col = 0; col < arr[row].length; col++) {
        const items = arr[row][col].items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].enc_id === id) {
            return {
              // item: items[i],
              row: row,
              col: col,
              // indexInItems: i // Index within the `items` array
            };
          }
        }
      }
    }
    return null;
  };

  const isObjectInCell = (rectangle, point) => {
    const { topLeft, topRight, bottomLeft, bottomRight } = rectangle;

    // Calculate the bounding box of the rectangle
    const rectLeft = topLeft.x;
    const rectRight = topRight.x;
    const rectTop = topLeft.y;
    const rectBottom = bottomLeft.y;

    // Extract the bounding box of the point (group)
    const pointLeft = point.left;
    const pointTop = point.top;
    const pointRight = pointLeft + point.width;
    const pointBottom = pointTop + point.height;

    // // Check if the point's bounding box is within the rectangle
    // const isWithinXBounds = (pointLeft >= rectLeft && pointRight <= rectRight);
    // const isWithinYBounds = (pointTop >= rectTop && pointBottom <= rectBottom);

    // Calculate the point's center
    const pointCenterX = pointLeft + point.width / 2;
    const pointCenterY = pointTop + point.height / 2;

    // Check if the center of the object is within the rectangle
    const isCenterInside =
      pointCenterX >= rectLeft &&
      pointCenterX <= rectRight &&
      pointCenterY >= rectTop &&
      pointCenterY <= rectBottom;
    return isCenterInside;
    // return isWithinXBounds && isWithinYBounds;
  };

  const isObjectInViewport = (object, viewport) => {
    const objLeft = object.left;
    const objTop = object.top;
    const objRight = object.left + object.width;
    const objBottom = object.top + object.height;

    const viewLeft = viewport.x1;
    const viewTop = viewport.y1;
    const viewRight = viewport.x2;
    const viewBottom = viewport.y2;

    // Check if there is any overlap between the object's bounding box and the viewport
    return !(
      objRight < viewLeft ||
      objLeft > viewRight ||
      objBottom < viewTop ||
      objTop > viewBottom
    );
  };

  const updateVisibleClusters = (fabricCanvas, zoom, visibleArea) => {
    const objectsToKeep = new Set();

    clustersMap.current.forEach((cluster) => {
      const clusterCenter = {
        x: cluster.object.left,
        y: cluster.object.top,
      };
      const isClusterInViewport = checkClusterInViewport(
        clusterCenter,
        cluster.object.radius,
        visibleArea
      );

      if (isClusterInViewport && zoom < zoomThreshold) {
        if (cluster.objType === "point") {
          cluster.points.forEach((point) => {
            if (!visibleObjects.current.has(point)) {
              fabricCanvas.add(point);
              if (point.name === "boundary") {
                // point.set('fill', 'red');
                // console.log(point,"dsfbjhsdbfd")
              }
              visibleObjects.current.add(point);
            }
            objectsToKeep.add(point);
          });
        } else if (cluster.objType === "cluster") {
          if (!visibleObjects.current.has(cluster.object)) {
            fabricCanvas.add(cluster.object);
            visibleObjects.current.add(cluster.object);
            cluster.points.forEach((point) => {
              let objToremove;
              if (point.name === "boundary") {
                objToremove = canvas.current
                  .getObjects()
                  ?.filter((obj) => obj.id === point.id);
              } else {
                objToremove = canvas.current
                  .getObjects()
                  ?.filter((obj) => obj.enc_id === point.enc_id);
              }
              objToremove?.map((item) => {
                fabricCanvas.remove(item);
              });
            });
          }
          objectsToKeep.add(cluster.object);
        }
      } else if (isClusterInViewport && zoom >= zoomThreshold) {
        cluster.points.forEach((point) => {
          if (!visibleObjects.current.has(point)) {
            canvas.current.add(point);
            if (point.name === "boundary") {
              // point.set('fill', 'red');
              // console.log(point,"dsfbjhsdbfd")
            }
            visibleObjects.current.add(point);
          }
          objectsToKeep.add(point);
        });
      } else {
        // console.log("else")
        cluster.points.forEach((point) => {
          fabricCanvas.remove(point);
        });
      }
    });

    // Remove objects that are no longer visible
    visibleObjects.current.forEach((obj) => {
      if (!objectsToKeep.has(obj)) {
        fabricCanvas.remove(obj);
        visibleObjects.current.delete(obj);
      }
    });


    // fabricCanvas.discardActiveObject();
    fabricCanvas.renderAll();
  };

  // console.log(storedObjects,"dsjkfhsdj")

  function isCornerInsideViewport(corner, viewport) {
    const { x, y } = corner;
    return (x >= viewport.x1 && x <= viewport.x2) && (y >= viewport.y1 && y <= viewport.y2);
  }

  const checkClusterInViewport = (
    clusterCenter,
    clusterRadius,
    visibleArea
  ) => {

    const result = clusterCenter.x + clusterRadius > visibleArea.x1 &&
      clusterCenter.x - clusterRadius < visibleArea.x2 &&
      clusterCenter.y + clusterRadius > visibleArea.y1 &&
      clusterCenter.y - clusterRadius < visibleArea.y2

    if (!result) {
      const centerPoint = clusterCenter;
      const width = 250;
      const halfWidth = width / 2;

      const corners = {
        topLeft: {
          x: centerPoint.x - halfWidth,
          y: centerPoint.y - halfWidth,
        },
        topRight: {
          x: centerPoint.x + halfWidth,
          y: centerPoint.y - halfWidth,
        },
        bottomLeft: {
          x: centerPoint.x - halfWidth,
          y: centerPoint.y + halfWidth,
        },
        bottomRight: {
          x: centerPoint.x + halfWidth,
          y: centerPoint.y + halfWidth,
        },
      };

      const edges = {
        topLeft: isCornerInsideViewport(corners.topLeft, visibleArea),
        topRight: isCornerInsideViewport(corners.topRight, visibleArea),
        bottomLeft: isCornerInsideViewport(corners.bottomLeft, visibleArea),
        bottomRight: isCornerInsideViewport(corners.bottomRight, visibleArea),
      }

      return Object.values(edges).some(value => value === true)

    }

    return result
  };

  const isObjectInRectangle = (obj, fabricCanvas, viewport) => {
    const viewportTransform = fabricCanvas.viewportTransform;
    const zoom = fabricCanvas.getZoom();

    // Transform the object's coordinates to the canvas's current zoom and translation
    const x = (obj.left - viewportTransform[4]) / zoom;
    const y = (obj.top - viewportTransform[5]) / zoom;

    // Check if the object's coordinates are within the given viewport bounds
    const isInViewport =
      x >= viewport.x1 &&
      x <= viewport.x2 &&
      y >= viewport.y1 &&
      y <= viewport.y2;

    // console.log(obj.id,x,y,isInViewport)

    return isInViewport;
  };

  // ------------------------- canvas clustering code over -------------------------

  useEffect(() => {
    if (addNewFloor) {
      if (toolActive === "Draw") {
        setSelTracingId();
        obj = "";
        changeSelectionAllObjs(canvas, false, "tracing");
        changeSelectionAllObjs(canvas, false, "text");
      } else if (toolActive === "Select") {
        changeSelectionAllObjs(canvas, true, "tracing");
        changeSelectionAllObjs(canvas, true, "text");
      } else if (toolActive === "Text") {
        obj = "";
        changeSelectionAllObjs(canvas, false, "tracing");
        changeSelectionAllObjs(canvas, false, "text");
      } else if (toolActive !== "Select") {
        changeSelectionAllObjs(canvas, false, "tracing");
        changeSelectionAllObjs(canvas, false, "text");
      }
      setZoomInOut(zoomInOut);
      setSelFloorPlanDtls((prev) => ({ ...prev, zoom: zoomInOut }));
      removeFabricObjectsByName(canvas, "temp");
      removeFabricObjectsByName(canvas, "corner_point");
      // stopPathDrawing
    }
  }, [toolActive, addNewFloor]);

  const createNodeBtwPolyline = (e, mouse, lineData, type) => {
    // console.log(e, mouse, lineData,"e, mouse, lineData,")
    removeLine(e?.target?.id, graph, canvas, "nodeBTWline");
    let nodeName = generateNodeName(graph);
    if (lastTraversibleUndoIndex != undefined) {
      setTraversibleHistory((prev) =>
        prev.splice(lastTraversibleUndoIndex - 1)
      );
      lastTraversibleUndoIndex = undefined;
    }
    let nodeAdded = false;
    if (
      !e.target ||
      e.target?.name == "backgroundRect" ||
      e.target?.name == "tracing" ||
      e.target?.name == "boundary" ||
      e?.target?.name == "text" ||
      // for subpath point connect to node
      e?.target?.name == "node" ||
      // ---------------------------------
      e?.target?.name == "path"
    ) {
      let node = addNodePoint(mouse, nodeName);

      canvas.current.add(node);
      const [node1, node2] = lineData?.id.split("$").slice(1);
      let node1position = posits[node1];
      let node2position = posits[node2];
      console.log(node1, node2, "node1,2");
      let poinCenter = adjustPositionIfNeeded(
        graph.positions,
        e.target?.getCenterPoint(),
        graph.positions[node1],
        graph.positions[node2]
      );
      let center = poinCenter;
      const coords =
        e.target &&
          e.target.name != "backgroundRect" &&
          e.target?.name != "line_starter_poly" &&
          e.target.name != "tracing" &&
          e.target.name != "path" &&
          e.target.name != "boundary" &&
          e.target.name != "text"
          ? { x: center?.x, y: center?.y }
          : { x: mouse?.x, y: mouse?.y };
      poly = false;
      polyBtn = "";
      lastPt = 1;
      pts = [];
      polyline = addPolyLine(pts, "line_starter_poly");
      // console.log(polyline, "polyline");
      canvas.current.add(polyline);
      onCreateNode(coords, nodeName);
      // removeFabricObjectsByName(canvas,'line_starter_poly')
      addConnectionBtwnEdges(key1, key2, graph, canvas);

      graph.addNode(node1);
      graph.addEdge(nodeName, node1);
      graph.addEdge(node2, nodeName);

      // console.log(graph,"graph");
      // console.log("view floorplan  ndoes")
      drawLine(
        { x: mouse.x, y: mouse.y },
        node1position,
        "path",
        `path$${node1}$${nodeName}`,
        canvas
      );
      drawLine(
        { x: mouse.x, y: mouse.y },
        node2position,
        "path",
        `path$${node}$${nodeName}`,
        canvas
      );
      stopPathDrawing();
      if (!type) {
        renderTraversiblePaths();

        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
      }
      // renderTraversiblePaths();
    }
  };

  useEffect(() => {
    if (viewportTransform && canvas.current) {
      canvas.current.viewportTransform = viewportTransform;
    }
  }, [toolActive, toolTraversible]);

  useEffect(() => {
    document.addEventListener("mousedown", handleMiddleMouseClick);
    document.addEventListener("keydown", handleKeyBoardPress);
    setSelFloorPlanDtls(selFloorPlanDtls);
    canvas.current = initCanvas(
      canvasContainerRef.current.clientWidth,
      canvasContainerRef.current.clientHeight,
      projectSettings
    );

    let PositionLocation = selLocationDtls?.position ?? null;
    let Positionproduct = selProductDtls?.position ?? null;
    let Positionsafety = selSafetyDtls?.position ?? null;
    let Positionamenity = selAmenityDtls?.position ?? null;
    let Positionbeacon = selBeaconDtls?.position ?? null;
    let PositionverticalTransport = selVerticalDtls?.position ?? null;

    firstClick = {
      location: PositionLocation,
      product: Positionproduct,
      safety: Positionsafety,
      amenity: Positionamenity,
      beacon: Positionbeacon,
      verticalTransport: PositionverticalTransport,
    };
    if (
      viewportTransform &&
      ((activeTab === "floorDetails" && addNewFloor) ||
        (activeTab === "traversable" && addNewTraversablePath) ||
        addNewLocation ||
        addNewProduct ||
        addNewQrCodeBeacon ||
        addNewAmenity ||
        addNewSafety ||
        addNewVertical)
    ) {
      canvas.current.viewportTransform = viewportTransform;
    } else if (viewportTransform) {
      canvas.current.viewportTransform = viewportTransform;
    }
    let savedTextboxes = [];

    canvas.current.on("mouse:down", function (e) {
      console.log(e, "down");

      let toolActive;
      setToolActive((prev) => {
        toolActive = prev;
        return prev;
      });

      // console.log(toolActive, 'toolActive')

      let panToolVariabl = false;
      setPanTool((prev) => {
        panToolVariabl = prev;
        return prev;
      });

      if (panToolVariabl) {
        const activeObject = e.target;
        if (activeObject?.name === "boundary") {
          setPanTool(false);
        }
      }

      mouseDown2 = true;
      if (obj) {
        originalObjCenterPoints = obj.getCenterPoint();
      }
      if (!obj) {
      }
      if (e.e.altKey === 1) {
        draggingCanvas = true;
        lastPosX = e.e.clientX;
        lastPosY = e.e.clientY;
      } else if (
        activeTab === "floorDetails" &&
        !obj &&
        toolActive === "Draw" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        activeText = undefined;
        changeSelectionAllObjs(canvas, false, "tracing");
        let mouse = canvas.current.getPointer(e.e);
        // if (pts?.length > 1) {pts.splice(-1, 1);} //remove duplicate start points
        let coords = { x: mouse?.x, y: mouse?.y };
        if (e.e.shiftKey && prevMouseClick) {
          let type = getVerticalOrHorizontalMove(prevMouseClick, {
            x: mouse.x,
            y: mouse.y,
          });
          if (type == "vertical") {
            coords = { x: prevMouseClick?.x, y: mouse.y };
          } else {
            coords = {
              x: mouse?.x,
              y: prevMouseClick.y,
            };
          }
        }
        createCornerPoint(canvas, coords, "corner_point", pts, Pencil);
        polyline = new fabric.Polyline(pts, {
          objectCaching: false,
          name: "temp",
          fill: "",
          stroke: "black",
          zIndex: 100,
          originX: "center",
          originY: "center",
          selectable: false,
          strokeUniform: true,
          position: "absolute",
          hoverCursor: `url(${Pencil}) 1 17, auto`,
        });
        canvas.current?.add(polyline);
        canvas.current.requestRenderAll(); // Force the canvas to re-render immediately

        bringFabricObjectsToFrontByName(canvas, "length_text");
        /* showing length of polyline */
        removeFabricObjectsByName(canvas, "length_text_temp");
        showLineLength("length_text");
        tracingLengthZoomLevel(canvas, canvas.current.getZoom());

        polyline.points[pts?.length] = { x: coords.x, y: coords.y };
        lastPt++;

        mouseDown = true;
        prevMouseClick = { x: coords?.x, y: coords?.y };
        const firstCircleObj = findObjectById("corner_point_1", canvas);
        if (
          pts?.length > 1 &&
          isInsideRadius(
            { x: pts[0]?.x, y: pts[0]?.y },
            pts[pts.length - 1],
            firstCircleObj?.radius
          )
        ) {
          pts?.splice(pts.length - 2, 2);
          completeTracingShape();
        }
      } else if (
        activeTab === "floorDetails" &&
        !obj &&
        toolActive === "Text" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        let activeObject = e.target;

        if (activeObject && activeObject.type === "textbox") {
          if (activeObject?._text?.length > 0) {
            canvas.current.setActiveObject(activeObject);
            setSelObject(activeObject);
            activeText = activeObject;
            updateText(canvas, setTexts, postTexts);
          }
        } else {
          let LastSelected;
          setSelObject((prev) => {
            if (prev?.name == "text") {
              LastSelected = prev;
            }
            return prev;
          });
          let lastSelValue;
          setTextStyleValue((prev) => {
            lastSelValue = prev;
            return prev;
          });
          console.log(lastSelValue, "LastSelected");
          if (activeText) return (activeText = undefined);
          let mouse = canvas.current.getPointer(e.e);
          var text = new fabric.Textbox("Name", {
            left: mouse.x - 12,
            top: mouse.y - 6,
            // fill: lastSelValue?.fill ?? LastSelected?.fill ?? "#646464",
            fill: lastSelValue?.fill ?? "#646464",
            fontSize: 14,
            maxWidth: Infinity,
            name: "text",
            backgroundColor: "transparent",
            selectable: false,
            // isWrapping: true,
            hasRotatingPoint: false,
            // fontFamily: lastSelValue?.fontFamily ?? LastSelected?.fontFamily ?? standardFonts[0],
            fontFamily: lastSelValue?.fontFamily ?? standardFonts[0],
            textAlign: lastSelValue?.textAlign ?? "left",
            fontWeight: lastSelValue?.fontWeight ?? "normal",
            fontSize: lastSelValue?.fontSize ?? standardFontSize[5],

            id: new Date().toString(),
          });
          // text.set({ width: text.getScaledWidth() + 25 })

          text.on("keydown", function (e) {
            if (e.key === " ") {
              e.preventDefault();
            }
          });
          document.addEventListener("keydown", function (event) {
            if (
              (event.ctrlKey || event.metaKey) &&
              (event.key === "z" || event.key === "y" || event.key === "x")
            ) {
              event.preventDefault();
            }
          });
          canvas.current.add(text);
          canvas.current.setActiveObject(text);
          text.enterEditing();
          text.setSelectionStart(0);
          text.setSelectionEnd(text.text.length);
          setSelObject(text);
          activeText = text;
          updateText(canvas, setTexts, postTexts);
        }
      } else if (
        activeTab === "floorDetails" &&
        obj?.id == "corner_point_1" &&
        toolActive === "Draw" &&
        addNewFloor
      ) {
        pts?.splice(pts.length - 1, 1);
        completeTracingShape();
      } else if (
        activeTab === "floorDetails" &&
        obj &&
        obj.id != "corner_point_1" &&
        toolActive === "Select" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        // console.log("Entering")

        obj = e.target;
        activeText = undefined;

        if (obj.name === "tracing" && obj.type === "polygon") {
          // console.log(e.target, "down");
          setTracingIntialValue({
            fill_color: e.target.fill,
            border_color: e.target.stroke,
            border_thick: e.target.strokeWidth,
          });
          setSelTracingId(e.target.id);
          showCornerPoints(obj);
          removeFabricObjectsByName(canvas, "tracing_obj_length");
          const points = getPolygonVertices(obj);
          showObjLength(obj, points, canvas);
          tracingLengthZoomLevel(canvas, canvas.current.getZoom());
        } else if (obj.name === "tracing" && obj.type === "circle") {
          removeFabricObjectsByName(canvas, "tracing_obj_length");
          setTracingIntialValue({
            fill_color: e.target.fill,
            border_color: e.target.stroke,
            border_thick: e.target.strokeWidth,
          });
          // cornersVisible.current = false
        } else {
          removeFabricObjectsByName(canvas, "tracing_obj_length");
          // cornersVisible.current = false
        }

        setSelTracingId(e.target.id);
        setSelObject(e.target);
      } else if (
        activeTab === "floorDetails" &&
        toolActive === "Rectangle" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        handleCreateRectangleShape(canvas, e);
      } else if (
        activeTab === "floorDetails" &&
        toolActive === "Triangle" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        handleCreateTriangleShape(canvas, e);
      } else if (
        activeTab === "floorDetails" &&
        toolActive === "Circle" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        handleCreateCircleShape(canvas, e);
      } else if (
        activeTab === "floorDetails" &&
        e.target &&
        e.target.name === "tracing" &&
        toolActive === "Fill" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        // console.log("Entering not", selFloorPlanDtls)

        // obj = e.target;
        let trace = e.target;
        // console.log(trace, "trace");
        activeText = undefined;

        let tracingValue = {};
        setTracingIntialValue((prev) => {
          tracingValue = prev;
          return prev;
        });
        let floorplanDetails = {};
        setSelFloorPlanDtls((prev) => {
          floorplanDetails = prev;
          return prev;
        });
        if (trace.name === "tracing") {
          trace.set(
            "fill",
            tracingValue?.fill_color ??
            floorplanDetails?.fill_color ??
            projectSettings?.fill_color
          );
          trace.set(
            "stroke",
            tracingValue?.border_color ??
            floorplanDetails?.border_color ??
            projectSettings?.border_color
          );
          trace.set(
            "strokeWidth",
            tracingValue?.border_thick ??
            floorplanDetails?.border_thick ??
            projectSettings?.border_thick
          );

          updateTracing(
            canvas,
            setTracings,
            setTracingIntialValue,
            postTrasing
          );
          updateTracingCircle(
            canvas,
            setTracingCircle,
            setTracingIntialValue,
            postTrasingCircle
          );
        }
      } else if (
        activeTab === "floorDetails" &&
        e.target &&
        toolActive === "Erase" &&
        addNewFloor &&
        !panToolVariabl
      ) {
        if (e.target.name === "tracing" && e.target?.type !== "circle") {
          canvas.current.remove(e.target);
          canvas.current.requestRenderAll();
          updateTracing(
            canvas,
            setTracings,
            setTracingIntialValue,
            postTrasing
          );
        } else if (e.target.name === "text") {
          canvas.current.remove(e.target);
          canvas.current.requestRenderAll();
          updateText(canvas, setTexts, postTexts);
        } else if (e.target.name === "tracing" && e.target?.type === "circle") {
          canvas.current.remove(e.target);
          canvas.current.requestRenderAll();
          updateTracingCircle(
            canvas,
            setTracingCircle,
            setTracingIntialValue,
            postTrasingCircle
          );
        }
      }
      if (
        activeTab === "locations" &&
        addNewLocation &&
        !obj &&
        !firstClick?.location?.x &&
        selLocationDtls?.position /* new darg condition */
      ) {

        canvas.current.forEachObject(function (obj) {
          if (obj.name == "temp_loc") {
            canvas.current.remove(obj);
          }
        });

        removeFabricObjectsBId(canvas, selLocationDtls?.enc_id);
        let mouse = canvas.current.getPointer(e.e);
        let vertices = getSquareCoordinates(mouse.x, mouse.y, 50);

        if (
          selLocationDtls?.boundary_attributes &&
          selLocationDtls?.boundary_attributes != "null"
        ) {
          vertices = selLocationDtls?.boundary_attributes;
        }

        let fillColor =
          selLocationDtls?.location_color ??
          projectSettings?.location_color ??
          "red";
        let locationIcon = getLocationPin(fillColor);
        addPins(
          canvas,
          mouse,
          locationIcon,
          "temp_loc",
          setSelLocationDtls,
          selLocationDtls,
          "locationSubmitBtn"
        );
        firstClick = { location: { x: mouse?.x, y: mouse?.y } };
      } else if (
        activeTab === "products" &&
        addNewProduct &&
        !obj &&
        !firstClick?.product &&
        selProductDtls?.position /* new darg condition */
      ) {
        // console.log(selProductDtls, "selProductDtls");
        canvas.current.forEachObject(function (obj) {
          if (obj.name == "temp_prod") {
            canvas.current.remove(obj);
          }
        });
        removeFabricObjectsBId(canvas, selProductDtls?.enc_id);
        let mouse = canvas.current.getPointer(e.e);
        let fillColor =
          selProductDtls?.product_color ??
          projectSettings?.product_color ??
          "red";
        let productIcon = getProductPin(fillColor);
        addPins(
          canvas,
          mouse,
          productIcon,
          "temp_prod",
          setSelProductDtls,
          selProductDtls,
          "productSubmitBtn"
        );
        firstClick = { product: { x: mouse?.x, y: mouse?.y } };
      } else if (
        activeTab === "beacons" &&
        addNewQrCodeBeacon &&
        !obj &&
        !firstClick?.beacon
      ) {
        // canvas.current.forEachObject(function (obj) {
        //   if (obj.name == "temp_beacon") {
        //     canvas.current.remove(obj);
        //   }
        // });
        console.log(e.target, obj, 'e.target.name')
        // removeFabricObjectsBId(canvas, selBeaconDtls?.enc_id);
        let mouse = canvas.current.getPointer(e.e);
        let fillColor =
          selBeaconDtls?.beacon_color ?? projectSettings?.beacon_color ?? "red";
        let beaconIcon = getBeaconPin(fillColor);
        addPins(
          canvas,
          mouse,
          beaconIcon,
          "temp_beacon",
          setSelBeaconDtls,
          selBeaconDtls,
          "beaconSubmitBtn"
        );
        firstClick = { beacon: { x: mouse?.x, y: mouse?.y } };
      } else if (
        activeTab === "amenitys" &&
        addNewAmenity &&
        !obj &&
        !firstClick?.amenity
      ) {
        canvas.current.forEachObject(function (obj) {
          if (obj.name == "temp_amenity") {
            console.log(obj.name, 'remove')
            canvas.current.remove(obj);
          }
        });
        removeFabricObjectsBId(canvas, selAmenityDtls?.enc_id);
        let mouse = canvas.current.getPointer(e.e);
        let fillColor =
          selAmenityDtls?.amenity_color ??
          projectSettings?.amenity_color ??
          "red";
        let amenityIcon = selAmenityDtls?.icon_path ?? getAmenityPin(fillColor);
        addPins(
          canvas,
          mouse,
          amenityIcon,
          "temp_amenity",
          setSelAmenityDtls,
          selAmenityDtls,
          "amenitySubmitBtn"
        );
        firstClick = { amenity: { x: mouse?.x, y: mouse?.y } };
      } else if (
        activeTab === "safety" &&
        addNewSafety &&
        !obj &&
        !firstClick?.safety
      ) {
        canvas.current.forEachObject(function (obj) {
          if (obj.name == "temp_safety") {
            canvas.current.remove(obj);
          }
        });
        removeFabricObjectsBId(canvas, selSafetyDtls?.enc_id);
        let mouse = canvas.current.getPointer(e.e);
        let fillColor =
          selSafetyDtls?.safety_color ?? projectSettings?.safety_color ?? "red";
        let safetyIcon = selSafetyDtls?.icon_path ?? getSafetyPin(fillColor);
        addPins(
          canvas,
          mouse,
          safetyIcon,
          "temp_safety",
          setSelSafetyDtls,
          selSafetyDtls,
          "safetySubmitBtn"
        );
        firstClick = { safety: { x: mouse?.x, y: mouse?.y } };
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Draw" &&
        !obj &&
        addNewTraversablePath &&
        !panToolVariabl
      ) {
        console.log(e.target, 'e.target')
        /* New condition for main path */
        if (
          objPinNamesOnly?.includes(e?.target?.name) &&
          e.target?.types !== "text_field"
        ) {
          toast.warning("Sorry! you can't add the main path to pins.");
          return;
        }

        let itemInGraph = graph.edges[e.target?.id];
        if (
          itemInGraph &&
          Object.keys(itemInGraph)?.some((item) => item?.includes("_"))
        ) {
          toast.warning("Sorry! You can't add edge to this node.");
          return;
        }

        let mouse = canvas.current.getPointer(e.e);
        checkPinConnectOrNot();

        if (e?.target?.name == "path" && mouseDown) {
          let removedLine = e?.target;
          createNodeBtwPolyline(e, mouse, removedLine);
        }
        if (e?.target?.name == "path" || e?.target?.name == "line_starter_poly")
          return;

        // create corner points at polyline end and start
        let nodeName = generateNodeName(graph);
        // console.log(nodeName, "nodename")
        let nodeAdded = false;
        let nodeCoords = {
          x: mouse?.x,
          y: mouse?.y,
        };
        console.log(nodeCoords, "nodeCoords");
        if (
          !e.target ||
          e.target?.name == "backgroundRect" ||
          e.target.name == "svg_refImage" ||
          e.target?.name == "tracing" ||
          e.target?.name == "boundary" ||
          e?.target?.name == "text" ||
          e?.target?.types == "text_field"
        ) {
          let node = addNodePoint(nodeCoords, nodeName);

          if (e?.target?.types == "text_field") {
            canvas.current.add(node);
            const pinNameObj = e?.target;
            let updatedNodeName = pinNameObj.node_name
              ? [...pinNameObj.node_name, nodeName]
              : [nodeName];
            pinNameObj.set({
              node_name: nodeName,
              node_coords: nodeCoords,
            });
            graph.highlightNode(nodeName);
          } else {
            canvas.current.add(node);
          }
        }

        let center = e.target?.getCenterPoint();
        let coords =
          e.target &&
            e.target.name != "backgroundRect" &&
            e.target.name != "svg_refImage" &&
            e.target?.name != "line_starter_poly" &&
            e.target.name != "tracing" &&
            e.target.name != "path" &&
            e.target.name != "boundary" &&
            e.target.name != "text" &&
            e.target.types != "text_field"
            ? { x: center?.x, y: center?.y }
            : { x: mouse?.x, y: mouse?.y };

        poly = false;
        polyBtn = "";
        lastPt = 1;
        pts = [];
        console.log(e?.target, toolTraversible, "setTraversibleHistory");
        polyline = addPolyLine(pts, "line_starter_poly");
        canvas.current.add(polyline);
        polyline.points[pts?.length] = coords;
        lastPt++;
        mouseDown = true;

        prevMouseClick = { x: mouse.x, y: mouse.y };
        if (
          (e.target &&
            e.target.name != "backgroundRect" &&
            e.target?.name != "line_starter_poly" &&
            e.target.name != "tracing" &&
            e.target.name != "path" &&
            e.target.name != "boundary" &&
            e.target.name != "svg_refImage" &&
            e.target.name != "text") ||
          e?.target?.types == "text_field"
        ) {
          if (e.target.name == "node" || e?.target?.types == "text_field") {
            if (graph.nodes.has(e.target?.id)) {
              nodeAdded = false;
            } else if (e?.target?.types != "text_field") {
              nodeAdded = true;
            } else {
              nodeAdded = true;
              console.log(e?.target, "e?.target");
            }
            if (e?.target?.types != "text_field") {
              onCreateNode(coords, e.target?.id);
            } else {
              onCreateNode(coords, e?.target?.node_name);
            }
          } else {
            console.log(e.target.name, 'e.target')
            if (graph.nodes.has(`${e.target.name}_${e.target.enc_id}`)) {
              nodeAdded = false;
            } else {
              nodeAdded = true;
            }
            onCreateNode(coords, `${e.target.name}_${e.target.enc_id}`);

            // renderTraversiblePaths()
            checkPinConnectOrNot();
          }
        } else {
          if (graph.nodes.has(nodeName)) {
            nodeAdded = false;
          } else {
            nodeAdded = true;
          }
          onCreateNode(coords, nodeName);
          // renderTraversiblePaths()
          checkPinConnectOrNot();
        }

        if (key1 && key2 && key1 != key2) {
          if (!nodeAdded && key1 != key2) {
          }
          console.log(key1, key2)
          addConnectionBtwnEdges(key1, key2, graph, canvas);
        }

        if (
          key1 &&
          key2 &&
          key1 !== key2 &&
          e.target &&
          e.target?.name != "tracing" &&
          e.target?.name != "line_starter_poly" &&
          e.target.name != "backgroundRect" &&
          e.target.name != "svg_refImage" &&
          e.target.name != "path" &&
          e.target.name != "boundary" &&
          e.target.name != "text" &&
          e?.target?.types != "text_field" &&
          !objPinNamesOnly?.includes(e.target.name)
        ) {
          stopPathDrawing();
          // undoTraversablePath(graph.getPositions(), graph.getEdges())
        }
        bringFabricObjectsToFrontByName(canvas, "node");
        bringFabricObjectsToFrontByName(canvas, "location");
        bringFabricObjectsToFrontByName(canvas, "product");
        bringFabricObjectsToFrontByName(canvas, "beacon");
        bringFabricObjectsToFrontByName(canvas, "safety");
        bringFabricObjectsToFrontByName(canvas, "amenity");
        bringFabricObjectsToFrontByName(canvas, "vertical");
        bringFabricObjectsToFrontByName(canvas, "text");

        graph?.getHighlightNode()?.forEach((item) => {
          bringToFrontPinNameNodes(canvas, item);
        });

        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
        navigationPathZoomLevel(
          canvas,
          canvas.current.getZoom(),
          projectSettings
        );
        // setNodes(prev => [...prev,coords])
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "sub_path" &&
        !obj &&
        addNewTraversablePath &&
        !panToolVariabl
      ) {
        console.log(graph, "graph");

        if (e?.target?.types === "text_field") {
          toast.warning("Sorry! you can't add the sub path to node.");
          return;
        }

        if (e.target?.name === "path") {
          const lineId = e.target?.id
            .split("$")
            .slice(1)
            .some((item) => item.includes("_"));
          if (lineId) {
            toast.warning(
              "Sorry! You can't connect a subpath to another subpath."
            );
            return;
          }
        }

        if (e.target?.name === "node") {
          let itemInGraph = graph.edges[e.target?.id];
          if (
            itemInGraph &&
            Object.keys(itemInGraph)?.some((item) => item?.includes("_"))
          ) {
            toast.warning("Sorry! You can't add edge to this node.");
            return;
          }
        }

        if (
          !objPinNamesOnly.includes(e?.target?.name) &&
          !nodeNameArray[0]?.includes("_")
        ) {
          toast.warning("Please click on a pin to start drawing.");
          return;
        }

        let mouse = canvas.current.getPointer(e.e);

        if (["node"]?.includes(e?.target?.name)) {
          let pathsarray = canvas.current
            .getObjects()
            .filter(
              (item) =>
                item.name == "path" &&
                item.id.split("$").slice(1)?.includes(e.target.id)
            );
          if (pathsarray.length > 0) {
            let removedLine = pathsarray[0];
            createNodeBtwPolyline(e, mouse, removedLine);
            return;
          } else {
            toast.warning("Sorry! you can't add the sub path to node.");
            return;
          }
        }

        checkPinConnectOrNot();

        if (e?.target?.name == "path" && mouseDown) {
          let removedLine = e?.target;
          createNodeBtwPolyline(e, mouse, removedLine);
        }
        if (e?.target?.name == "path" || e?.target?.name == "line_starter_poly")
          return;

        // create corner points at polyline end and start
        let nodeName = generateNodeName(graph);

        if (e.target && e.target.name && e.target.enc_id) {
          nodeNameArray.push(`${e.target.name}_${e.target.enc_id}`);
        } else {
          nodeNameArray.push(nodeName);
        }

        if (
          mouseDown &&
          ((nodeNameArray[0]?.includes("_") &&
            objPinNamesOnly.includes(e?.target?.name)) ||
            (!nodeNameArray[0]?.includes("_") &&
              !objPinNamesOnly.includes(e?.target?.name)))
        ) {
          toast.warning("Sorry! you can't add the sub path here.");
          return;
        }
        // }

        //used to prevent adding line to traversible history while adding node
        let nodeAdded = false;
        let nodeCoords = {
          x: mouse?.x,
          y: mouse?.y,
        };

        if (
          !e.target ||
          e.target?.name == "backgroundRect" ||
          e.target?.name == "tracing" ||
          e.target.name == "svg_refImage" ||
          e.target?.name == "boundary" ||
          e?.target?.name == "text" ||
          e?.target?.types == "text_field"
        ) {
          let node = addNodePoint(nodeCoords, nodeName);
          console.log(node, 'canvas.current')
          // if (e?.target?.types == "text_field" && !e?.target?.node_name) {
          if (e?.target?.types == "text_field") {
            // console.log("if condition");
            canvas.current.add(node);
            const pinNameObj = e?.target;
            let updatedNodeName = pinNameObj.node_name
              ? [...pinNameObj.node_name, nodeName]
              : [nodeName];
            pinNameObj.set({
              node_name: nodeName,
              node_coords: nodeCoords,
            });
            graph.highlightNode(nodeName);
          } else {
            canvas.current.add(node);
            graph.addSubnode(nodeName);
            console.log(graph.getSubNode(),'graph')
          }
        }

        // //------------------- restric subpath drawing -------------------
        // if (e.target && e.target.name && e.target.enc_id) {
        //   nodeNameArray.push(`${e.target.name}_${e.target.enc_id}`);
        // } else {
        //   nodeNameArray.push(nodeName);
        // }

        // if (nodeNameArray.length > 1 && nodeNameArray[nodeNameArray.length - 1]?.includes("_")) {
        //   stopPathDrawing()
        //   return
        // }
        // //------------------- restric subpath drawing -------------------

        let center = e.target?.getCenterPoint();
        let coords =
          e.target &&
            e.target.name != "backgroundRect" &&
            e.target.name != "svg_refImage" &&
            e.target?.name != "line_starter_poly" &&
            e.target.name != "tracing" &&
            e.target.name != "path" &&
            e.target.name != "boundary" &&
            e.target.name != "text" &&
            e.target.types != "text_field"
            ? { x: center?.x, y: center?.y }
            : { x: mouse?.x, y: mouse?.y };

        poly = false;
        polyBtn = "";
        lastPt = 1;
        pts = [];
        polyline = addPolyLine(pts, "line_starter_poly");
        canvas.current.add(polyline);
        polyline.points[pts?.length] = coords;
        lastPt++;
        mouseDown = true;
        prevMouseClick = { x: mouse.x, y: mouse.y };
        if (
          (e.target &&
            e.target.name != "backgroundRect" &&
            e.target.name != "svg_refImage" &&
            e.target?.name != "line_starter_poly" &&
            e.target.name != "tracing" &&
            e.target.name != "path" &&
            e.target.name != "boundary" &&
            e.target.name != "text") ||
          e?.target?.types == "text_field"
        ) {
          if (e.target.name == "node" || e?.target?.types == "text_field") {
            if (graph.nodes.has(e.target?.id)) {
              nodeAdded = false;

              //------------------- restric subpath drawing -------------------
              let itemInGraph = graph.edges[e.target?.id];
              if (
                itemInGraph &&
                Object.keys(itemInGraph)?.some((item) => item?.includes("_"))
              ) {
                bringFabricObjectsToFrontByName(canvas, "node");
                bringFabricObjectsToFrontByName(canvas, "location");
                bringFabricObjectsToFrontByName(canvas, "product");
                bringFabricObjectsToFrontByName(canvas, "beacon");
                bringFabricObjectsToFrontByName(canvas, "safety");
                bringFabricObjectsToFrontByName(canvas, "amenity");
                bringFabricObjectsToFrontByName(canvas, "vertical");
                bringFabricObjectsToFrontByName(canvas, "text");
                stopPathDrawing();
                return;
              }
              if (nodeNameArray.some((item) => item?.includes("_"))) {
                bringFabricObjectsToFrontByName(canvas, "node");
                bringFabricObjectsToFrontByName(canvas, "location");
                bringFabricObjectsToFrontByName(canvas, "product");
                bringFabricObjectsToFrontByName(canvas, "beacon");
                bringFabricObjectsToFrontByName(canvas, "safety");
                bringFabricObjectsToFrontByName(canvas, "amenity");
                bringFabricObjectsToFrontByName(canvas, "vertical");
                bringFabricObjectsToFrontByName(canvas, "text");
                stopPathDrawing();
                return;
              }
              //------------------- restric subpath drawing -------------------
            } else if (e?.target?.types != "text_field") {
              nodeAdded = true;
              setTraversibleHistory((prev) => [
                ...prev,
                {
                  type: "nodeAndLine",
                  action: "create",
                  data: {
                    nodeName: e.target.id,
                    key1,
                    key2,
                  },
                },
              ]);
            } else {
              nodeAdded = true;
              console.log(e?.target, "e?.target");
              setTraversibleHistory((prev) => [
                ...prev,
                {
                  type: "nodeAndLine",
                  action: "create",
                  data: {
                    nodeName: e?.target?.node_name,
                    key1,
                    key2,
                  },
                },
              ]);
            }
            if (e?.target?.types != "text_field") {
              onCreateNode(coords, e.target?.id);
            } else {
              onCreateNode(coords, e?.target?.node_name);
            }
          } else {
            if (graph.nodes.has(`${e.target.name}_${e.target.enc_id}`)) {
              nodeAdded = false;
            } else {
              nodeAdded = true;
              setTraversibleHistory((prev) => [
                ...prev,
                {
                  type: "nodeAndLine",
                  action: "create",
                  data: {
                    nodeName: `${e.target.name}_${e.target.enc_id}`,
                    key1,
                    key2,
                  },
                },
              ]);
            }

            onCreateNode(coords, `${e.target.name}_${e.target.enc_id}`);
            // renderTraversiblePaths()
            checkPinConnectOrNot();
          }
        } else {
          if (graph.nodes.has(nodeName)) {
            nodeAdded = false;
          } else {
            nodeAdded = true;
            setTraversibleHistory((prev) => [
              ...prev,
              {
                type: "nodeAndLine",
                action: "create",
                data: {
                  nodeName,
                  key1,
                  key2,
                },
              },
            ]);
          }
          onCreateNode(coords, nodeName);
          // renderTraversiblePaths()
          checkPinConnectOrNot();
        }

        if (key1 && key2 && key1 != key2) {
          if (!nodeAdded && key1 != key2) {
            setTraversibleHistory((prev) => [
              ...prev,
              {
                type: "line",
                action: "create",
                data: {
                  key1,
                  key2,
                },
              },
            ]);
          }
          addConnectionBtwnEdges(key1, key2, graph, canvas);
        }

        //------------------- restric subpath drawing -------------------
        if (
          nodeNameArray.length > 1 &&
          nodeNameArray.some((item) => item.includes("_"))
        ) {
          bringFabricObjectsToFrontByName(canvas, "node");
          bringFabricObjectsToFrontByName(canvas, "location");
          bringFabricObjectsToFrontByName(canvas, "product");
          bringFabricObjectsToFrontByName(canvas, "beacon");
          bringFabricObjectsToFrontByName(canvas, "safety");
          bringFabricObjectsToFrontByName(canvas, "amenity");
          bringFabricObjectsToFrontByName(canvas, "vertical");
          bringFabricObjectsToFrontByName(canvas, "text");
          stopPathDrawing();
          return;
        }
        //------------------- restric subpath drawing -------------------

        if (
          key1 &&
          key2 &&
          key1 !== key2 &&
          e.target &&
          e.target?.name != "tracing" &&
          e.target?.name != "line_starter_poly" &&
          e.target.name != "backgroundRect" &&
          e.target.name != "svg_refImage" &&
          e.target.name != "path" &&
          e.target.name != "boundary" &&
          e.target.name != "text" &&
          e?.target?.types != "text_field"
        ) {
          stopPathDrawing();
          // undoTraversablePath(graph.getPositions(), graph.getEdges())
        }
        bringFabricObjectsToFrontByName(canvas, "node");
        bringFabricObjectsToFrontByName(canvas, "location");
        bringFabricObjectsToFrontByName(canvas, "product");
        bringFabricObjectsToFrontByName(canvas, "beacon");
        bringFabricObjectsToFrontByName(canvas, "safety");
        bringFabricObjectsToFrontByName(canvas, "amenity");
        bringFabricObjectsToFrontByName(canvas, "vertical");
        bringFabricObjectsToFrontByName(canvas, "text");

        graph?.getHighlightNode()?.forEach((item) => {
          bringToFrontPinNameNodes(canvas, item);
        });

        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
        navigationPathZoomLevel(
          canvas,
          canvas.current.getZoom(),
          projectSettings
        );
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Erase" &&
        !obj &&
        addNewTraversablePath
      ) {
        let obj = e.target;
        if (obj?.name == "node") {
          let edges = graph.getEdges();
          if (edges[obj.id]) {
            deleteSubPath(obj);
            removeNode(obj.id, true, graph, canvas);
          }
        } else if (obj?.name == "path") {
          removeLine(obj.id, graph, canvas);
          deleteSubPath(obj);
        }
        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
        checkPinConnectOrNot();
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        // obj &&
        addNewTraversablePath
      ) {
        // console.log(e, "target", graph.nodes);
        if (e.target?.type === "activeSelection") {
          mouseDownSelect = canvas.current.getPointer(e.e)
        }
        if (e?.target?.name == "path") {
          // console.log(e?.target?.id?.split("$"),e?.target?.stroke,"e?.target?.stroke");
          if (e?.target?.stroke === "green") {
            canvas.current.forEachObject(obj => {
              if (obj.name === "node" && obj.fill === "rgba(0,255,0,0.5)") {
                canvas.current.bringToFront(obj);
              }
            })
          } else if (e?.target?.stroke === "black") {
            canvas.current.forEachObject(obj => {
              if (obj.name === "node" && obj.fill === "rgba(0,0,255,0.5)") {
                if (!obj.id.includes("_")) {
                  canvas.current.bringToFront(obj);
                }
              }
            })
          }
        }

        if (e?.target?.name == "node") {
          // new change
          // e.target.selectable = true;
          // e.target.lockMovementX = false;
          // e.target.lockMovementY = false;
          // e.target.lockRotation = true;
          // e.target.lockScalingX = true;
          // e.target.lockScalingY = true;
          // e.target.hasControls = false;
          // e.target.hasBorders = false;
          // // mouseDown = true
          // canvas.current.renderAll();
          // ---------------------
          // let objs = canvas.current.getObjects()
          // const newColor = 'black';
          // highLightSelectedPaths(canvas, objs, newColor)
          // nodeLinesSelected(obj, graph, canvas)
          if (e?.target?.fill === "rgba(0,255,0,0.5)") {
            canvas.current.forEachObject(obj => {
              if (obj.name === "node" && obj.fill === "rgba(0,255,0,0.5)") {
                canvas.current.bringToFront(obj);
              }
            })
          } else if (e?.target?.fill === "rgba(0,0,255,0.5)") {
            canvas.current.forEachObject(obj => {
              if (obj.name === "node" && obj.fill === "rgba(0,0,255,0.5)") {
                if (!obj.id.includes("_")) {
                  canvas.current.bringToFront(obj);
                }
              }
            })
          }
        }

      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Erase" &&
        !obj &&
        addNewTraversablePath
      ) {
        let obj = e.target;
        if (obj?.name == "node") {
          let edges = graph.getEdges();
          if (edges[obj.id]) {
            const linesDeleted = Object.keys(edges[obj.id]).map((a) => ({
              key1: obj.id,
              key2: a,
            }));
            const nodePosition = graph.positions[obj.id];
            setTraversibleHistory((prev) => [
              ...prev,
              {
                type: "nodeAndLine",
                action: "delete",
                data: {
                  nodeName: obj.id,
                  nodePosition,
                  lines: linesDeleted,
                },
              },
            ]);
          }
          removeNode(obj.id, true, graph, canvas);
        } else if (obj?.name == "path") {
          removeLine(obj.id, graph, canvas);
        }
        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
      } else if (
        activeTab === "verticalTransport" &&
        addNewVertical &&
        !obj &&
        !firstClick?.verticalTransport &&
        verticalFloorId
      ) {
        console.log(selVerticalDtls, "select");
        canvas.current.forEachObject(function (obj) {
          if (obj.name == "temp_vertical") {
            canvas.current.remove(obj);
          }
        });
        removeFabricObjectsBId(canvas, selVerticalDtls?.enc_id);
        let fillColor =
          selVerticalDtls?.vt_color ??
          projectSettings?.level_change_color ??
          "red";
        let verticalIcon =
          selVerticalDtls?.icon_path ?? getVerticalPin(fillColor);
        let path = fabric.loadSVGFromString(
          verticalIcon,
          function (objects, options) {
            let obj = fabric.util.groupSVGElements(objects, options);

            let mouse = canvas.current.getPointer(e.e);
            obj.set({
              left: mouse?.x - obj.width / 2,
              top: mouse?.y - obj.height / 2,
              name: "temp_vertical",
              lockRotation: true,
              lockScalingX: true,
              lockScalingY: true,
              hoverCursor: "grab",
            });
            canvas.current.add(obj).renderAll();
            if (verticalFloorId) {
              selVerticalDtls?.connectionPins?.forEach((item) => {
                if (item?.value == verticalFloorId) {
                  // item.value
                  (item.value = item?.value),
                    (item.label = item?.label),
                    (item.position = { x: mouse?.x, y: mouse?.y });
                }
              });
              let values = {
                // ...selVerticalDtls,
                position: { x: mouse?.x, y: mouse?.y },
              };
              setselVerticalDtls((prev) => ({ ...prev, ...values }));
            }
            firstClick = { verticalTransport: { x: mouse?.x, y: mouse?.y } };
            document.getElementById("transportSubmitBtn")?.click();
            // canvasBackgroundImageHandler(null);
          }
        );
      }
    });

    canvas.current.on("text:changed", function (e) {
      const textObject = e.target;
      // console.log(textObject, "textObject");
      if (isEnterKey) {
        const newText = textObject.text + "\n";
        textObject.set("text", newText);
        canvas.current.renderAll();
      } else {
        const newText = textObject.text.replace(/[^\S\n]/g, "\u00A0"); // Remove spaces
        textObject.set("text", newText);
        canvas.current.renderAll();
      }
      updateText(canvas, setTexts, postTexts);
    });

    canvas.current.on("object:modified", function (e) {
      console.log(e, "modified");
      const obj1 = e.target;
      // console.log(obj1, "modified");
      let panToolVariabl = false;
      setPanTool((prev) => {
        panToolVariabl = prev;
        return prev;
      });
      if (obj && !panToolVariabl) {
        let mouse = canvas.current.getPointer(e.e);

        if (activeTab === "floorDetails" && addNewFloor) {
          updateTracing(
            canvas,
            setTracings,
            setTracingIntialValue,
            postTrasing
          );
          updateText(canvas, setTexts, postTexts);
          updateTracingCircle(
            canvas,
            setTracingCircle,
            setTracingIntialValue,
            postTrasingCircle
          );
          if (obj?.name == "tracing" && obj.type === "polygon") {
            removeFabricObjectsByName(canvas, "tracing_obj_length");
            const points = getPolygonVertices(obj);
            console.log(points, "points-modified");
            showObjLength(obj, points, canvas);
            tracingLengthZoomLevel(canvas, canvas.current.getZoom());
          }
        } else if (activeTab === "locations" && addNewLocation) {
          if (obj.name == "boundary") {
            boundaryAttributes = getPolygonVertices(obj);

            prevSelectedBoundary = canvas.current.getActiveObject();

            let locPosition;
            canvas.current.forEachObject((obj1) => {
              if (
                obj1.name == "temp_loc" ||
                obj1.enc_id == selLocationDtls?.enc_id
              ) {
                locPosition = obj1.getCenterPoint();
              }
              canvas.current.bringToFront(obj1);
            });
            setSelLocationDtls((prev) => ({
              ...prev,
              boundary_attributes: boundaryAttributes,
              position: { x: locPosition?.x, y: locPosition?.y },
            }));
            nodePositionUpdate(
              "location",
              selLocationDtls,
              {
                x: locPosition?.x,
                y: locPosition?.y,
              },
              graph,
              setSelTraversibleDetails
            );
          } else {
            // let boundaryAttributes;
            canvas.current.forEachObject((obj1) => {
              if (
                obj1.id == "temp_boundary" ||
                obj1.id == `boundary_${selLocationDtls?.enc_id}`
              ) {
                boundaryAttributes = getPolygonVertices(obj1);
                obj1.set("selectable", true);
                canvas.current.renderAll();
              }
              // canvas.current.bringToFront(obj1)
            });
            console.log(selLocationDtls, "selLocationDtls");
            setSelLocationDtls((prev) => ({
              ...prev,
              boundary_attributes: boundaryAttributes,
              position: { x: mouse?.x, y: mouse?.y },
            }));
            nodePositionUpdate(
              "location",
              selLocationDtls,
              {
                x: mouse?.x,
                y: mouse?.y,
              },
              graph,
              setSelTraversibleDetails
            );
          }
          setIsDirty(true);
          document.getElementById("locationSubmitBtn")?.click();

          // setMousetrigger((prev) => {
          //   if (prev === 0) {
          //     prev = prev + 1
          //   } else {
          //     prev = prev - 1
          //   }
          //   return prev
          // })

          // console.log(activeTab === "locations" && addNewLocation,"activeT")
        } else if (activeTab === "products" && addNewProduct) {
          setSelProductDtls((prev) => ({
            ...prev,
            position: { x: mouse?.x, y: mouse?.y },
          }));

          // if (selProductDtls?.enc_id) {
          nodePositionUpdate(
            "product",
            selProductDtls,
            {
              x: mouse?.x,
              y: mouse?.y,
            },
            graph,
            setSelTraversibleDetails
          );
          setIsDirty(true);
          document.getElementById("productSubmitBtn")?.click();
          // }
        } else if (activeTab === "beacons" && addNewQrCodeBeacon) {
          setSelBeaconDtls((prev) => ({
            ...prev,
            position: { x: mouse?.x, y: mouse?.y },
          }));
          nodePositionUpdate(
            "beacon",
            selBeaconDtls,
            {
              x: mouse?.x,
              y: mouse?.y,
            },
            graph,
            setSelTraversibleDetails
          );
          document.getElementById("beaconSubmitBtn")?.click();
        } else if (activeTab === "amenitys" && addNewAmenity) {
          let selAmenityDtls;
          setSelAmenityDtls((prev) => {
            selAmenityDtls = prev;
            return prev;
          });
          if (selAmenityDtls?.enc_id) {
            setSelAmenityDtls((prev) => ({
              ...prev,
              position: { x: mouse?.x, y: mouse?.y },
            }));
            nodePositionUpdate(
              "amenity",
              selAmenityDtls,
              {
                x: mouse?.x,
                y: mouse?.y,
              },
              graph,
              setSelTraversibleDetails
            );
            // setIsDirty(true)
            document.getElementById("amenitySubmitBtn")?.click();
          }
        } else if (activeTab === "safety" && addNewSafety) {
          let selSafetyDtls;
          setSelSafetyDtls((prev) => {
            selSafetyDtls = prev;
            return prev;
          });
          if (selSafetyDtls?.enc_id) {
            setSelSafetyDtls((prev) => ({
              ...prev,
              position: { x: mouse?.x, y: mouse?.y },
            }));
            nodePositionUpdate(
              "safety",
              selSafetyDtls,
              {
                x: mouse?.x,
                y: mouse?.y,
              },
              graph,
              setSelTraversibleDetails
            );
            // setIsDirty(true)
            document.getElementById("safetySubmitBtn")?.click();
          }
        } else if (activeTab === "verticalTransport" && addNewVertical) {
          let selVerticalDtlsVar;
          setselVerticalDtls((prev) => {
            selVerticalDtlsVar = prev;
            return prev;
          });
          selVerticalDtlsVar?.connectionPins?.forEach((item) => {
            console.log(item, "item");
            if (item?.value == (verticalFloorId ?? e.target?.floor_id)) {
              (item.value = item?.value),
                (item.label = item?.label),
                (item.position = { x: mouse?.x, y: mouse?.y });
            }
          });
          let values = {
            ...selVerticalDtlsVar,
            position: { x: mouse?.x, y: mouse?.y },
          };
          // console.log(values, "values");
          setselVerticalDtls((prev) => ({
            ...prev,
            ...values,
          }));
          nodePositionUpdate(
            "vertical",
            e.target,
            {
              x: mouse?.x,
              y: mouse?.y,
            },
            graph,
            setSelTraversibleDetails
          );
          // removeFabricObjectsEncId(canvas, e?.target?.enc_id, 'vertical')
          document.getElementById("transportSubmitBtn")?.click();
        }
        /* Drag pin */
        if (
          checkConditionDrag() &&
          [
            "location",
            "product",
            "beacon",
            "amenity",
            "safety",
            "vertical",
          ]?.includes(obj1?.name)
        ) {
          const center = obj1.getCenterPoint();
          nodePositionUpdate(
            obj1?.name,
            obj1,
            {
              x: center?.x,
              y: center?.y,
            },
            graph,
            setSelTraversibleDetails
          );
          updatePinPosition(obj1, center, activeTab)
          changeFabricObjectSelectionByName(canvas, "node", true);
          changeFabricObjectSelectionByName(canvas, "path", true);

        }
      }
    });

    canvas.current.on("object:moving", function (e) {
      let panToolVariabl = false;
      setPanTool(false);
      const newObj = e?.target;
      // console.log(newObj, 'object:moving')
      let mouse = canvas.current.getPointer(e.e);
      if (!panToolVariabl) {
        if (activeTab === "floorDetails" && newObj?.name === "tracing") {
          removeFabricObjectsByName(canvas, "tracing_obj_length");
        }
        if (activeTab == "locations" && addNewLocation) {
          if (newObj?.name == "boundary") {
            canvas.current.forEachObject((obj1) => {
              if (
                obj1.name == "temp_loc" ||
                obj1?.id == selLocationDtls?.enc_id
              ) {
                let cneterPoint = newObj.getCenterPoint();
                obj1.set(
                  "left",
                  // obj1.left +
                  cneterPoint.x - obj1.width / 2
                  // originalObjCenterPoints.x
                );
                obj1.set(
                  "top",
                  // obj1.top +
                  cneterPoint.y - obj1.height / 2
                  // originalObjCenterPoints.y
                );
                obj1.setCoords();
              }
            });
            canvas.current.renderAll();
          } else {
            canvas.current.forEachObject((obj1) => {
              if (
                obj1.id == "temp_boundary" ||
                // obj1.id == `boundary_${selLocationDtls?.enc_id}`
                obj1.id == `boundary_${e.target?.enc_id}`
              ) {
                // console.log(obj1, "centerpoint");

                let centerPointpin = newObj.getCenterPoint();
                // let centerPointBoundary = obj1.getCenterPoint();
                // console.log(originalObjCenterPoints, centerPointBoundary, obj1.left, "centerpoint");

                obj1.set(
                  "left",
                  obj1.left +
                  parseInt(centerPointpin.x) -
                  parseInt(originalObjCenterPoints.x)
                );
                obj1.set(
                  "top",
                  obj1.top +
                  parseInt(centerPointpin.y) -
                  parseInt(originalObjCenterPoints.y)
                );

                // obj1.set(
                //   "left",
                //   newObj.left +
                //   parseInt(centerPointpin.x) -
                //   parseInt(originalObjCenterPoints.x)+newObj.width/2
                // );
                // obj1.set(
                //   "top",
                //   newObj.top +
                //   parseInt(centerPointpin.y) -
                //   parseInt(originalObjCenterPoints.y)+newObj.height/2
                // );
                obj1.setCoords();
                originalObjCenterPoints = centerPointpin;
                // canvas.current.bringToFront(obj1);
              }
            });

            // moveDraggedPinAndName(canvas, newObj, 'location')
          }
          canvas.current.renderAll();
        }
        if (checkConditionDrag() && ['product', 'beacon', 'amenity', 'safety', 'vertical']?.includes(newObj?.name)) {
          moveDraggedPinAndName(canvas, newObj, newObj?.name)
          // if (activeTab === 'traversable' && toolTraversible === 'Drag_pin') {
          if (activeTab === 'traversable' && toolTraversible === 'Select') {
            const center = newObj.getCenterPoint()
            nodePositionUpdate(
              newObj?.name,
              newObj,
              {
                x: center?.x,
                y: center?.y,
              },
              graph,
              setSelTraversibleDetails
            );
            renderTraversiblePaths()
          }
        } else if (checkConditionDrag() && newObj?.name === "location") {
          canvas.current.forEachObject((obj1) => {
            if (
              obj1.id == "temp_boundary" ||
              // obj1.id == `boundary_${selLocationDtls?.enc_id}`
              obj1.id == `boundary_${e.target?.enc_id}`
            ) {
              boundaryAttributes = getPolygonVertices(obj1)
              console.log(boundaryAttributes, 'temp_boundary')
              let cneterPoint = newObj.getCenterPoint();

              obj1.set(
                "left",
                obj1.left +
                parseInt(cneterPoint.x) -
                parseInt(originalObjCenterPoints.x)
              );
              obj1.set(
                "top",
                obj1.top +
                parseInt(cneterPoint.y) -
                parseInt(originalObjCenterPoints.y)
              );
              obj1.setCoords();
              originalObjCenterPoints = cneterPoint;
              // canvas.current.bringToFront(obj1);
            }
          });
          moveDraggedPinAndName(canvas, newObj, 'location')
          // if (activeTab === 'traversable' && toolTraversible === 'Drag_pin') {
          if (activeTab === 'traversable' && toolTraversible === 'Select') {
            const center = newObj.getCenterPoint()
            nodePositionUpdate(
              newObj?.name,
              newObj,
              {
                x: center?.x,
                y: center?.y,
              },
              graph,
              setSelTraversibleDetails
            );
            // const nodeId = `${newObj?.name}_${newObj?.enc_id}`;
            // let node = findObjectById(nodeId, canvas)
            // node.set({
            //   left: center?.x,
            //   top: center?.y,
            // })
            // Object.keys(graph.edges[nodeId]).forEach((neighbor) => {
            //   console.log(neighbor, 'moveDraggedPinAndName')
            // })
            // console.log(nodeId,node, 'moveDraggedPinAndName')
            renderTraversiblePaths()

          }
        }
      }
    });

    canvas.current.on("object:added", function (e) {
      if (activeTab == "traversable") {
        handleTraversibleDropDown();
      }
      // console.log(selFloorPlanDtls?.enc_id)

      const newObject = e.target;
      // let floorId = null;
      // setFloorID((prev) => {
      //   floorId = prev;
      //   return prev;
      // });
      let validTypes = [
        "product",
        "location",
        "boundary",
        "amenity",
        "beacon",
        "safety",
        "vertical",
      ];


      if (newObject.name === "boundary") {
        bringFabricObjectsToFrontByName(canvas, "location");
        // console.log(newObject.id)
      }

      if (validTypes.includes(newObject.name)) {
        let nameWithfloodId;

        setStoredObjects((prevStoredObjects) => {
          const updatedStoredObjects = new Map(prevStoredObjects);
          if (newObject.name === "boundary") {
            let obj = findObjectByEnc_id(
              newObject?.id?.split("_")[1],
              "location",
              canvas
            );
            if (obj) {
              nameWithfloodId = `${obj.enc_id}_${obj.fp_id}`;
            } else {
              nameWithfloodId = newObject?.id;
            }
          } else {
            nameWithfloodId = `${newObject.enc_id}_${newObject.fp_id}`;
          }

          // if (nameWithfloodId) {
          if (!updatedStoredObjects.has(nameWithfloodId)) {
            updatedStoredObjects.set(nameWithfloodId, []);
          }


          const objectsArray = updatedStoredObjects.get(nameWithfloodId);

          const storedObjectExists = objectsArray.some((obj) => {
            if (obj?.enc_id === newObject?.enc_id) {
              return true;
            }
            return false;
          });

          if (storedObjectExists) {
            let index;
            if (newObject.name === "boundary") {
              index = updatedStoredObjects
                .get(nameWithfloodId)
                .findIndex((item) => item.id === newObject.id);
            } else if (newObject.types) {
              index = updatedStoredObjects
                .get(nameWithfloodId)
                .findIndex(
                  (item) =>
                    item.enc_id === newObject.enc_id &&
                    item.types === "text_field"
                );
            } else {
              index = updatedStoredObjects
                .get(nameWithfloodId)
                .findIndex(
                  (item) =>
                    item.enc_id === newObject.enc_id &&
                    item.types !== "text_field"
                );
            }

            if (index !== -1) {
              updatedStoredObjects.get(nameWithfloodId).splice(index, 1);
            }

            updatedStoredObjects.get(nameWithfloodId).push(newObject);
            if (newObject.name === "boundary") {
              updatedStoredObjects.delete(newObject.id);
            }

            return updatedStoredObjects;
          } else {
            updatedStoredObjects.get(nameWithfloodId).push(newObject);
            return updatedStoredObjects;
          }
          // } else {
          //   return prevStoredObjects
          // }
        });

        // if (newObject.boundaryGroup) {
        //   let array = storedObjects.get(`${newObject.enc_id}_${newObject.fp_id}`)
        //   let bounderyobject = array.find((item) =>(item.id == `boundary_${newObject.enc_id}`))
        //   cornersVisible.current = bounderyobject
        // }
        // fabricCanvas.setActiveObject(cornersVisible.current);


      }

    });

    canvas.current.on("object:removed", function (e) {
      if (activeTab == "traversable") {
        handleTraversibleDropDown();
      }
    });

    canvas.current.on("mouse:up", function (evt) {
      // console.log(obj, "mouse:up");
      localStorage.removeItem("connectedNodePoint");
      console.log(selObject, "mouse up")

      let selectionUp = canvas.current.getActiveObjects();
      // console.log(selectionUp, 'getActiveObject')
      let panToolVariabl = false;
      setPanTool((prev) => {
        panToolVariabl = prev;
        return prev;
      });

      let toolActive;
      setToolActive((prev) => {
        toolActive = prev;
        return prev;
      });

      // console.log(toolActive, toolActive, 'toolActive-up')
      draggingCanvas = false;
      mouseDown2 = false;
      mouseDownSelect = false;


      if (
        activeTab == "floorDetails" &&
        toolActive === "Select" &&
        (evt?.target?.name === "text" || obj?.name === "text")
      ) {
        console.log(evt?.target?.id, "evt?.target");
        setSelTracingId(obj.id);
        setSelObject(evt?.target);
      } else if (
        activeTab == "floorDetails" &&
        toolActive === "Select" &&
        (evt?.target?.name === "tracing" || obj?.name === "tracing")
      ) {
        setSelTracingId(obj.id);
        setSelObject(evt?.target);
      } else if (
        activeTab == "floorDetails" &&
        toolActive === "Select" &&
        (evt?.target?.name !== "tracing" || evt?.target?.name !== "text") &&
        selectionUp?.length === 1
      ) {
        console.log("selection-up");
        setSelTracingId();
        setSelObject();
      } else if (
        activeTab == "floorDetails" &&
        toolActive === "Rectangle" &&
        polyObj
      ) {
        handleCompleteRectangleShape(evt);
      } else if (
        activeTab == "floorDetails" &&
        toolActive === "Triangle" &&
        polyObj
      ) {
        handleCompleteTriangleShape(evt);
      } else if (
        activeTab == "floorDetails" &&
        toolActive === "Circle" &&
        polyObj
      ) {
        handleCompleteCircleShape(evt);
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        addNewTraversablePath
      ) {
        canvas.current.selection = true;
        var selection = canvas.current.getActiveObject();
        if (!['location', 'product', 'beacon', 'amenity', 'safety', 'vertical']?.includes(selection?.name)) {
          console.log(selection, 'setSelectedPaths')
          if (selection) {
            // Lock the transforms on the selected objects
            selection.lockMovementX = true;
            selection.lockMovementY = true;
            selection.lockRotation = true;
            selection.lockScalingX = true;
            selection.lockScalingY = true;
            selection.hasControls = false;
            // selection.hasBorders = false;
            // selection.selectable=true
            canvas.current.renderAll();
            setSelectedPaths(true);


          } else {
            setSelectedPaths(false);
          }
        }

        const objects = canvas.current.getActiveObjects();
        // console.log(objects, "setSelectedPaths");
        // console.log(evt?.target, "setSelectedPaths");

        if (objects?.length > 0) {
          findPathsFromSelectedNode(objects);
        } else {
          if (["path", "node"].includes(evt?.target?.name)) {
            evt?.target.set({
              hasBorders: false,
              hasControls: false,
            });
            canvas.current.setActiveObject(evt?.target);
            setSelectedPaths(true);
          }
          findPathsFromSelectedNode([evt?.target]);
        }

        let connectedNodePointObj;
        canvas.current?.forEachObject(function (o) {
          if (o["name"] == "node" && o["fill"] == "rgba(255,255,0,0.5)") {
            o.set({ fill: "rgba(0,255,0,0.5)", radius: 7 });
          }
        });
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        addNewTraversablePath &&
        selectionUp
      ) {
        console.log(selectionUp, "selectionUp");
      }
      if (
        activeTab == "floorDetails" &&
        addNewFloor &&
        toolActive === "Select" &&
        selectionUp?.length > 1
      ) {
        // } else if (activeTab == "floorDetails" && toolActive === "Select") {
        canvas.current.selection = true;
        var selection = canvas.current.getActiveObject();
        console.log(selection, "getActiveObject");
        if (
          selection &&
          selection?.name !== "tracing" &&
          selection?.name !== "text"
        ) {
          /* These 2 are the selected tracing block move */
          selection.lockMovementX = true;
          selection.lockMovementY = true;
          selection.lockRotation = true;
          selection.lockScalingX = true;
          selection.lockScalingY = true;
          selection.hasControls = false;
          selection.name = "tracing_group";
          // selection.hasBorders = false;
          canvas.current.renderAll();
          setSelTracingId(true);
          setSelObject(selection);
        }
      }
      // else if (activeTab == "floorDetails" && toolActive !== "Select") {
      //   canvas.current.selection = false;
      // }

      /* Dragged pin move from list */
      // else if (activeTab === "products" && evt?.target.name === 'product'
      // ) {
      //   console.log(evt?.target, 'evt?.target')
      //   let obj = evt?.target;
      //   obj.set({
      //     selectable: true,
      //     lockRotation: true,
      //     lockScalingX: true,
      //     lockScalingY: true,
      //     hasControls: false,
      //     hasBorders: false,
      //   })
      // }
    });

    canvas.current.on("mouse:move", function (evt) {
      let toolActive;

      setToolActive((prev) => {
        toolActive = prev;
        return prev;
      });
      // console.log(toolActive, 'toolActive')

      let mouse = canvas.current.getPointer(evt.e);
      let panToolVariabl = false;
      setPanTool((prev) => {
        panToolVariabl = prev;
        return prev;
      });

      let selectedObjects = []
      setSelectedObjects((prev) => {
        selectedObjects = prev
        return prev
      })
      if (mouseDownSelect && selectedObjects.length > 0) {
        let currentPointer = canvas.current.getPointer(evt.e);

        // Calculate the difference in mouse movement
        const deltaX = currentPointer.x - mouseDownSelect.x;
        const deltaY = currentPointer.y - mouseDownSelect.y;

        selectedObjects.map((node) => {
          // console.log(node.left,node.top,node.id,"dfmdksfdsfs")
          let mousepoint = {
            x: node.left + deltaX,
            y: node.top + deltaY
          }
          const zoom = canvas.current.getZoom();
          const viewportTransform = canvas.current.viewportTransform;

          const adjustedPosition = {
            x: (mousepoint.x * zoom) + viewportTransform[4],
            y: (mousepoint.y * zoom) + viewportTransform[5]
          };

          dragNodeOnMainPath(
            node,
            mousepoint,
            graph,
            canvas,
            setSelTraversibleDetails,
            renderTraversiblePaths,
            evt,
            false
          )
          // console.log(node,mouse,evt,"node and mouse")
        })
        // mouseDownSelect = currentPointer
      }

      // console.log(panToolVariabl, 'panToolVariabl')
      handleCursor(activeTab, toolActive, canvas, panToolVariabl);

      if (evt.e.buttons === 4 || (panToolVariabl && mouseDown2)) {
        viewportTransform = canvas.current.viewportTransform;
        controllPan(evt, canvas);
        // ------------------------- navigation optimisation -------------------------
        renderTraversiblePaths();

        // ------------------------- navigation optimisation -------------------------

        // ------------------------- canvas clustering code -------------------------
        // if (canvas.current) {
        //   clusturGroup(canvas.current)
        // }
        if (!addNewFloor && !addNewTraversablePath) {
          setMousetrigger((prev) => {
            if (prev === 0) {
              prev = prev + 1
            } else {
              prev = prev - 1
            }
            return prev
          })
        }
        // ------------------------- canvas clustering code over -------------------------
      } else if (mouseDown) {
        console.log(mouseDown, "mouseDown")
        if (activeTab === "floorDetails" && toolActive === "Draw") {
          if (evt.e.shiftKey) {
            let type = getVerticalOrHorizontalMove(prevMouseClick, {
              x: mouse.x,
              y: mouse.y,
            });
            if (type == "vertical") {
              polyline.points[lastPt - 1] = {
                x: prevMouseClick?.x,
                y: mouse.y,
              };
            } else {
              polyline.points[lastPt - 1] = {
                x: mouse?.x,
                y: prevMouseClick.y,
              };
            }
          } else {
            polyline.points[lastPt - 1] = { x: mouse?.x, y: mouse?.y };
            polyline.dirty = true;
            // polyline.setBoundingBox(true);
            canvas.current.requestRenderAll();
          }
        } else {
          polyline.points[lastPt - 1] = { x: mouse?.x, y: mouse?.y };
          // polyline.setBoundingBox(true);
        }

        if (activeTab == "floorDetails" && toolActive === "Draw") {
          /* showing length of polyline */
          removeFabricObjectsByName(canvas, "length_text_temp");
          showLineLength("length_text_temp", `url(${Pencil}) 1 17, auto`);
          tracingLengthZoomLevel(canvas, canvas.current.getZoom(), mouse);
        } else if (activeTab == "floorDetails") {
          stopPathDrawing();
        }
        canvas.current.renderAll();
      } else if (evt.e.buttons === 0) {
        canvas.current.defaultCursor = "default";
        canvas.current.forEachObject(function (obj) {
          if (obj.name === "backgroundRect") {
            obj.set("defaultCursor", "default");
          }
        });
        canvas.current.renderAll();
      }
      if (activeTab === "traversable" && drawingLine) {
        drawingLine.set({
          x2: mouse.x,
          y2: mouse.y,
        });

        canvas.current.renderAll();
      } else if (
        activeTab === "traversable" &&
        (toolTraversible === "Draw" || toolTraversible === "sub_path")
      ) {
        handleCursor(activeTab, toolTraversible, canvas);
      } else if (activeTab === "traversable" && toolTraversible === "Select") {
        // ---------------make main path point visible  ----------------------
        // let pathLineNodes = filterObject(graph.edges)
        // Object.keys(pathLineNodes)?.map((item) => {
        //   let pathNode = findObjectById(item, canvas)
        //   canvas.current.bringToFront(pathNode)
        // })
        // ---------------make main path point visible  ----------------------

        handleCursor(activeTab, toolTraversible, canvas);
        const activeNode = canvas.current.getActiveObject();
        if (
          activeNode &&
          activeNode?.name == "node" &&
          activeNode?.id == evt?.target?.id &&
          evt?.transform?.action == "drag"
        ) {
          // console.log(evt, "node is dragging");
          // dragNodeAndItsPath(
          //   activeNode,
          //   mouse,
          //   graph,
          //   canvas,
          //   setSelTraversibleDetails,
          //   renderTraversiblePaths
          // );
          dragNodeOnMainPath(
            activeNode,
            mouse,
            graph,
            canvas,
            setSelTraversibleDetails,
            renderTraversiblePaths,
            evt
          )

          checkPinConnection()
        } else if (activeNode &&
          activeNode?.name == "path" &&
          activeNode?.id == evt?.target?.id &&
          evt?.transform?.action == "drag"
        ) {
          // console.log(evt?.target, 'evt?.target')
          // console.log(graph)
          // dragPathAndItsNodes(
          //   activeNode,
          //   mouse,
          //   graph,
          //   canvas,
          //   setSelTraversibleDetails,
          //   renderTraversiblePaths
          // )
        }
      } else if (activeTab === "traversable" && toolTraversible === "Erase") {
        // handleCursor(activeTab, toolTraversible, canvas);
        HoverCursorChanger(canvas, `url(${Eraser}) 1 4, auto`, "path");
        HoverCursorChanger(canvas, `url(${Eraser}) 1 8, auto`, "node");
      } else if (mouseDownShape && toolActive === "Rectangle") {
        handleCreateRectangleShape(canvas, evt);
      } else if (mouseDownShape && toolActive === "Triangle") {
        handleCreateTriangleShape(canvas, evt);
      } else if (mouseDownShape && toolActive === "Circle") {
        handleCreateCircleShape(canvas, evt);
      }
    });

    canvas.current.on("mouse:dblclick", function (e) {
      // console.log(e, activeTab, "double");
      if (activeTab === "floorDetails") {
        pts?.splice(pts.length - 1, 1);
        completeTracingShape();
      } else if (
        activeTab === "locations" &&
        !addNewLocation &&
        !isCommonSidebarVisible &&
        e?.target?.name === "location"
      ) {
        console.log(e?.target);
        onEditLocation(e?.target);
      } else if (
        activeTab === "products" &&
        !addNewProduct &&
        !isCommonSidebarVisible &&
        e?.target?.name === "product"
      ) {
        console.log(e?.target);

        onEditProduct(e?.target);
      } else if (
        activeTab === "beacons" &&
        !addNewQrCodeBeacon &&
        !isCommonSidebarVisible &&
        e?.target?.name === "beacon"
      ) {
        console.log(e?.target);
        setPrefilledMessage();
        onEditBeacon(e?.target);
      } else if (
        activeTab === "amenitys" &&
        !addNewAmenity &&
        !isCommonSidebarVisible &&
        e?.target?.name === "amenity"
      ) {
        console.log(e?.target);

        onEditAmenity(e?.target);
      } else if (
        activeTab === "safety" &&
        !addNewSafety &&
        !isCommonSidebarVisible &&
        e?.target?.name === "safety"
      ) {
        console.log(e?.target);

        onEditSafety(e?.target);
      } else if (activeTab === "traversable") {
        canvas.current.discardActiveObject();
        // stopPathDrawing();
        // undoTraversablePath(graph.getPositions(), graph.getEdges())
      }
    });

    canvas.current.on("selection:created", function (e) {
      obj = canvas.current.getActiveObject();
      let panToolVariabl = false;
      setPanTool((prev) => {
        panToolVariabl = prev;
        return prev;
      });

      let toolActive;
      setToolActive((prev) => {
        toolActive = prev;
        return prev;
      });

      if (objPinNames?.includes(obj?.name) && obj?.name === "tracing") {
        obj.set({ hasControls: false, hasBorders: false });
        canvas?.current?.requestRenderAll();
      }
      if (activeTab == "floorDetails" && toolActive == "Select") {
        if (
          obj.name == "tracing" &&
          obj.type === "polygon" &&
          !panToolVariabl
        ) {
          setTracingIntialValue({
            fill_color: e.selected[0].fill,
            border_color: e.selected[0].stroke,
            border_thick: e.selected[0].strokeWidth,
          });
          setSelTracingId(obj.id);
          showCornerPoints(obj);
          removeFabricObjectsByName(canvas, "tracing_obj_length");
          const points = getPolygonVertices(obj);
          showObjLength(obj, points, canvas);
          tracingLengthZoomLevel(canvas, canvas.current.getZoom());
        }
      } else if (activeTab == "locations" && !panToolVariabl) {
        canvas.current.forEachObject(function (obj1) {
          if (
            (obj1.name == "temp_loc" || obj1.id == obj?.enc_id) &&
            obj1.type == "group"
          ) {
            // console.log(obj1.name,'obj1.name')
            canvas.current.bringToFront(obj1);
          }
        });
        if (obj.name == "boundary") {
          console.log("showcorners");
          showCornerPoints(obj);
          cornersVisible.current = obj
          // console.log("sdkjfbjskdbfkjsdbfjksdbfjksdbf")
        } else {
          cornersVisible.current = false
        }
      } else if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        addNewTraversablePath
      ) {
        // const objects = canvas.current.getActiveObjects();
        // const newColor = "black";
        // console.log(objects, "setSelectedPaths");
        // if (objects) {
        //   // findPathsFromSelectedNode(objects)
        // }

        const objects = e.selected.filter(items => items.name === "node" && !items.id.includes("_"));
        let currentObjects = objects.map((obj) => {
          let currentPossition = graph.positions[obj.id]
          return {
            ...obj,
            left: currentPossition.x,
            top: currentPossition.y
          }
        })
        setSelectedObjects(currentObjects)
      }
    });


    canvas.current.on("selection:cleared", function () {
      // console.log("selection:cleared");
      obj = "";
      setSelTracingId();
      removeFabricObjectsByName(canvas, "tracing_obj_length");
      // console.log("selection:cleared");
      if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        addNewTraversablePath
      ) {
        let objs = canvas.current.getObjects();
        const newColor = "black";
        highLightSelectedPaths(canvas, [], newColor);
      }
      canvas.current.discardActiveObject();
      canvas.current.renderAll();
    });

    canvas.current.on("mouse:wheel", function (options) {
      handleMouseWheel(options, canvas);
      viewportTransform = canvas.current.viewportTransform;
      options.e.preventDefault();
      options.e.stopPropagation();
      // updatePolylinePointsOnZoom();

      // ------------------------- navigation optimisation -------------------------
      // console.log(mouseDown)        
      // renderTraversiblePaths();
      // if (!mouseDown) {
      // console.log(graph.nodes,"graph")
      renderTraversiblePaths(undefined, false, mouseDown)
      // }


      // ------------------------- navigation optimisation -------------------------

      // ------------------------- canvas clustering code -------------------------
      if (!addNewFloor && !addNewTraversablePath) {
        console.log(addNewTraversablePath, addNewFloor, 'addNewTraversablePath')
        setMousetrigger((prev) => {
          if (prev === 0) {
            prev = prev + 1
          } else {
            prev = prev - 1
          }
          return prev
        })
      }
      // ------------------------- canvas clustering code over -------------------------
    });



    if (canvas?.current) {
      renderTracings();
      renderTracingCircles();
      renderTexts();
      renderLocations();
      // console.log(products, 'renderProducts')
      renderProducts();
      renderBeacons();
      renderAmenitys();
      renderSafeties();
      renderVerticalTransport();
      renderTraversiblePaths();

      // if (addNewFloor && activeTab == "floorDetails") {
      //   canvasBackgroundImageHandler(selFloorPlanDtls?.plan);
      // } else {
      //   canvasBackgroundImageHandler(null);
      // }

      if (selFloorPlanDtls?.show_image == 1 && selFloorPlanDtls?.plan) {
        canvasBackgroundImageHandler(selFloorPlanDtls?.plan);
      } else {
        canvasBackgroundImageHandler(null);
      }

    }

    // destroy fabric on unmount
    return () => {
      canvas?.current?.dispose();
      canvas.current = null;
    };
  }, [
    addNewFloor,
    addNewLocation,
    addNewProduct,
    // toolActive,
    addNewQrCodeBeacon,
    addNewAmenity,
    addNewSafety,
    addNewTraversablePath,
    addNewVertical,
    verticalFloorId,
    activeTab,
    toolTraversible,
    isCommonSidebarVisible,
  ]);

  // Function to update polyline points on zoom
  function updatePolylinePointsOnZoom() {
    polyline.dirty = true;
    canvas.current.requestRenderAll();
  }

  const moveDraggedPinAndName = (canvas, newObj, type) => {
    let storedObjects;
    setStoredObjects((prev) => {
      storedObjects = prev;
      return prev;
    });
    const textObj = findPinNameGroup(canvas, newObj?.enc_id, type);
    textObj?.set({
      left: newObj?.left + 10,
      top: newObj?.top - 12,
      originX: "center",
      originY: "center",
    });

    // const selectedKey = `${newObj?.enc_id}_${newObj?.fp_id}`
    // let values = storedObjects?.get(selectedKey);
    // console.log(values,"sfdsadsadas sdfsdfsdfsdfsd"  )
    // if (values) {
    // values.length = 0; // Clear the array while retaining the key
    // storedObjects.get(selectedKey).push(values)
    // }
    // console.log(values,"sfdsadsadas")

    // console.log(values, 'selectedObject')
    // if (values) {
    // values = values.filter(item => item.enc_id !== newObj.enc_id);
    // Update the Map with the filtered array
    // }
    // console.log(values,storedObjects.get(selectedKey),selectedKey,"sdfjdshfjksdfjsd qawswsaqaqawsqaws")
  };

  const checkConditionDrag = () => {
    const condition = activeTab === 'traversable'
      // ? toolTraversible === 'Drag_pin'
      ? toolTraversible === 'Select'
      : (
        !addNewFloor &&
        !addNewLocation &&
        !addNewProduct &&
        !addNewQrCodeBeacon &&
        !addNewAmenity &&
        !addNewSafety &&
        !addNewVertical &&
        !panTool
      );

    // console.log(condition, 'condition')
    return condition;
  };

  useEffect(() => {
    if (canvas.current) {
      // ------------------------- canvas clustering code -------------------------
      if (
        !["floorDetails", "traversable"].includes(activeTab) ||
        (activeTab === "traversable" && isCommonSidebarVisible) ||
        (activeTab === "floorDetails" && !addNewFloor)
      ) {
        // clusturGroup(canvas.current, selFloorPlanDtls?.enc_id);
      }
      // ------------------------- canvas clustering code over -------------------------

      if (activeTab === "traversable" && !isCommonSidebarVisible) {
        updatePinsInNavigation(canvas);
      }
    }
  }, [storedObjects, selFloorPlanDtls, mouseTrigger]);

  const updatePinsInNavigation = (canvas) => {
    const pinsToKeep = new Set();
    const combinedArray = Array.from(storedObjects?.values()).flat();
    let viewport = updateVisibleArea(canvas.current);
    combinedArray.forEach((object) => {
      const validTypes = [
        "product",
        "location",
        "boundary",
        "amenity",
        "beacon",
        "safety",
        "vertical",
      ];
      if (
        validTypes.includes(object.name) &&
        object.type === "group" &&
        object.fp_id === floorID
      ) {
        const boundingRect = object.getBoundingRect(true);
        const isInViewport = isObjectInViewport(boundingRect, viewport);
        // if (showAllPins) {
        //   if (!visibleObjects.current.has(object)) {
        //     canvas.current.add(object);
        //     visibleObjects.current.add(object);
        //   }
        //   pinsToKeep.add(object);
        // } else
        if (isInViewport) {
          if (!visibleObjects.current.has(object)) {
            canvas.current.add(object);
            visibleObjects.current.add(object);
          }
          pinsToKeep.add(object);
        } else {
          canvas.current.remove(object);
        }
      }
    });
    // console.log(pinsToKeep,"pin to keep")
    visibleObjects.current.forEach((obj) => {
      if (!pinsToKeep.has(obj)) {
        canvas.current.remove(obj);
        visibleObjects.current.delete(obj);
      }
    });

    // canvas.current.discardActiveObject();
    canvas.current.renderAll();
  };
  // console.log("hiiiii")

  const filterObject = (inputObj) => {
    // Create a new object to store the filtered results
    let filteredObj = {};

    // Iterate through each key in the input object
    for (const [key, value] of Object.entries(inputObj)) {
      // If the key doesn't start with "location" and none of the nested keys start with "location"
      if (!key.includes('_') && !Object.keys(value).some(k => k.includes('_'))) {
        filteredObj[key] = value; // Add it to the filtered object
      }
    }

    return filteredObj;
  };

  const findPathsFromSelectedNode = (objects) => {
    const nodes = objects.filter(obj => obj?.name === 'node');
    if (nodes?.length > 0) {
      let edges = graph.getEdges();
      let pathArray = [];
      nodes.forEach((node) => {
        let id = node.id;
        if (edges[id]) {
          Object.keys(edges[id]).forEach((key) => {
            let path1 = findObjectById(`path$${key}$${id}`, canvas);
            let path2 = findObjectById(`path$${id}$${key}`, canvas);
            if (path1) {
              pathArray.push(path1);
            }
            if (path2) {
              pathArray.push(path2);
            }
          });
        }
      });
      const newColor = "#e78fbc";
      highLightSelectedPaths(canvas, pathArray, newColor);
    } else {
      const newColor = "#f595c4";
      highLightSelectedPaths(canvas, objects, newColor);
    }
  };

  useEffect(() => {
    /* For screen break issue onload */
    if (activeTab === "settings" && canvas?.current) {
      canvas?.current?.setHeight(canvasContainerRef.current.clientHeight);
      canvas?.current?.setWidth(canvasContainerRef.current.clientWidth);
      canvas?.current?.requestRenderAll();
    }
  }, [activeTab, canvas?.current]);

  const storeOriginalProperties = () => {
    canvas.current.forEachObject((obj) => {
      if (!obj.originalScaleX) {
        obj.originalScaleX = obj.scaleX;
        obj.originalScaleY = obj.scaleY;
        obj.originalLeft = obj.left;
        obj.originalTop = obj.top;
      }
    });
  };

  const resizeAndScaleCanvas = (canvasObj) => {
    const viewportWidth =
      selFloorPlanDtls?.width ?? canvasContainerRef.current.clientWidth;
    const viewportHeight =
      selFloorPlanDtls?.height ?? canvasContainerRef.current.clientHeight;
    const originalCanvasWidth = selFloorPlanDtls?.width || viewportWidth;
    const originalCanvasHeight = selFloorPlanDtls?.height || viewportHeight;

    const scaleRatio = Math.min(
      viewportWidth / originalCanvasWidth,
      viewportHeight / originalCanvasHeight
    );
    const bgImg = canvas.current.backgroundImage;

    if (bgImg) {
      bgImg.scaleX = bgImg.scaleY = scaleRatio;
      bgImg.set({
        originX: "center",
        originY: "center",
        left: viewportWidth / 2,
        top: viewportHeight / 2,
      });

      const deltaX = (viewportWidth - originalCanvasWidth * scaleRatio) / 2;
      const deltaY = (viewportHeight - originalCanvasHeight * scaleRatio) / 2;
      canvas.current.forEachObject((obj) => {
        obj.set({
          scaleX: obj.originalScaleX * scaleRatio,
          scaleY: obj.originalScaleY * scaleRatio,
          left: obj.originalLeft * scaleRatio + deltaX,
          top: obj.originalTop * scaleRatio + deltaY,
        });
        obj.setCoords();
      });
    } else {
      canvas.current.forEachObject((obj) => {
        obj.set({
          scaleX: obj.originalScaleX * scaleRatio,
          scaleY: obj.originalScaleY * scaleRatio,
          left: obj.originalLeft * scaleRatio,
          top: obj.originalTop * scaleRatio,
        });
        obj.setCoords();
      });
    }
    canvas.current.renderAll();
  };

  useEffect(() => {
    if (windowWidth && canvas?.current) {
      const canvasWidth = canvasContainerRef.current.clientWidth;
      const canvasHeight = canvasContainerRef.current.clientHeight;
      canvas.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
      canvas.current.requestRenderAll();
      storeOriginalProperties();
      canvas.current.requestRenderAll();
      const pageDiv = document.querySelector(".pageDiv");
      if (pageDiv) {
        pageDiv.style.height = "100%";
      }
    }
  }, [windowWidth, selFloorPlanDtls]);

  useEffect(() => {
    const handleResizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    // handling key presses
    document.removeEventListener("keydown", keyDownHandler);
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [traversibleHistory, toolTraversible]);

  function resetCanvasTransform() {
    viewportTransform = undefined;
    // canvas.current.viewportTransform = [1, 0, 0, 0, 1, 0]
    canvas?.current?.absolutePan({ x: 0, y: 0 });
    canvas?.current?.setZoom(1);
    canvas?.current.renderAll();
  }

  const handleKeyBoardPress = (event) => {
    if (event.key === "Escape") {
      if (activeTab === "traversable") {
        removeEmptyNode(canvas, graph, activeTab, isCommonSidebarVisible);
        stopPathDrawing();
        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
          post: true,
        }));
      }
    } else if (event.keyCode === 46) {
      deleteTracingsByDeleteKey();
    }
    // else if (event?.e?.shiftKey) {
    //     const activeObjects = canvas.current.getActiveObjects();
    //     if (!activeObjects.includes(event.target)) {
    //       canvas.current.discardActiveObject();
    //       const selection = new fabric.ActiveSelection(activeObjects.concat(event.target), {
    //         canvas: canvas.current
    //       });
    //       canvas.current.setActiveObject(selection);
    //       canvas.current.renderAll();
    //     }
    //   } else {
    //     getSelection(event);
    //   }
    // }
    // if (event.ctrlKey && event.key === 'z') {
    //   undoTraversablePath(graph.getPositions(), graph.getEdges())
    // }
  };

  const keyDownHandler = (event) => {
    if (addNewFloor && activeTab == "floorDetails" && event.key === "Escape") {
      event.preventDefault();
    }
    if (event.ctrlKey && event.key === "z") {
      // Undo operation
      if (activeTab == "traversable") {
        undoTraversable();
      }
    } else if (event.ctrlKey && event.key === "y") {
      // Redo operation
      console.log("Redoing...");
      // redoOperation(positions, edges, redoStack);
    } else if (event.keyCode === 46) {
      // console.log(activeTab, toolActive, addNewFloor, 'delete-tracing')
      if (
        activeTab === "traversable" &&
        toolTraversible == "Select" &&
        addNewTraversablePath
      ) {
        deleteSelectedObjects();
      }
      // deleteTracingsByDeleteKey()
    }
    if (activeTab == "floorDetails") {
      if (event.key == "Enter") {
        isEnterKey = true;
      } else {
        isEnterKey = false;
      }
    }
  };

  const onScrollBarMove = (delta) => {
    canvas.current.relativePan(delta);
  };

  const deleteSelectedObjects = () => {
    console.log(graph, "graphs")
    deleteObjects(
      canvas,
      graph,
      setSelTraversibleDetails,
      setToolTraversible,
      setSelectedPaths,
      checkPinConnectOrNot, true,
      deleteSubPath
    );
  };

  const showLineLength = (objName, cursor) => {

    let toolActive;
    setToolActive((prev) => {
      toolActive = prev;
      return prev;
    });


    /* showing length of polyline */
    let length = 0;
    let center = {};
    for (let i = 1; i < pts.length; i++) {
      const point1 = pts[i - 1];
      const point2 = pts[i];
      const distance = calculateDistance(point1, point2);
      center = {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2,
      };
      length = ((distance * 0.5) / 100) * 10;
    }

    const angle = Math.atan2(
      pts[pts.length - 1]?.y - pts[0]?.y,
      pts[pts.length - 1]?.x - pts[0]?.x
    );
    const textLeft = center.x - (length / 2) * Math.cos(angle);
    const textTop = center.y - (length / 2) * Math.sin(angle);

    const text = new fabric.Text(`${length.toFixed(2)} m`, {
      left: textLeft,
      top: textTop,
      selectable: false,
      // hoverCursor: "auto",
      hoverCursor: toolActive === "Draw" ? `url(${Pencil}) 1 17, auto` : "auto",
      fontSize: 12,
      fontFamily: "Arial",
      fill: "black",
      name: objName,
      originX: "center",
      originY: "center",
      // backgroundColor: "#fbfbfb",
      fontWeight: 700,
    });
    if (cursor) {
      text.hoverCursor = cursor;
    }
    const textOverlay = new fabric.Text(`${length.toFixed(2)} m`, {
      left: textLeft,
      top: textTop,
      selectable: false,
      // hoverCursor: "auto",
      hoverCursor: toolActive === "Draw" ? `url(${Pencil}) 1 17, auto` : "auto",
      fontSize: 12,
      fontFamily: "Arial",
      fill: "black",
      name: objName,
      originX: "center",
      originY: "center",
      // backgroundColor: "#fbfbfb",
      fontWeight: 700,
      // angle: angle * (180 / Math.PI) + 10,
      stroke: "white",
      strokeWidth: 3,
    });
    if (cursor) {
      textOverlay.hoverCursor = cursor;
    }
    canvas.current?.add(textOverlay);
    canvas.current?.add(text);
    canvas.current.bringToFront(text);
  };

  const undoTraversable = () => {
    console.log(traversibleHistory, "traversibleHistory");
    console.log(lastTraversibleUndoIndex, "lastTraversibleUndoIndex");
    if (lastPt > 1) {
      stopPathDrawing();
      return;
    }

    if (traversibleHistory.length) {
      if (lastTraversibleUndoIndex == undefined) {
        lastTraversibleUndoIndex = traversibleHistory.length - 1;
      }
      let indexData = traversibleHistory[lastTraversibleUndoIndex];
      console.log(indexData, "indexData");
      if (indexData?.action == "create") {
        if (indexData?.type == "nodeAndLine") {
          removeNode(indexData.data.nodeName, true, graph, canvas);
          if (indexData.data.key1 && indexData.data.key2) {
            removeLine(
              `path$${indexData.data.key1}$${indexData.data.key2}`,
              graph,
              canvas
            );
          }
        } else if (indexData?.type == "line") {
          if (indexData.data.key1 && indexData.data.key2) {
            removeLine(
              `path$${indexData.data.key1}$${indexData.data.key2}`,
              graph,
              canvas
            );
          }
        }
        lastTraversibleUndoIndex--;
      } else if (indexData?.action == "delete") {
        if (indexData?.type == "nodeAndLine") {
          onCreateNode(indexData.data.nodePosition, indexData.data.nodeName);
          // if (indexData.data.key1 && indexData.data.key2) {
          //   removeLine(`path$${indexData.data.key1}$${indexData.data.key2}`);
          // }
        }
      }
      setSelTraversibleDetails((prev) => ({
        ...prev,
        edges_data: graph.getEdges(),
        points_data: graph.getPositions(),
        post: true,
      }));
    }
  };

  const undoTraversablePath = (positions, edges) => {
    const redoStack = []; // To store undone operations

    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "z") {
        // Undo operation
        console.log("Undoing...");
        undoOperation(positions, edges);
      } else if (event.ctrlKey && event.key === "y") {
        // Redo operation
        console.log("Redoing...");
        redoOperation(positions, edges, redoStack);
      }
    });

    const undoOperation = (positions, edges) => {
      const redoPositions = positions;
      const redoEdges = edges;

      console.log(positions, edges, "first");
      if (positions) {
        const keys = Object.keys(positions);
        const lastKey = keys[keys.length - 1];
        const lastObject = positions[lastKey];
        console.log(lastKey, "lastKey");
        removeFabricObjectsBId(canvas, lastKey);
        delete positions[lastKey];
        graph.restorePositions(positions);
        redoStack.push({ type: "positions", key: lastKey, data: lastObject });
      }

      if (edges) {
        const keys = Object.keys(edges);
        const lastKey = keys[keys.length - 1];
        const lastObject = edges[lastKey];
        console.log(lastKey, "edges");
        if (edges[lastKey]) {
          Object.keys(edges[lastKey]).forEach((key) => {
            delete edges[key][lastKey];
            removeFabricObjectsBId(canvas, `path$${key}$${lastKey}`);
            removeFabricObjectsBId(canvas, `path$${lastKey}$${key}`);
          });
          delete edges[lastKey];
          graph.restoreEdges(edges);
          redoStack.push({ type: "edges", key: lastKey, data: lastObject });
        }
      }

      setSelTraversibleDetails((prev) => ({
        ...prev,
        edges_data: graph.getEdges(),
        points_data: graph.getPositions(),
      }));
    };

    const redoOperation = (positions, edges, redoStack) => {
      const redoItem = redoStack.pop();
      console.log(redoItem);

      if (redoItem) {
        const { type, key, data } = redoItem;

        if (type === "positions") {
          // Redo positions
          positions[key] = data;
          // graph.restorePositions(positions);
          onCreateNode(data, key);
          canvas?.current.renderAll();
        } else if (type === "edges") {
          // Redo edges
          edges[key] = data;
          // addConnectionBtwnEdges()
          // graph.restoreEdges(edges);
        }
        setSelTraversibleDetails((prev) => ({
          ...prev,
          edges_data: graph.getEdges(),
          points_data: graph.getPositions(),
        }));
      }
    };
  };

  const handleTraversibleDropDown = () => {
    const notIncludeObjs = [
      "tracing",
      "path",
      "boundary",
      "node",
      "line_starter_poly",
      "short_path",
      "text",
    ];
    let tmpData = [];
  };

  const stopPathDrawing = () => {
    poly = false;
    polyBtn = "";
    lastPt = 1;
    mouseDown = false;
    pts = [];
    key1 = null;
    key2 = null;
    drawingLine = undefined;
    //------------------- restric subpath drawing -------------------
    nodeNameArray = [];
    //------------------- restric subpath drawing -------------------
    removeFabricObjectsByName(canvas, "line_starter_poly");
    obj = "";
    /* Remove tracing length */
    removeFabricObjectsByName(canvas, "length_text_temp");
    removeFabricObjectsByName(canvas, "length_text");
    removeFabricObjectsByName(canvas, "tracing_obj_length");

    setTracingIntialValue(projectSettings);
    canvas.current?.discardActiveObject();
    canvas.current?.renderAll();
  };

  const completeTracingShape = () => {
    let projectData;
    setProjectSettings((prev) => {
      projectData = prev;
      return prev;
    });

    let strokeColor =
      projectData?.border_color ?? selFloorPlanDtls?.border_color;
    let strokeWidth =
      projectData?.border_thick ?? selFloorPlanDtls?.border_thick;
    let fillColor = projectData?.fill_color ?? selFloorPlanDtls?.fill_color;

    removeFabricObjectsByName(canvas, "corner_point"); // to remove corners at polyline
    removeFabricObjectsByName(canvas, "length_text");
    removeFabricObjectsByName(canvas, "length_text_temp");
    pts?.map((ele, index) => {
      if (
        pts[index]?.x == pts[index - 1]?.x &&
        pts[index]?.y == pts[index - 1]?.y
      ) {
        pts?.splice(index, 1);
        console.log("remove");
      }
    });
    let polyObj = new fabric.Polygon(pts, {
      objectCaching: false,
      id: new Date().toString(),
      fill: fillColor,
      stroke: strokeColor,
      strokeWidth: Number(0),
      originX: "center",
      originY: "center",
      selectable: false,
      name: "tracing",
      position: "absolute",
      zIndex: 2000,
      perPixelTargetFind: true,
      // hoverCursor: "default",
      hoverCursor: `url(${Pencil}) 1 17, auto`,
      evented: true,
      strokeLineJoin: "bevil",
      // perPixelTargetFind: false,
    });
    polyObj.set("strokeWidth", Number(strokeWidth));
    polyObj.setCoords();
    // setSelTracingId()
    canvas.current.forEachObject(function (obj) {
      if (obj.name == "temp") {
        canvas.current.remove(obj);
      }
    });
    setTracings((prev) => [...prev, { vertices: pts }]);

    canvas?.current?.add(polyObj);
    canvas?.current.bringToFront(polyObj);
    sendToBackObjects(canvas, "backgroundRect");

    updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
    // changeSelectionAllObjs(false, "tracing");
    poly = false;
    polyBtn = "";
    lastPt = 1;
    mouseDown = false;
    pts = [];
    prevMouseClick = undefined;
  };

  const showCornerPoints = (obj) => {
    if (obj) {
      // console.log(obj.name,"obj")
      obj.transparentCorners = false;
      var lastControl = obj?.points?.length - 1;
      obj.cornerStyle = "circle";
      obj.cornerColor = "rgba(0,0,255,0.5)";
      obj.cornerSize = 8;
      obj.controls = obj.points.reduce(function (acc, point, index) {
        acc["p" + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(
            index > 0 ? index - 1 : lastControl,
            actionHandler
          ),
          actionName: "modifyPolygon",
          pointIndex: index,
        });
        return acc;
      }, {});
    } else {
      cornersVisible.current = false
      console.log("obj is not present")
    }
  };

  useEffect(() => {
    // to change boundary color on selecting new color
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    if (canvas?.current && addNewLocation) {
      // canvas?.current?.forEachObject((ob) => {
      combinedArray?.forEach((ob) => {
        if (ob?.name == "boundary" && !ob?.id) {
          const propertiesToUpdate = {
            ...ob,
            fill: selLocationDtls.boundary_color
              ? hexToRgb(selLocationDtls.boundary_color, 0.4 ?? "#26A3DB")
              : "",
          };
          ob.set(propertiesToUpdate);
        } else if (
          ob?.id === selLocationDtls?.enc_id &&
          ob.name !== "backgroundRect"
        ) {
          ob.set(
            "fill",
            selLocationDtls.boundary_color
              ? hexToRgb(selLocationDtls.boundary_color, 0.4 ?? "#26A3DB")
              : ""
          );
        }
        if (selLocationDtls?.location_color && ob?.name === "temp_loc") {
          removeFabricObjectsByName(canvas, "temp_loc");
          let fillColor =
            selLocationDtls?.location_color ??
            projectSettings?.location_color ??
            "red";

          let locationIcon = getLocationPin(fillColor);
          let path = fabric.loadSVGFromString(
            locationIcon,
            function (objects, options) {
              let obj = fabric.util.groupSVGElements(objects, options);
              obj.set({
                left: selLocationDtls?.position?.x - obj.width / 2,
                top: selLocationDtls?.position?.y - obj.height / 2,
                name: "temp_loc",
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                hoverCursor: "grab",
                hasControls: false,
                hasBorders: false,
              });
              canvas.current.add(obj);
            }
          );
        }
        if (ob?.name === "temp_loc" && selLocationDtls?.boundary_color) {
          ob.set("isBoundary", true);
        }
      });

      if (selLocationDtls?.boundary_color && addNewLocation) {
        const position = selLocationDtls.position;
        let vertices = getSquareCoordinates(position.x, position.y, 50);
        if (selLocationDtls?.boundary_attributes) {
          vertices = selLocationDtls?.boundary_attributes;
        }
        if (boundaryAttributes) {
          vertices = boundaryAttributes;
        } else {
          boundaryAttributes = vertices;
        }
        if (
          !getFabricObject(
            `boundary_${selLocationDtls.enc_id}`,
            "id",
            canvas
          ) &&
          !getFabricObject("temp_boundary", "id", canvas)
        ) {
          let polyObj = new fabric.Polygon(vertices, {
            name: "boundary",
            objectCaching: false,
            id: "temp_boundary",
            fill: hexToRgb(selLocationDtls.boundary_color, 0.4) ?? null,
            stroke: "grey",
            strokeWidth: Number(0),
            // lockMovementX: true, lockMovementY: true,
            originX: "center",
            originY: "center",
            selectable: activeTab === "locations" && addNewLocation,
            hoverCursor: "grab",
          });
          // console.log('boundary clearded')
          canvas.current.add(polyObj).renderAll();
          showCornerPoints(polyObj);

        } else {
          //this means its on edit
          changeSelectionById(
            true,
            `boundary_${selLocationDtls.enc_id}`,
            canvas
          );
          const returObj = findObjectById(
            `boundary_${selLocationDtls.enc_id}`,
            canvas
          );
          // console.log(returObj, "returObj");
          if (returObj) {
            showCornerPoints(returObj);
          } else if (obj.id === "temp_boundary") {
            showCornerPoints(obj);
          }

          changePropertyById(
            hexToRgb(selLocationDtls.boundary_color, 0.4),
            "fill",
            `boundary_${selLocationDtls.enc_id}`,
            canvas
          );

          changePropertyById(
            hexToRgb(selLocationDtls.boundary_color, 0.4),
            "fill",
            "temp_boundary",
            canvas
          );
        }
      } else {
        removeFabricObjectsBId(canvas, "temp_boundary");
        // boundaryAttributes = null   //this is to remove existing boundary vertices data while saving location.
      }
      if (!selLocationDtls || !selLocationDtls.boundary_attributes) {
        boundaryAttributes = undefined;
      }
      if (selLocationDtls && !selLocationDtls.boundary_color) {
        boundaryAttributes = undefined;
        removeFabricObjectsBId(canvas, "temp_boundary");
        removeFabricObjectsBId(canvas, `boundary_${selLocationDtls.enc_id}`);
      }
    }
    if (canvas?.current && selLocationDtls?.location_color && addNewLocation) {
      // canvas?.current?.forEachObject((obj) => {
      combinedArray?.forEach((obj) => {
        if (obj?.enc_id == selLocationDtls?.enc_id) {
          removeFabricObjectsEncId(canvas, obj?.enc_id, "location");
          RemoveObjectFromStoredObjects(obj, "location");
          let fillColor =
            selLocationDtls?.location_color ?? projectSettings?.location_color;
          let locationIcon = getLocationPin(fillColor);
          let square;
          square = new fabric.Rect({
            left: selLocationDtls?.position?.x - 14,
            top: selLocationDtls.position?.y - 15,
            width: 26,
            height: 28,
            fill: "transparent",
            stroke: "red",
            strokeWidth: 2,
          });

          let path = fabric.loadSVGFromString(
            locationIcon,
            function (objects, options) {
              let obj = fabric.util.groupSVGElements(objects, options);

              obj.set({
                left: selLocationDtls?.position?.x - obj.width / 2,
                top: selLocationDtls.position?.y - obj.height / 2,
                selectable: true,
                // obj?.enc_id === selLocationDtls?.enc_id ? true : false,
                name: "location",
                locationEdit: true,
                id: selLocationDtls.location_name,
                enc_id: selLocationDtls?.enc_id,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                hoverCursor: "grab",
                hasControls: false,
                hasBorders: false,
              });
              // console.log(selLocationDtls, 'addNewLocation')
              if (addNewLocation) {
                const group = new fabric.Group([square, obj], {
                  selectable: addNewLocation ? true : false,
                  lockRotation: true,
                  lockScalingX: true,
                  lockScalingY: true,
                  hasControls: false,
                  hasBorders: false,
                  name: "location",
                  id: selLocationDtls.location_name,
                  enc_id: selLocationDtls?.enc_id,
                  fp_id: selLocationDtls?.enc_floor_plan_id,
                  boundaryGroup: true,
                  hoverCursor: "grab",
                });
                canvas.current.add(group).renderAll();
              } else {
                canvas.current.add(obj).renderAll();
              }
              // canvas.current.bringToFront(group);
            }
          );

          // console.log(storedObjects, obj?.enc_id, obj?.fp_id, "storedObjects")
        }
      });
    }
    if (!selLocationDtls) {
      boundaryAttributes = undefined;
    }
  }, [selLocationDtls]);

  useEffect(() => {
    /* Product pin color change dinamically */
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    if (canvas?.current && selProductDtls?.product_color) {
      RemoveObjectFromStoredObjects(selProductDtls)
      updateProductPin(
        canvas,
        selProductDtls,
        projectSettings,
        setCanvasUpdated,
        addNewProduct,
        combinedArray
      );
    }
  }, [selProductDtls]);

  useEffect(() => {
    /* Beacon color change dinamically */
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    if (canvas?.current && selBeaconDtls?.beacon_color) {
      RemoveObjectFromStoredObjects(selBeaconDtls)
      // console.log(selBeaconDtls)
      updateBeaconPin(canvas, selBeaconDtls, projectSettings, setCanvasUpdated, combinedArray);
    }
  }, [selBeaconDtls]);

  const RemoveObjectFromStoredObjects = (obj, name) => {
    // console.log(obj,storedObjects,"objobj")

    let nameWithfloodId;
    if (obj?.name === "location") {
      nameWithfloodId = `${obj?.enc_id}_${obj?.fp_id}`
    } else if (name) {
      nameWithfloodId = `${obj?.enc_id}_${floorID}`
      // console.log(storedObjects,floorID)
    } else {
      nameWithfloodId = `${obj?.enc_id}_${obj?.enc_floor_plan_id}`
    }
    // console.log(nameWithfloodId,"objobj")

    setStoredObjects((prevStoredObjects) => {
      const updatedStoredObjects = new Map(prevStoredObjects);
      updatedStoredObjects.delete(nameWithfloodId);
      return updatedStoredObjects
    });
  }

  useEffect(() => {
    /* Amenity color change dinamically */
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    if (
      canvas?.current &&
      (selAmenityDtls?.amenity_color || selAmenityDtls?.icon_path)
    ) {

      RemoveObjectFromStoredObjects(selAmenityDtls)

      updateAmenityPin(
        canvas,
        selAmenityDtls,
        projectSettings,
        setCanvasUpdated,
        aminityIcons,
        combinedArray
      );
    }
  }, [selAmenityDtls]);

  useEffect(() => {
    /* safety color change dinamically */
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    if (
      canvas?.current &&
      (selSafetyDtls?.safety_color || selSafetyDtls?.icon_path)
    ) {
      RemoveObjectFromStoredObjects(selSafetyDtls)
      updateSafetyPin(
        canvas,
        selSafetyDtls,
        projectSettings,
        setCanvasUpdated,
        safetyIcons,
        combinedArray
      );
    }
  }, [selSafetyDtls]);

  useEffect(() => {
    /* vertical color change dinamically */
    let combinedArray = storedObjects?.values()
      ? Array.from(storedObjects?.values())?.flat()
      : [];
    let selVerticalDtlsVar
    setselVerticalDtls(prev => {
      selVerticalDtlsVar = prev
      return prev
    })
    if (
      canvas?.current &&
      (selVerticalDtls?.vt_color || selVerticalDtls?.icon_path)
    ) {
      console.log(selVerticalDtls, selVerticalDtlsVar, "selVerticalDtlsVar")
      if (selVerticalDtls?.transport_details) {
        RemoveObjectFromStoredObjects(selVerticalDtls?.transport_details[0], "vertical")
      }
      updateVerticalPin(canvas, selVerticalDtls, projectSettings, combinedArray);
    }
  }, [selVerticalDtls]);

  useEffect(() => {
    if (toolTraversible == "Erase") {
      HoverCursorChanger(canvas, "grab", "node");
      HoverCursorChanger(canvas, "grab", "path");
      // canvas.current.selection = true;
      setSelectedPaths(false);
      obj = "";
      changeFabricObjectSelectionByName(canvas, "node", false);
      changeFabricObjectSelectionByName(canvas, "path", false);
    } else if (toolTraversible == "Select") {
      canvas.current.selection = true;
      changeFabricObjectSelectionByName(canvas, "node", true);
      changeFabricObjectSelectionByName(canvas, "path", true);
    } else {
      stopPathDrawing();
      canvas.current.selection = false;
      changeFabricObjectSelectionByName(canvas, "node", false);
      changeFabricObjectSelectionByName(canvas, "path", false);
      setSelectedPaths(false);
      obj = "";
    }
  }, [toolTraversible]);

  useEffect(() => {
    if (addNewFloor) {
      if (toolActive === "Select") {
        canvas.current.selection = true;
      } else {
        canvas.current.selection = false;
      }
    }
  }, [toolActive, addNewFloor])

  const textStyleHandler = (type, value) => {
    const obj = canvas.current.getActiveObject();
    if (obj) {
      console.log(obj, "selected Text");
      if (type == "fontFamily") {
        if (standardFonts.includes(value)) {
          obj.set(type, value);
        } else {
          var myfont = new FontFaceObserver(value);
          myfont
            .load()
            .then(() => {
              obj.set(type, value);
            })
            .catch(function (e) {
              console.log(e, "font failed");
            });
        }
      } else if (type == "fill") {
        obj.set(type, value);
      }
      else if (type == "fontSize") {
        obj.set(type, value);
        console.log(obj, value, "selected Text");

      }
      else if (type == "fontWeight") {
        obj.set(type, value);
        console.log(obj, value, "selected Text");

      }
      else if (type == "textAlign") {
        obj.set(type, value);
        console.log(obj, value, "selected Text");

      }
      canvas.current.renderAll();
      updateText(canvas, setTexts, postTexts);
    }
  };

  /* Product pin edit */
  const onEditProduct = async (row, type) => {
    editProduct(
      row,
      setAddNewProduct,
      setImages,
      setSpecifications,
      setSelProductDtls,
      type
    );
  };

  /* Beacon pin edit */
  const onEditBeacon = async (row) => {
    editBeacon(row, setSelBeaconDtls, setAddNewQrCodeBeacon);
  };

  /* transport pin edit */
  const onEditVerticaltransport = async (row, type) => {
    editVerticaltransport(
      row,
      setVerticalIcons,
      setAddNewVertical,
      setselVerticalDtls,
      handleEnableDisable,
      type
    );
  };

  /* Amenity pin edit */
  const onEditAmenity = async (row) => {
    editAmenity(row, setAddNewAmenity, setSelAmenityDtls);
  };

  /* Safety pin edit */
  const onEditSafety = async (row) => {
    setTimeout(() => {
      editSafety(row, setSafetyIcons, setAddNewSafety, setSelSafetyDtls);
    }, 700);
  };

  const onEditAd = async (row) => {
    editAd(row, setAddNewAd, setSelAd);
  };

  /* Location pin edit */
  const onEditLocation = async (location, type) => {
    editLocation(
      location,
      setAddNewLocation,
      setPromotions,
      setIsBoundary,
      setSelLocationDtls,
      setHours,
      type,
    );
  };

  useEffect(() => {
    if (canvas.current && !selLocationDtls?.enc_id) {
      removeFabricObjectsByName(canvas, "location");
      removeFabricObjectsByName(canvas, "boundary");
      removeFabricObjectsByName(canvas, "temp_loc");
      renderLocations();
      renderTraversiblePaths();
    }
  }, [locations, activeTab]);

  useEffect(() => {
    if (canvas.current && !selProductDtls?.enc_id) {
      removeFabricObjectsByName(canvas, "product");
      removeFabricObjectsByName(canvas, "temp_prod");
      renderProducts();
    }
  }, [products, activeTab]);

  useEffect(() => {
    if (canvas.current && !selBeaconDtls?.enc_id) {
      removeFabricObjectsByName(canvas, "beacon");
      removeFabricObjectsByName(canvas, "temp_beacon");
      renderBeacons();
    }
  }, [beacons, activeTab]);

  useEffect(() => {
    if (canvas.current && !selAmenityDtls?.enc_id) {
      removeFabricObjectsByName(canvas, "amenity");
      removeFabricObjectsByName(canvas, "temp_amenity");
      renderAmenitys();
    }
  }, [amenities, activeTab]);

  useEffect(() => {
    if (canvas.current && !selSafetyDtls?.enc_id) {
      removeFabricObjectsByName(canvas, "safety");
      removeFabricObjectsByName(canvas, "temp_safety");
      renderSafeties();
    }
  }, [safeties, activeTab]);

  useEffect(() => {
    if (canvas.current) {
      removeFabricObjectsByName(canvas, "vertical");
      renderVerticalTransport();
    }
  }, [verticalTransports, activeTab]);

  useEffect(() => {
    if (canvas.current) {
      if (!addNewFloor) {
        // to prevent rerender on tracing modifying.
        removeFabricObjectsByName(canvas, "tracing");
        removeFabricObjectsByName(canvas, "text");
        renderTracings();
        renderTracingCircles()
        renderTexts();

      }
      removeFabricObjectsByName(canvas, "location");
      removeFabricObjectsByName(canvas, "boundary");
      renderLocations();

      removeFabricObjectsByName(canvas, "product");
      renderProducts();

      removeFabricObjectsByName(canvas, "beacon");
      renderBeacons();

      removeFabricObjectsByName(canvas, "amenity");
      renderAmenitys();

      removeFabricObjectsByName(canvas, "safety");
      renderSafeties();

      removeFabricObjectsByName(canvas, "vertical");
      renderVerticalTransport();

      removeFabricObjectsBId(canvas, "short_path");
    }
  }, [tracings, activeTab, texts, tracingCircle]);

  useEffect(() => {
    if (canvas.current) {
      // if (selFloorPlanDtls && !addNewFloor && activeTab == "floorDetails") {
      // canvasBackgroundImageHandler(selFloorPlanDtls?.plan ?? null);
      if (selFloorPlanDtls?.show_image == 1 && selFloorPlanDtls?.plan) {
        canvasBackgroundImageHandler(selFloorPlanDtls?.plan);
      } else {
        canvasBackgroundImageHandler(null);
      }
      // }

      // if (!selFloorPlanDtls?.plan) {
      if (addNewFloor) {
        removeFabricObjectsByName(canvas, "product");
        removeFabricObjectsByName(canvas, "location");
        removeFabricObjectsByName(canvas, "boundary");
        removeFabricObjectsByName(canvas, "amenity");
        removeFabricObjectsByName(canvas, "beacon");
        removeFabricObjectsByName(canvas, "safety");
        removeFabricObjectsBId(canvas, "short_path");
      }
    }
  }, [selFloorPlanDtls]);

  function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    var x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
      y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;

    return fabric.util.transformPoint(
      { x: x, y: y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas?.viewportTransform ?? [1, 0, 0, 1, 0, 0],
        fabricObject.calcTransformMatrix()
      )
    );
  }

  function actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
      currentControl = polygon.controls[polygon.__corner],
      mouseLocalPosition = polygon.toLocalPoint(
        new fabric.Point(x, y),
        "center",
        "center"
      ),
      polygonBaseSize = getObjectSizeWithStroke(polygon),
      size = polygon._getTransformedDimensions(0, 0),
      finalPointPosition = {
        x:
          (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
          polygon.pathOffset.x,
        y:
          (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
          polygon.pathOffset.y,
      };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    if (activeTab == "floorDetails") {
      removeFabricObjectsByName(canvas, "tracing_obj_length");
      const points = getPolygonVertices(polygon);
      // showObjLength("_", polygon.points,canvas);
      showObjLength("_", points, canvas);
      tracingLengthZoomLevel(canvas, canvas.current.getZoom());
      canvas.current.setZoom(canvas.current.getZoom())
    }
    return true;
  }

  /* original */
  const canvasBackgroundImageHandler = (
    imgSrc,
    zoom,
    bgColor,
    scaleX,
    scaleY
  ) => {
    canvasBGimage(
      canvas,
      projectSettings,
      addNewFloor,
      selFloorPlanDtls,
      canvasContainerRef,
      activeTab,
      imgSrc,
      zoom,
      bgColor,
      scaleX,
      scaleY,
      zoomInOut,
      svgFile
    );
  };

  const onSelectReferanceImage = (e) => {
    const pic = e.target.files[0]
    setFileKey(Date.now());
    if (pic) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
      if (!allowedTypes.includes(pic.type)) {
        toast.error('Please check the supported formats of the reference image and try again.')
      } else {
        if (pic) {
          // if (pic.type === 'image/svg+xml') {
          //   const reader = new FileReader();

          //   reader.onload = function (event) {
          //     const svgData = event.target.result;

          //     // Create a DOM parser to extract SVG dimensions
          //     const parser = new DOMParser();
          //     const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
          //     const svgElement = svgDoc.documentElement;

          //     // Get the width and height from the SVG attributes (or viewBox if width/height is not set)
          //     const width = svgElement.getAttribute('width') || svgElement.viewBox.baseVal.width;
          //     const height = svgElement.getAttribute('height') || svgElement.viewBox.baseVal.height;

          //     if (!width || !height) {
          //       toast.error('SVG does not have valid width/height or viewBox attributes.');
          //       return;
          //     }

          //     // Define a high scale factor for maximum quality
          //     const scaleFactor = 1.5;
          //     const highResWidth = width * scaleFactor;
          //     const highResHeight = height * scaleFactor;

          //     // Create a high-resolution canvas
          //     const canvas = document.createElement('canvas');
          //     canvas.width = highResWidth;
          //     canvas.height = highResHeight;

          //     const ctx = canvas.getContext('2d');

          //     // Clear the canvas (not really necessary since we aren't drawing a background)
          //     ctx.clearRect(0, 0, canvas.width, canvas.height);

          //     const img = new Image();
          //     img.onload = function () {
          //       // Draw the SVG image scaled to the higher resolution
          //       ctx.drawImage(img, 0, 0, highResWidth, highResHeight);

          //       // Convert the canvas content to PNG (preserving transparency)
          //       const dataURL = canvas.toDataURL('image/png'); // Use PNG to preserve quality

          //       // Create a file from the data URL
          //       fetch(dataURL)
          //         .then(res => res.blob())
          //         .then(blob => {
          //           const convertedImage = new File([blob], 'converted-image.png', { type: 'image/png' });

          //           // Replace the `pic` object with the converted image
          //           setFloorPlanModal(true);
          //           setSelFloorPlanDtls((prev) => ({
          //             ...prev,
          //             imageScale: URL.createObjectURL(convertedImage),
          //             image: convertedImage,
          //             imageType: 'referance-image',
          //             show_image: 1
          //           }));
          //         });
          //     };

          //     // Set the source of the image to the SVG data
          //     img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
          //   };

          //   // Read the SVG file as text to preserve exact content
          //   reader.readAsText(pic);
          // } else {
          // Handle non-SVG files normally
          setFloorPlanModal(true);
          setSelFloorPlanDtls((prev) => ({
            ...prev,
            imageScale: URL.createObjectURL(pic),
            image: pic,
            imageType: 'referance-image',
            show_image: 1
          }));
          // setSvgFile(pic)
        }
        // }
      }
    }
  }

  const importSvg = (e) => {
    const pic = e.target.files[0]
    setFileKey(Date.now());
    if (pic) {
      const allowedTypes = ['image/svg+xml'];
      if (!allowedTypes.includes(pic.type)) {
        toast.error('Please check the supported formats of the reference image and try again.')
      } else {
        if (pic) {
          setFloorPlanModal(true)
          setSelFloorPlanDtls((prev) => ({ ...prev, imageScale: URL.createObjectURL(pic), svg_image: pic, imageType: 'import-svg' }))
        }
      }
    }
  }

  const handleScaleSubmit = (type, values) => {
    console.log(type, selFloorPlanDtls, 'convertedImage')
    if (!values?.points_distance) {
      toast.error('Select two points on your floor plan.')
      return
    }
    if (type === 'referance-image') {
      setLoadingSacle(true)
      const zoomLevel = (parseFloat(values?.real_world_distance) / parseFloat(values?.points_distance)) * 100;
      const normalizedDistance = (zoomLevel / 100)
      setSelFloorPlanDtls((prev) => ({ ...prev, plan: prev?.imageScale, zoom: normalizedDistance }))
      handlezoomPost(normalizedDistance);
      setZoomInOut(normalizedDistance)
      // setTimeout(() => {
      if (selFloorPlanDtls?.image?.type === 'image/svg+xml') {
        handlePostSvgFileForRefImage(selFloorPlanDtls)
        setSelFloorPlanDtls((prev) => ({ ...prev, plan: null, zoom: normalizedDistance }))

      }
      document.getElementById('FloorPlanAddBtn')?.click()

      // setTimeout(() => {
      //   setFloorPlanModal(false)
      // }, 1000);
      // setSelImageOrSvgValues()
      // }, 1000);

    } else {
      setLoadingSacle(true)
      const zoomLevel = (parseFloat(values?.real_world_distance) / parseFloat(values?.points_distance)) * 100;
      const resizeSacle = (zoomLevel / 100)
      console.log(resizeSacle, 'resizeSacle')
      // return
      getTracingFromSvg(selFloorPlanDtls?.svg_image, resizeSacle)
      // setSelFloorPlanDtls((prev) => ({ ...prev, plan: null, refImg: '', image: null, show_image: 0 }))
      setTimeout(() => {
        document.getElementById('FloorPlanAddBtn')?.click()
        // setFloorPlanModal(false)
        // setSelImageOrSvgValues()
      }, 1000);

    }

  }


  const handleDeleteRefImage = () => {
    // handlezoomPost('1');
    setSelFloorPlanDtls((prev) => ({
      ...prev,
      plan: null,
      refImg: '',
      show_image: 0,
      get_svg: null
    }))
    setTimeout(() => {
      document.getElementById('FloorPlanAddBtn')?.click()
    }, 1000);
  }

  const handlePostSvgFileForRefImage = async (values) => {
    const formdata = new FormData();
    formdata.append(`svg_file`, values?.image);
    formdata.append(`id`, values?.enc_id);

    try {
      const reqUrl = 'save-svg'
      const response = await postRequest(reqUrl, formdata, true);
      const data = response.data ?? [];
      console.log(response, 'zoom')
      if (response?.type === 1) {
        getSvgFileAsRefImage(values?.enc_id)
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getCirclePosition = (circle, resizeScale) => {
    let matrix = circle.calcTransformMatrix();
    let point = { x: circle.left, y: circle.top };

    let transformedPoint = {
      x: matrix[0] * point.x + matrix[2] * point.y + matrix[4],
      y: matrix[1] * point.x + matrix[3] * point.y + matrix[5]
    };

    return {
      x: transformedPoint.x * resizeScale,
      y: transformedPoint.y * resizeScale,
      radius: circle.radius * resizeScale
    };
  };


  const getTextPosition = (text) => {
    let matrix = text.calcTransformMatrix();
    let point = { x: text.left, y: text.top };

    let transformedPoint = {
      x: matrix[0] * point.x + matrix[2] * point.y + matrix[4],
      y: matrix[1] * point.x + matrix[3] * point.y + matrix[5]
    };

    return transformedPoint;
  };

  const getTracingFromSvg = async (file, resizeScale) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const svg = event.target.result;
      fabric.loadSVGFromString(svg, (objects, options) => {
        const obj = fabric.util.groupSVGElements(objects, options);
        let tmpTracings = [];
        let tmpTracingCircle = [];
        let tempTexts = [];
        let tmpRectangle = [];
        objects.forEach((object) => {
          if (object.type === 'polygon') {
            // console.log(object,object.strokeWidth, object.type, object.stroke, 'getTracingFromSvg')
            // Scale polygon vertices
            const translatedPoints = getPolygonVertices(object);
            // console.log(object, object.type, translatedPoints, 'getTracingFromSvg ')
            const removeDuplicatedPoints = removeDuplicatePoints(translatedPoints)
            tmpTracings.push({
              vertices: scaleVertices(removeDuplicatedPoints, resizeScale),
              fill_color: object.fill ? object.fill : "#7ed32100",
              border_color: object.stroke ?? "#7ed32100",
              border_thick: object.strokeWidth ?? 0,
            });
          } else if (object.type === 'rect') {
            const translatedPoints = getRectangleVertices(object, obj, resizeScale);
            // console.log(object, translatedPoints, object.type, 'getTracingFromSvg rectangle')
            tmpTracings.push({
              vertices: scaleVertices(translatedPoints, resizeScale),
              fill_color: object.fill ? object.fill : "#7ed32100",
              border_color: object.stroke ?? "#7ed32100",
              border_thick: object.strokeWidth ?? 0,
            });
          } else if (object.type === 'circle') {
            const translatedPoints = getCirclePosition(object, resizeScale);
            const radius = (object.radius * (object.scaleX ?? 1)) * resizeScale;
            tmpTracingCircle.push({
              // vertices: translatedPoints,
              vertices: {
                x: (object.left + (obj.width / 2)) * resizeScale,
                y: (object.top + (obj.height / 2)) * resizeScale
              },
              fill_color: object.fill ? object.fill : "#7ed32100",
              border_color: object.stroke ?? "#7ed32100",
              border_thick: object.strokeWidth ?? 0,
              radius: radius,
              scaleX: object.scaleX ?? 1,
              scaleY: object.scaleY ?? 1,
            });
          } else if (object.type === 'text') {
            const transformedPoint = getTextPosition(object);
            let singleLineText = object.text.replace(/\s/g, "\u00A0");
            tempTexts.push({
              // left: transformedPoint.x * resizeScale,
              // top: transformedPoint.y * resizeScale,
              left: (object.left + (obj.width / 2)) * resizeScale,
              top: (object.top + (obj.height / 2)) * resizeScale,
              text: singleLineText,
              scaleX: object.scaleX * resizeScale,
              scaleY: object.scaleY * resizeScale,
              angle: object.angle,
              fontFamily: object.fontFamily,
              fill_color: object.fill ? object.fill : "#7ed32100",
              fontSize: object.fontSize * resizeScale,
              fontWeight: object.fontWeight ?? 'normal',
              textAlign: object.textAlign ?? 'left',
              height: object.height,
              width: object.width
            });
          }
          else if (object.type === 'line') {
            const translatedPoints = [
              { x: object.x1, y: object.y1 },
              { x: object.x2, y: object.y2 }
            ]
            tmpTracings.push({
              vertices: scaleVertices(translatedPoints, resizeScale),
              fill_color: object.fill ? object.fill : "#7ed32100",
              border_color: object.stroke ?? "#7ed32100",
              border_thick: object.strokeWidth ?? 0,
            });
          }
        });
        // console.log(tmpTracings, "tmpTracings")
        const traces = [...tracings, ...tmpTracings]
        const traceCircles = [...tracingCircle, ...tmpTracingCircle]
        const text = [...texts, ...tempTexts]
        setTracings(traces);
        postTrasing(traces);
        setTracingCircle(traceCircles);
        postTrasingCircle(traceCircles);
        setTexts(text);
        postTexts(text);
        renderTracings();
        renderTracingCircles();
        renderTexts();
      });
    };
    reader.readAsText(file);
  };

  const handlezoomPost = async (zoom) => {
    let datas = {
      id: floorID,
      img_size: zoom
    }
    try {
      const reqUrl = 'update-size'
      const response = await postRequest(reqUrl, datas);
      const data = response.data ?? [];
      console.log(data, 'zoom')
      // getFloorPlanByid(floorID);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (selFloorPlanDtls?.plan && selFloorPlanDtls?.zoom && selFloorPlanDtls?.show_image == 1) {
      canvasBackgroundImageHandler(
        selFloorPlanDtls?.plan,
        selFloorPlanDtls?.zoom
      );
    }
  }, [selFloorPlanDtls]);

  const onChangeTracingMetadata = (value, type) => {
    let ppty = "";
    switch (type) {
      case "fill_color":
        ppty = "fill";
        break;
      case "border_color":
        ppty = "stroke";
        break;
      case "border_thick":
        ppty = "strokeWidth";
        break;

      default:
        break;
    }
    console.log(obj, 'style-change')
    if (obj && obj?.name === "tracing") {
      if (ppty) {
        obj.set(ppty, value);
        setSelFloorPlanDtls((prev) => ({ ...prev, [type]: value }));
      }
    } else if (
      obj?.name !== "tracing" &&
      obj?.name !== "text" &&
      obj.name === "tracing_group"
    ) {
      console.log(obj, "obj");
      const bulkSelected = canvas.current.getActiveObjects();
      bulkSelected?.forEach((a) => {
        if (a.name === "tracing") {
          a.set(ppty, value);
        }
      });
      // canvas.current.discardActiveObject();
      canvas.current.renderAll();
    }

    canvas.current.requestRenderAll();
    updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
    updateTracingCircle(canvas, setTracingCircle, setTracingIntialValue, postTrasingCircle);
  };

  const onSaveTracingStyle = () => {
    updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
  };

  // delete tracing
  const onDeleteTracing = (enc_id) => {
    //  previous code *****************
    // if (obj.name == "tracing" || obj.name == "text") {
    //   canvas.current.remove(obj);
    // } else {
    //   const bulkSelected = canvas.current.getActiveObjects();
    //   if (bulkSelected.type === "activeSelection") {
    //     bulkSelected.forEachObject((obj) => {
    //       canvas.current.remove(obj);
    //     });
    //     canvas.discardActiveObject();
    //   } else {
    //     bulkSelected?.forEach((a) => {
    //       if (a.name === "tracing" || a.name == "text") {
    //         canvas.current.remove(a);
    //       }
    //     });
    //   }
    //   canvas.current.remove(obj);
    //   canvas.current.discardActiveObject();
    //   canvas.current.renderAll();
    // }
    // canvas.current.requestRenderAll();
    // updateTracing(
    //   canvas,
    //   setTracings,
    //   setTracingIntialValue,
    //   postTrasing,
    //   enc_id
    // );
    // setSelTracingId();
    // obj = "";
    // updateText(canvas, setTexts, postTexts, enc_id);

    // changed by savad on 23/07/2024
    const bulkSelected = canvas.current.getActiveObjects();
    if (bulkSelected.type === "activeSelection") {
      bulkSelected.forEachObject((obj) => {
        canvas.current.remove(obj);
      });
      canvas.discardActiveObject();
    } else {
      bulkSelected?.forEach((a) => {
        canvas.current.remove(a);
      });
    }
    canvas.current.remove(obj);
    canvas.current.discardActiveObject();
    canvas.current.renderAll();
    // }
    canvas.current.requestRenderAll();
    updateTracing(
      canvas,
      setTracings,
      setTracingIntialValue,
      postTrasing,
      enc_id
    );
    setSelTracingId();
    setSelObject();
    obj = "";
    updateText(canvas, setTexts, postTexts, enc_id);
    updateTracingCircle(canvas, setTracingCircle, setTracingIntialValue, postTrasingCircle, enc_id);
  };

  // // duplicate tracing
  // const duplicateObject = () => {
  //   const activeObject = canvas?.current?.getActiveObject();
  //   if (activeObject) {
  //     const objectData = activeObject.toObject();
  //     activeObject.clone(function (cloned) {
  //       cloned.set(objectData);
  //       cloned.set({
  //         left: cloned.left + 10,
  //         top: cloned.top + 10,
  //         evented: true,
  //         selectable: true,
  //       });

  //       cloned.set({
  //         name: activeObject?.get("name"),
  //         id: new Date()?.toString(),
  //       });
  //       console.log(cloned, 'cloned')
  //       canvas?.current?.add(cloned).renderAll();
  //       updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
  //       showCornerPoints(cloned)
  //       removeFabricObjectsByName(canvas, "tracing_obj_length");
  //       const points = getPolygonVertices(cloned);
  //       showObjLength(cloned, points, canvas);
  //       tracingLengthZoomLevel(canvas, canvas.current.getZoom());
  //       // canvas?.current?.setActiveObject(cloned);
  //     });
  //   }
  // };

  // duplicate tracing
  const duplicateObject = () => {
    const activeObject = canvas?.current?.getActiveObject();

    if (!activeObject) return;

    console.log(activeObject, activeObject.type, "activeObject.type")

    if (activeObject.type === 'polygon') {
      // Handle single polygon duplication
      let polyObj = new fabric.Polygon([...activeObject.points], {
        objectCaching: false,
        name: "tracing",
        id: new Date().toString(),
        fill: activeObject.get("fill"),
        stroke: activeObject.get("stroke"),
        strokeWidth: activeObject.get("strokeWidth"),
        originX: activeObject.get("originX"),
        originY: activeObject.get("originY"),
        perPixelTargetFind: activeObject.get("perPixelTargetFind"),
        position: activeObject.get("position"),
        zIndex: activeObject.get("zIndex"),
        selectable: activeObject.get("selectable"),
        hoverCursor: activeObject.get("hoverCursor"),
        evented: activeObject.get("evented"),
        opacity: activeObject.get("opacity"),
        left: activeObject.get("left") + 10,
        top: activeObject.get("top") + 10,
      });

      polyObj.setCoords();
      canvas?.current?.add(polyObj);
      showCornerPoints(polyObj);
      const points = getPolygonVertices(polyObj);
      removeFabricObjectsByName(canvas, "tracing_obj_length");
      showObjLength(polyObj, points, canvas);
      canvas?.current?.setActiveObject(polyObj);

    } else if (activeObject.type === 'activeSelection') {
      const objects = activeObject.getObjects();
      objects.forEach(obj => {
        let newObj;
        let objLeft = obj.left + activeObject.left;
        let objTop = obj.top + activeObject.top;

        if (obj.type === 'polygon') {
          newObj = new fabric.Polygon([...obj.points], {
            objectCaching: false,
            name: "tracing",
            id: new Date().toString(),
            fill: obj.get("fill"),
            stroke: obj.get("stroke"),
            strokeWidth: obj.get("strokeWidth"),
            originX: obj.get("originX"),
            originY: obj.get("originY"),
            perPixelTargetFind: obj.get("perPixelTargetFind"),
            position: obj.get("position"),
            zIndex: obj.get("zIndex"),
            selectable: obj.get("selectable"),
            hoverCursor: obj.get("hoverCursor"),
            evented: obj.get("evented"),
            opacity: obj.get("opacity"),
            left: objLeft + 10,
            top: objTop + 10,
          });
        } else if (obj.type === 'circle') {
          newObj = new fabric.Circle({
            ...obj.toObject(),
            left: objLeft + 10,
            top: objTop + 10,
            evented: true,
          });
        } else {
          newObj = fabric.util.object.clone(obj);
          newObj.set({
            left: objLeft + 10,
            top: objTop + 10,
            evented: true,
          });
        }

        newObj.set({
          name: obj.get("name"),
          id: new Date().toString(),
        });

        canvas?.current?.add(newObj);
        // showCornerPoints(newObj);
        // const points = getPolygonVertices(newObj);
        // removeFabricObjectsByName(canvas, "tracing_obj_length");
        // showObjLength(newObj, points, canvas);
        canvas?.current?.setActiveObject(newObj);
      });
    } else if (activeObject.type === 'circle') {
      const objectData = activeObject.toObject();
      activeObject.clone(function (cloned) {
        cloned.set(objectData);
        cloned.set({
          left: cloned.left + 10,
          top: cloned.top + 10,
          evented: true,
        });

        cloned.set({
          name: activeObject?.get("name"),
          id: new Date()?.toString(),
        });
        canvas?.current?.add(cloned);
        canvas?.current?.setActiveObject(cloned);
        // showCornerPoints(cloned);
        // const points = getPolygonVertices(cloned);
        // removeFabricObjectsByName(canvas, "tracing_obj_length");
        // showObjLength(cloned, points, canvas);
        canvas?.current?.setActiveObject(cloned);
      });


    } else {
      // Handle other object types duplication
      const objectData = activeObject.toObject();
      activeObject.clone(function (cloned) {
        cloned.set(objectData);
        cloned.set({
          left: cloned.left + 10,
          top: cloned.top + 10,
          evented: true,
        });

        cloned.set({
          name: activeObject?.get("name"),
          id: new Date()?.toString(),
        });

        canvas?.current?.add(cloned).renderAll();
        updateTracing(canvas, setTracings, setTracingIntialValue, postTrasing);
        updateText(canvas, setTexts, postTexts);
        updateTracingCircle(canvas, setTracingCircle, setTracingIntialValue, postTrasingCircle);

        if (cloned.type === 'polygon') {
          showCornerPoints(cloned)
          removeFabricObjectsByName(canvas, "tracing_obj_length");
          const points = getPolygonVertices(cloned);
          showObjLength(cloned, points, canvas);
          // tracingLengthZoomLevel(canvas, canvas.current.getZoom());
        }

        // removeFabricObjectsByName(canvas, "tracing")
        // let tmpTracings = [...tracings, cloned];
        // renderTracings(tmpTracings)
        changeSelectionAllObjs(canvas, true, "tracing");
        changeSelectionAllObjs(canvas, true, "text");
        // console.log(cloned, 'cloned')
        canvas?.current?.setActiveObject(cloned);
      });
    }
  };





  const deleteTracingsByDeleteKey = () => {
    let activeTabVar;
    setActiveTab((prev) => {
      activeTabVar = prev;
      return prev;
    });
    let toolActiveVar;
    setToolActive((prev) => {
      toolActiveVar = prev;
      return prev;
    });

    let addNewFloorVar;
    setAddNewFloor((prev) => {
      addNewFloorVar = prev;
      return prev;
    });

    let selFloorPlanDtlsVar;
    setSelFloorPlanDtls((prev) => {
      selFloorPlanDtlsVar = prev;
      return prev;
    });

    if (
      activeTabVar === "floorDetails" &&
      toolActiveVar == "Select" &&
      addNewFloorVar
    ) {
      console.log("delete-tracing");
      onDeleteTracing(selFloorPlanDtlsVar?.enc_id);
    }
  };

  const updatePinPosition = async (obj, position) => {
    setSavingTimer(true)
    // 
    let value = {
      project_id: id,
      id: obj?.enc_id,
      type: getTypeByName(obj),
      positions: position,
      is_published: "0",
      discard: "1",
      publish: "1",
    };
    if (value?.type == 1) {
      console.log(obj, 'getPolygonVertices')
      // boundaryAttributes = getPolygonVertices(obj);
      value.boundary_attributes = boundaryAttributes
    }
    try {
      const reqUrl = `update-position`;
      const response = await postRequest(reqUrl, value);
      let floor;
      setSelFloorPlanDtls((prev) => {
        floor = prev;
        return prev;
      });
      handleEnableDisable();
      boundaryAttributes = undefined;
      const floorPlanId = floor?.enc_id ?? obj?.fp_id ?? floorID ?? selFloorPlanDtls?.enc_id
      // console.log(floorID, selFloorPlanDtls?.enc_id, floor?.enc_id, obj?.fp_id, 'selFloorPlanDtls?.enc_id')
      // if (activeTab === "traversable") {
      if (obj?.name === 'location') {
        getLocationList(floorPlanId)
      } else if (obj?.name === 'product') {
        getProductList(floorPlanId)
      } else if (obj?.name === 'beacon') {
        getBeaconList(floorPlanId)
      } else if (obj?.name === 'amenity') {
        getAmenityList(floorPlanId)
      } else if (obj?.name === 'safety') {
        getSafetyList(floorPlanId)
      } else if (obj?.name === 'vertical') {
        getVerticalTransportCurrentFloor((floorPlanId), setVerticalTransports)
      }
      if (activeTab === "traversable") {
        setTimeout(() => {
          checkPinConnectOrNot()
        }, 1000);
      }

    } catch (error) {
      // console.log(error);
    } finally {
      setSavingTimer(false)

    }
  }


  const postTrasing = async (tracings, enc_id) => {
    let value = {
      id: enc_id ?? selFloorPlanDtls?.enc_id ?? floorID,
      tracings: JSON.stringify(tracings),
      is_published: "0",
      discard: "1",
      publish: "1",
    };
    try {
      const reqUrl = `update-tracing`;
      const response = await postRequest(reqUrl, value);
      const data = response.response?.data ?? [];
      handleEnableDisable();
    } catch (error) {
      ////// console.log(error);
    }
  };

  const postTrasingCircle = async (circleDatas, enc_id) => {
    let value = {
      id: enc_id ?? selFloorPlanDtls?.enc_id ?? floorID,
      circle_data: JSON.stringify(circleDatas),
      is_published: "0",
      discard: "1",
      publish: "1"
    };
    try {
      const reqUrl = `update-circle`;
      const response = await postRequest(reqUrl, value);
      const data = response.response?.data ?? [];
      handleEnableDisable();
    } catch (error) {
      ////// console.log(error);
    }
  };

  const postTexts = async (text, enc_id) => {
    let value = {
      id: enc_id ?? selFloorPlanDtls?.enc_id ?? floorID,
      text: JSON.stringify(text),
      is_published: "0",
      discard: "1",
      publish: "1",
    };
    try {
      const reqUrl = `update-text`;
      const response = await postRequest(reqUrl, value);
      const data = response.response?.data ?? [];
      handleEnableDisable();
    } catch (error) {
      ////// console.log(error);
    }
  };

  const onCreateNode = (position, nodeName) => {
    const relPosX = position?.x;
    const relPosY = position?.y;

    if (key1) {
      key2 = key1;
    }
    key1 = nodeName;
    graph.addNode(nodeName);
    graph.addPosition(nodeName, relPosX, relPosY);
    setPosits((prev) => ({ ...prev, [nodeName]: { x: relPosX, y: relPosY } }));
  };
  function sortCoordinatesAlongLine(coordinates, lineStart, lineEnd) {
    function distanceAlongLine(point, lineStart, lineEnd) {
      const dx = lineEnd?.x - lineStart?.x;
      const dy = lineEnd?.y - lineStart?.y;
      const lineLengthSquared = dx * dx + dy * dy;

      const t = ((point?.x - lineStart?.x) * dx + (point?.y - lineStart?.y) * dy) / lineLengthSquared;
      return t;
    }

    return coordinates.sort((a, b) => {
      const distanceA = distanceAlongLine(a?.coord, lineStart, lineEnd);
      const distanceB = distanceAlongLine(b?.coord, lineStart, lineEnd);
      return distanceA - distanceB;
    });
  }
  useEffect(() => {
    if (activeTab === "traversable") {
      getProjectById();
      getFloorsList();
    }
  }, [activeTab]);

  const showPath = (sourcePoint, destinationPoint) => {
    removeFabricObjectsBId(canvas, "short_path");
    removeFabricObjectsByName(canvas, "highlight_node");
    // removeFabricObjectsByName(canvas, "node");

    canvas.current.discardActiveObject();
    setSelectedPaths(false);
    let shortestPath = null;
    shortestPath = dijkstra(graph, sourcePoint, destinationPoint);
    let nodes = graph.getPositions();
    // console.log(shortestPath, "shortestPath");
    if (shortestPath) {
      pathLine(canvas, nodes, projectSettings, shortestPath);
      highligthNodes(canvas, projectSettings, shortestPath);
      canvas.current.renderAll();
    }
  };

  const findShortestPath = (fromValue, toValue, graphValue, position) => {
    removeFabricObjectsBId(canvas, "short_path");
    removeFabricObjectsByName(canvas, "highlight_node");
    if (!selTraversibleDetails.from || !selTraversibleDetails.to)
      if (selTraversibleDetails.from == selTraversibleDetails.to) return;
    // console.log(selTraversibleDetails, "selTraversibleDetails");
    let shortestPath = null;
    shortestPath = dijkstra(
      graphValue ?? graph,
      fromValue ?? selTraversibleDetails.from,
      toValue ?? selTraversibleDetails.to
    );
    // console.log(shortestPath, " ");
    let nodes = position ?? graph.getPositions();
    let edges = graph.getEdges();
    if (shortestPath) {
      shortestPath.forEach((p, id) => {
        if (id < shortestPath.length - 1) {
          let points = [
            nodes[p].x,
            nodes[p].y,
            nodes[shortestPath[id + 1]].x,
            nodes[shortestPath[id + 1]].y,
          ];
          let line = new fabric.Line(points, {
            strokeWidth: projectSettings?.navigation_thick ?? 3,
            stroke: projectSettings?.navigation_color ?? "red",
            selectable: false,
            name: graphValue ? "level_short_path" : "short_path",
            id: "short_path",
            originX: "center",
            originY: "center",
            hoverCursor: "auto",
            // strokeDashArray: [5, 5],
          });
          canvas.current.add(line).renderAll();
        }
      });

      highligthNodes(canvas, projectSettings, shortestPath);
      canvas.current.renderAll();
    }
  };

  function checkFloorConnections(selectedVTS, isAccending) {
    const matchingItems = [];
    for (let item1 of selectedVTS) {
      for (let item2 of allVerticalTransports) {
        if (
          isAccending
            ? item1.vt_id === item2.vt_id &&
            floorIndex(item1?.floor_plan_id) <=
            floorIndex(selTraversibleDetails?.to_floor_id)
            : item1.vt_id === item2.vt_id &&
            floorIndex(item1?.floor_plan_id) >=
            floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          matchingItems.push(item2);
        }
      }
    }
    if (matchingItems.length > 0) {
      return matchingItems;
    } else {
      return null;
    }
  }

  function findShortestVTS(data, from) {
    let minLength = Infinity;
    let shortestItem = null;
    data?.forEach((item) => {
      const path = dijkstraWithLength(graph, from, item.name);
      // console.log(path, "sp");
      if (path?.length < minLength) {
        minLength = path.length;
        shortestItem = item;
      }
    });
    return shortestItem;
  }

  function checkTopFloorVTS(data, isAccending) {
    let checkDirectConnectionReturn = checkFloorConnections(data, isAccending);
    // console.log(checkDirectConnectionReturn, "checkDirectConnectionReturn");
    const dataArray = checkDirectConnectionReturn ?? data;
    const filteredArray = dataArray.reduce((acc, current) => {
      const floorPlanId = parseInt(floorIndex(current.floor_plan_id));
      if (isAccending) {
        if (
          floorPlanId > (floorIndex(acc[0]?.floor_plan_id) || 0) &&
          floorPlanId <= floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          return [current];
        } else if (floorPlanId === (floorIndex(acc[0]?.floor_plan_id) || 0)) {
          acc.push(current);
        }
      } else {
        if (
          floorPlanId <
          (floorIndex(acc[0]?.floor_plan_id) ||
            floorIndex(dataArray[dataArray?.length - 1]?.floor_plan_id)) &&
          floorPlanId >= floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          return [current];
        } else if (
          floorPlanId ===
          (floorIndex(acc[0]?.floor_plan_id) ||
            floorIndex(dataArray[dataArray?.length - 1]?.floor_plan_id))
        ) {
          acc.push(current);
        }
      }
      return acc;
    }, []);
    // console.log(filteredArray, "filteredArray");
    const topVTIDS = filteredArray?.map((item) => item?.vt_id);
    const topFloorID = filteredArray?.map((item) =>
      floorIndex(item?.floor_plan_id)
    );
    const filteredData = data.filter(
      (item) =>
        topVTIDS.includes(item?.vt_id) &&
        !topFloorID.includes(floorIndex(item?.floor_plan_id))
    );
    return filteredData;
  }

  function convertVerticalPinData(array) {
    const data = array?.map((item) => ({
      enc_id: item?.vtd_id,
      name: `vertical_${item?.vtd_id}`,
      type: 6,
      to_floor_id: item?.floor_plan_id,
      vertical_transport_id: item?.vt_id,
      vt_name: item?.vt_name,
      floor_plan: item?.floor_plan,
    }));
    return data ?? [];
  }

  function findHighestFloorPlanId(array, item, isAscending) {
    let maxFloorPlanId = isAscending ? -Infinity : Infinity;
    let connectedItem;

    if (isAscending) {
      array.forEach((arrItem) => {
        if (arrItem.vt_id === item.to_vt_id) {
          if (
            floorIndex(arrItem.floor_plan_id) > maxFloorPlanId &&
            floorIndex(arrItem.floor_plan_id) <=
            floorIndex(selTraversibleDetails?.to_floor_id)
          ) {
            maxFloorPlanId = floorIndex(arrItem.floor_plan_id);
            connectedItem = arrItem;
          }
        }
      });
    } else {
      array.forEach((arrItem) => {
        if (arrItem.vt_id === item.to_vt_id) {
          if (
            floorIndex(arrItem.floor_plan_id) < maxFloorPlanId &&
            floorIndex(arrItem.floor_plan_id) >=
            floorIndex(selTraversibleDetails?.to_floor_id)
          ) {
            maxFloorPlanId = floorIndex(arrItem.floor_plan_id);
            connectedItem = arrItem;
          }
        }
      });
    }
    return connectedItem;
  }

  const handlePontsAndEdges = (floor_id) => {
    const floorPlanDtls = allPointsAndEdges?.find(
      (item) => item?.fp_id == floor_id
    );
    if (floorPlanDtls?.edges_data) {
      const edges = JSON.parse(floorPlanDtls.edges_data);
      // // console.log(edges, "edges");
      if (edges?.length === 0) {
        graph.restoreEdges();
      } else {
        graph.restoreEdges(edges);
        const nodeFromAPI = Object.keys(edges);
        nodeFromAPI?.forEach((n) => {
          graph.addNode(n);
        });
      }
    } else {
      graph.restoreEdges();
    }
    if (floorPlanDtls?.points_data) {
      const points = JSON.parse(floorPlanDtls.points_data);
      if (points?.length === 0) {
        graph.restorePositions();
      } else {
        graph.restorePositions(points);
      }
    } else {
      graph.restorePositions();
    }
  };

  const getCurrentIndex = (id) => {
    const item = floorPlans.find((item) => item?.enc_id == id);
    return item?.display_index;
  };

  const floorIndex = (id) => {
    let floorss;
    // if ((selTraversibleDetails?.from_floor_id) > (selTraversibleDetails?.to_floor_id)) {
    if (
      getCurrentIndex(selTraversibleDetails?.from_floor_id) >
      getCurrentIndex(selTraversibleDetails?.to_floor_id)
    ) {
      floorss = floorPlansPathSort.sort(
        (a, b) => b.display_index - a.display_index
      );
    } else {
      floorss = floorPlansPathSort.sort(
        (a, b) => a.display_index - b.display_index
      );
    }

    const index = floorss?.findIndex((item) => id == item.enc_id);
    return index;
  };

  // const onSelectVerticalTransport = (data) => {
  //   console.log(allVerticalTransports, 'allVerticalTransports')
  //   let pathArray = [];
  //   let result;
  //   const isAccending =
  //     floorIndex(selTraversibleDetails?.from_floor_id) <
  //     floorIndex(selTraversibleDetails?.to_floor_id);
  //   console.log(isAccending);

  //   for (
  //     let i = floorIndex(selTraversibleDetails?.from_floor_id);
  //     isAccending
  //       ? i <= floorIndex(selTraversibleDetails?.to_floor_id)
  //       : i >= floorIndex(selTraversibleDetails?.to_floor_id);

  //   ) {
  //     const element = i;

  //     console.log(element);
  //     const selectedVTS = allVerticalTransports?.filter(
  //       (item) =>
  //         data?.icon_id == item?.icon_id &&
  //         element == floorIndex(item?.floor_plan_id)
  //     );

  //     console.log(selectedVTS, "selectedVTS");
  //     let topMostItem;
  //     if (i != floorIndex(selTraversibleDetails?.to_floor_id)) {
  //       // topMostItem = checkTopMostConnetedVT(selectedVTS, allVerticalTransports);
  //       topMostItem = checkTopFloorVTS(
  //         selectedVTS,
  //         isAccending,
  //         allVerticalTransports
  //       );
  //       topMostItem = topMostItem.filter(
  //         (item) => result?.vt_id !== item?.vt_id
  //       );

  //       if (topMostItem.length === 0) {
  //         const diffrentTypeVT = allVerticalTransports.filter(
  //           (item) =>
  //             floorIndex(item?.floor_plan_id) ===
  //             floorIndex(result?.floor_plan_id) &&
  //             result?.vt_id !== item?.vt_id
  //         );
  //         console.log(diffrentTypeVT, "diffrentTypeVT");
  //         // topMostItem = checkTopMostConnetedVT(diffrentTypeVT, allVerticalTransports);
  //         topMostItem = checkTopFloorVTS(
  //           diffrentTypeVT,
  //           isAccending,
  //           allVerticalTransports
  //         );
  //         topMostItem = topMostItem.filter(
  //           (item) => result?.vt_id !== item?.vt_id
  //         );
  //       }
  //     } else {
  //       topMostItem = [result];
  //     }
  //     console.log(result, "result");
  //     console.log(topMostItem, "topMostItem");

  //     // Find the shortest VT for the current floor
  //     let fromData;
  //     if (i === floorIndex(selTraversibleDetails?.from_floor_id)) {
  //       fromData = selTraversibleDetails?.from;
  //     } else if (i == floorIndex(selTraversibleDetails?.to_floor_id)) {
  //       fromData = selTraversibleDetails?.to;
  //     } else {
  //       fromData = `vertical_${result?.vtd_id}`;
  //     }
  //     console.log(fromData, "fromData");
  //     const indexID = floorPlans[i]?.enc_id;
  //     handlePontsAndEdges(indexID);
  //     let shortestVt = findShortestVTS(
  //       convertVerticalPinData(topMostItem),
  //       fromData
  //     );
  //     console.log(shortestVt, "shortestVt");

  //     const from =
  //       i === floorIndex(selTraversibleDetails?.from_floor_id)
  //         ? selTraversibleDetails?.from
  //         : `vertical_${result?.vtd_id}`;
  //     let item = {
  //       floor_plan_id: shortestVt?.to_floor_id,
  //       from: from,
  //       to:
  //         i === floorIndex(selTraversibleDetails?.to_floor_id)
  //           ? selTraversibleDetails?.to
  //           : shortestVt?.name,
  //       to_vt_id: shortestVt?.vertical_transport_id,
  //       floor_plan: shortestVt?.floor_plan,
  //       vt_name: shortestVt?.vt_name,
  //     };
  //     pathArray.push(item);
  //     if (
  //       floorIndex(result?.floor_plan_id) !=
  //       floorIndex(selTraversibleDetails?.to_floor_id)
  //     ) {
  //       result = findHighestFloorPlanId(
  //         allVerticalTransports,
  //         item,
  //         isAccending
  //       );
  //       console.log(result);
  //       if (i === floorIndex(result?.floor_plan_id)) {
  //         break;
  //       } else {
  //         i = floorIndex(result?.floor_plan_id);
  //       }
  //     } else {
  //       break;
  //     }
  //   }
  //   console.log(pathArray, "pathArray");
  //   setSelTraversibleDetails((prev) => ({
  //     ...prev,
  //     pathArray,
  //     is_miltiple: true,
  //   }));
  //   handlePontsAndEdges(selTraversibleDetails?.from_floor_id);
  //   toggleVerticalClose();
  //   if (
  //     pathArray[pathArray?.length - 1]?.floor_plan_id ==
  //     selTraversibleDetails?.to_floor_id
  //   ) {
  //     showPath(pathArray[0]?.from, pathArray[0]?.to);
  //   } else {
  //     setSelTraversibleDetails((prev) => ({
  //       ...prev,
  //       pathArray,
  //       is_miltiple: false,
  //     }));
  //     toast.warning(
  //       "Sorry! The chosen vertical transport has no connection to your destination."
  //     );
  //   }
  // };

  // const handleVerticalTransportSelection = (data) => {
  //   onSelectVT(
  //     data,
  //     allVerticalTransports,
  //     selTraversibleDetails,
  //     floorPlans,
  //     floorPlansPathSort,
  //     handlePontsAndEdges,
  //     setSelTraversibleDetails,
  //     toggleVerticalClose,
  //     showPath,
  //     toast, graph
  //   );
  // };





  function getMatchingFloorPlan(fromPoints, toPoints, verticalTransports) {
    if(fromPoints[0] == null || toPoints[0] === null ) return []
    // console.log(fromPoints, toPoints, verticalTransports,fromPoints.length, toPoints.length == 0, "fromPoints, toPoints, verticalTransports")
    // if(fromPoints.length  = 0 || toPoints.length == 0) return []
    let matchingFloorPlans = []; // Array to store all matching floor plans

    // Loop through all floors in verticalTransports
    for (let floor of verticalTransports) {
      let transports = floor.transports;

      // Find matching transports from 'fromPoints' in the current floor
      let matchingFromTransports = transports.filter(transport =>
          // fromPoints.some(fromPoint => fromPoint.vt_id === transport.vt_id)
          fromPoints.some(fromPoint => fromPoint.vertical_transport_id === transport.vt_id)
      );

      // Find matching transports from 'toPoints' in the current floor
      let matchingToTransports = transports.filter(transport =>
          // toPoints.some(toPoint => toPoint.vt_id === transport.vt_id)
          toPoints.some(toPoint => toPoint.vertical_transport_id === transport.vt_id)
      );
      // If both fromPoints and toPoints have matching transports on this floor
      if (matchingFromTransports.length > 0 && matchingToTransports.length > 0) {
        // console.log(matchingFromTransports,matchingToTransports,"matchingFromTransports,matchingToTransports")
        matchingFloorPlans.push({
              floor_plan_id: floor.floor_plan, // Floor plan id
              floor_plan: floor.floor_plan_name, // Floor plan name
              fromTransports: convertVerticalPinData(matchingFromTransports), // Matching transports from 'fromPoints'
              toTransports: convertVerticalPinData(matchingToTransports) // Matching transports from 'toPoints'
          });
      }
    }

    return matchingFloorPlans; 
  }


  const matchConnectionByAnyTransport = (allVerticalTransports, fromvt, tovt, iconid = false) => {

    const transportsByFloor = floorPlans.map(floor => {
      return {
          floor_plan: floor.enc_id,
          floor_plan_name: floor.floor_plan,
        transports: allVerticalTransports.filter(transport => {
          if (iconid) {
           return  transport.floor_plan_id === floor.enc_id && transport.icon_id === iconid
          } else {
            return transport.floor_plan_id === floor.enc_id
          }
        })
      };
    });
// console.log(transportsByFloor,"all vertical transport")
    let fromFloor = transportsByFloor.filter((item) => item.floor_plan === selTraversibleDetails.from_floor_id)
    let toFloor = transportsByFloor.filter((item) => item.floor_plan === selTraversibleDetails.to_floor_id)
  
    function findTransportItems(currentTransport, floorplanId, iconid = false) {
    // console.log(iconid,"icon id in find transport item")
      const countOccurrences = (floorPlan) => {
        return floorplanId.filter((id) => id === floorPlan).length;
      };
      // console.log(transportsByFloor,floorplanId,"transportsByFloor")
      return transportsByFloor.filter(
        (item) =>
            item.transports.some(
              (transport) => transport.vt_id === currentTransport.vt_id && transport.floor_plan_id != currentTransport.floor_plan_id 
            )&& !floorplanId.includes(item.floor_plan)
          // )&& countOccurrences(item.floor_plan) <= 1
      );
    }
  
    function checkTransportOnDestination(currentTransport, toFloor,transport) {
      // return currentTransport.some((item) =>
      //   toFloor.some((to) => to.floor_plan === item.floor_plan)
      // );
      return toFloor.some((to) => to.floor_plan === transport.floor_plan_id)
    }
  
    // Recursive function to traverse through floors and transports
    function traverseTransports(transport, floorplanId, connectedPath, toFloor,previousTransportId = null,iconid=false) {
      let transportItemsByFloor = findTransportItems(transport, floorplanId,iconid);
  // console.log(transportItemsByFloor,"transportItemsByFloor")
      transportItemsByFloor.forEach(floorPlan => {
        // Find the index of the transport item with vt_id 215
        const transportIndex = floorPlan.transports.findIndex(item => item.vt_id === transport.vt_id);
        
        // If the item is found, move it to the beginning of the transports array
        if (transportIndex > -1) {
            const [transportItem] = floorPlan.transports.splice(transportIndex, 1); // Remove the item
            floorPlan.transports.unshift(transportItem); // Add it at the beginning
        }
      });
  // console.log(transportItemsByFloor,connectedPath,"connectedPath")
      if (checkTransportOnDestination(transportItemsByFloor, toFloor,transport)) {
        return connectedPath;
      }

      function checkIfItIsReadyToPushOrNot(transportItem, arrayitems, toFloor) {
        
        // console.log(transportItem, arrayitems, toFloor,"transportItem, transports, toFloor")
        let isGreaterthan2 = arrayitems.length > 1 
        const isTransportPresent = toFloor.some(floorPlan1 => 
          floorPlan1.transports.some(transport1 => 
            arrayitems.some(transport2 => transport1.vt_id === transport2.vt_id)
          )
        );
        const isfromFloorPresent = fromFloor.some(floorPlan1 => 
          floorPlan1.transports.some(transport1 => 
            arrayitems.some(transport2 => transport1.vt_id === transport2.vt_id)
          )
        );
        // console.log(isGreaterthan2, isTransportPresent, isfromFloorPresent, "isGreaterthan2,isTransportPresent")
        if (!isGreaterthan2 && !isTransportPresent) {
          return false
        } else {
          return true
        }
      }
    
      for (let transportFloor of transportItemsByFloor) {
        floorplanId.push(transportFloor.floor_plan);
        for (let transportItem of transportFloor.transports) {
          if (checkIfItIsReadyToPushOrNot(transportItem, transportFloor.transports, toFloor)) {
            // console.log(connectedPath,"connectedPath reduy to push")
            connectedPath.push({
              transportItem:transportItem,
              transportID: transportItem.vt_id,
              transportName: transportItem.vt_name,
              floor: transportFloor.floor_plan,
              floor_plan_name: transportFloor.floor_plan_name,
            });
          }
    
          let result = traverseTransports(
            transportItem,
            floorplanId,
            connectedPath,
            toFloor,
            transport,
            iconid
          );
          if (result) {
            return result;
          }
        }
      }
    
      return null; // No connection found, return null
    }
  
    let floorplanId = []; 
    let connection = fromFloor.map((floor) => {
      floorplanId.push(floor.floor_plan);
      let connectedPath = [];
    
      for (let transport of floor.transports) {
        // console.log(transport,"transport")
        connectedPath.push({
          transportItem:transport,
          transportID: transport.vt_id,
          transportName: transport.vt_name,
          floor: floor.floor_plan,
          floor_plan_name: floor.floor_plan_name,
        });
    
        let result = traverseTransports(transport, floorplanId, connectedPath, toFloor, null, iconid);
        
        if (result) {
          return result;
        }
      }
    
      return null; // No connection found on this floor
    });
  
    return connection[0]
  }

  function findMatchingItems(arr1, arr2, type) {
    if(arr1[0] == null || arr2[0] === null ) return []
    let newarray = allVerticalTransports.filter((item) => {
      if (item.floor_plan_id === selTraversibleDetails?.to_floor_id && type === item.icon_id) {
        return item
      }
    })

    let matchingItems = [];

    const isItemInAllItems = newarray.find(allItem => 
      arr1.some( item => item.vertical_transport_id === allItem.vt_id)
    );

    // console.log(arr1, arr2, newarray, "jdfjdkshfjsdk")
    
    if (!isItemInAllItems) {
      return matchingItems
    }
    // Loop through each item in arr2
    for (let item2 of arr2) {
      // Check if any item in arr1 has the same vt_id as the current item in arr2
      // let matchingItem1 = arr1.find(item1 => {
      //   return item1.vt_id === item2.vt_id
      //   // let itemToReturn = newarray.find(allItem => 
      //   //   allItem.vt_id === item1.vertical_transport_id
      //   // );
      //   // return itemToReturn.vtd_id === item2.enc_id
      // });
      let matchingItem1 = arr1.find(item1 => {
        return item1.vertical_transport_id === item2.vertical_transport_id
        // let itemToReturn = newarray.find(allItem => 
        //   allItem.vt_id === item1.vertical_transport_id
        // );
        // return itemToReturn.vtd_id === item2.enc_id
      });

      // let matchingItem1 = isItemInAllItems.vtd_id === item2.enc_id ? arr1.find(item1 => isItemInAllItems.vt_id === item1.vertical_transport_id) : false
      // console.log(matchingItem1,item2,"matchingItem1")
      // If a match is found, add both items (from arr1 and arr2) to the matchingItems array
      if (matchingItem1) {
        handlePontsAndEdges(item2?.to_floor_id)
        // console.log(matchingItem1?.to_floor_id,item2,graph,item2.name, selTraversibleDetails.to,"path")
        const path = dijkstraWithLength(graph, item2.name, selTraversibleDetails.to);
        if (path) {
          matchingItems.push({
              from: matchingItem1,
              to: item2
          });
          
        }
      }
      
    }
    
    return matchingItems;
  }

  const findNearestFloor = (isIndirectlyConnected,fromvt,tovt) => {
    return isIndirectlyConnected.reduce((acc, current) => {
      if (findFloorIndexToCheck(fromvt[0].to_floor_id) === findFloorIndexToCheck(acc?.floor_plan_id)) {
        acc = current
      }else if (findFloorIndexToCheck( acc?.floor_plan_id) > findFloorIndexToCheck(current?.floor_plan_id) && findFloorIndexToCheck(acc?.floor_plan_id) !== findFloorIndexToCheck(fromvt[0].to_floor_id)) {
      }
      return acc
   }, isIndirectlyConnected[0])
    
  }
  
  const findFloorIndexToCheck = (id) => {
    let list = [...floorPlans];
    let allfloorlist = list.sort((a, b) => a.display_index - b.display_index);
    return allfloorlist.findIndex(fp => (fp.enc_id === id))
  }

  const checkIfItemIsPresent = (floor) => {
    handlePontsAndEdges(floor?.floor_plan_id)
    const path = dijkstraWithLength(graph, floor.fromTransports[0].name, floor.toTransports[0].name);
    return path
  }
  
  const onSelectVerticalTransport = (data) => {
    let startfloor = []
    let endfloor = []
    let fromvt = []
    let tovt = []

    allVerticalTransports.map((item) => {
      if (item.floor_plan_id === selTraversibleDetails?.from_floor_id && data.icon_id === item.icon_id) {
        startfloor.push(item)
      }

      if (item.floor_plan_id === selTraversibleDetails?.to_floor_id && data.icon_id === item.icon_id) {
        endfloor.push(item)
      }
    })

    // console.log(startfloor,endfloor,"startfloor,endfloor")
    handlePontsAndEdges(selTraversibleDetails?.from_floor_id)
    fromvt = [findShortestVTS(convertVerticalPinData(startfloor), selTraversibleDetails.from)]
    let pindata = convertVerticalPinData(startfloor)?.map((item) => {
      const path = dijkstraWithLength(graph, selTraversibleDetails.from, item.name);
      return path
    })
    
    // console.log(pindata,"pindata")
    handlePontsAndEdges(selTraversibleDetails?.to_floor_id)
    tovt = [findShortestVTS(convertVerticalPinData(endfloor), selTraversibleDetails.to)]
  
    if (pindata.every(item => item !== null)) {
      // console.log(startfloor,tovt[0],"start and end floor")
      const vt = startfloor?.find((item) => item?.vt_id === tovt[0]?.vertical_transport_id)
      if (vt) {
        fromvt = convertVerticalPinData([vt])
      }
    } else {
        console.log("Array contains null values. Operation skipped.");
    }
    const transportsByFloor = floorPlans.map(floor => {
      return {
        floor_plan: floor.enc_id,
        floor_plan_name: floor.floor_plan,
        transports: allVerticalTransports.filter(transport => transport.floor_plan_id === floor.enc_id && data.icon_id === transport.icon_id)
      };
    });

    let pathArray = [];
    let result;

    let isDirectlyConnected = findMatchingItems(fromvt, convertVerticalPinData(endfloor),data.icon_id)
    const isIndirectlyConnected = getMatchingFloorPlan(fromvt, tovt, transportsByFloor);
    let anySameConnection = matchConnectionByAnyTransport(allVerticalTransports, fromvt, tovt,data.icon_id)
    let anyConnection = matchConnectionByAnyTransport(allVerticalTransports, fromvt, tovt)
    console.log(isDirectlyConnected, isIndirectlyConnected,anySameConnection,anyConnection, "isDirectlyConnected,isIndirectlyConnected")
    // console.log(anyConnection,"matchConnectionByAnyTransport")
    // const isAnyConnecteion = findAMmatchingTransport(fromvt, tovt, transportsByFloor); 
    // if (anyConnection) {
    //   let changevts = anyConnection.map((item) => {
    //     let convertedVt = convertVerticalPinData([item.transportItem])
    //     return {floor:item.floor,convertedVt:convertedVt[0]}
    //   })
    //   console.log(changevts, "changevts")
    //     function mergeArray(arr) {
    //       // Don't touch the first and last items, so extract them first
    //       const firstItem = arr[0];
    //       const lastItem = arr[arr.length - 1];
          
    //       // Handle the middle items
    //       const middleItems = arr.slice(1, -1);
      
    //       // Use a map to track floors and merge them
    //       const floorMap = new Map();
      
    //       middleItems.forEach(item => {
    //           const floor = item.floor;
    //           if (floorMap.has(floor)) {
    //               // If the floor is already in the map, merge the `convertedVt` data
    //               const existing = floorMap.get(floor);
    //               // Convert convertedVt into an array and merge
    //               existing.convertedVt = [].concat(existing.convertedVt, item.convertedVt);
    //           } else {
    //               // Otherwise, add the floor as a new entry
    //               floorMap.set(floor, { ...item });
    //           }
    //       });
      
    //       // Recreate the array with first, merged middle items, and last
    //       const mergedArray = [firstItem, ...floorMap.values(), lastItem];
      
    //       return mergedArray;
    //   }
    //   let resultarray = mergeArray(changevts)
    //   console.log(resultarray)
    //   let itemarray = resultarray.map((item, i) => {
    //     if (i == 0) {
    //       return {
    //         floor_plan_id:item.floor,
    //         from: selTraversibleDetails.from,
    //         to: `vertical_${item.convertedVt.enc_id}`,
    //         to_vt_id: item.convertedVt.enc_id,
    //         floor_plan: item.floor,
    //         vt_name: item.convertedVt.vt_name,
    //       }
    //     } else if (i == resultarray.length - 1) {
    //       return {
    //         floor_plan_id:item.floor,
    //         from: selTraversibleDetails.to,
    //         to: `vertical_${item.convertedVt.enc_id}`,
    //         to_vt_id: item.convertedVt.enc_id,
    //         floor_plan: item.floor,
    //         vt_name: item.convertedVt.vt_name,
    //       }
    //     } else {
    //       return {
    //         floor_plan_id:item.floor,
    //         from: `vertical_${item.convertedVt[0].enc_id}`,
    //         to: `vertical_${item.convertedVt[1].enc_id}`,
    //         to_vt_id: item.convertedVt[1].enc_id,
    //         floor_plan: item.floor,
    //         vt_name: item.convertedVt[1].vt_name,
    //       }
    //     }
    //   })
    //   console.log(itemarray, "itemarray")
    //   pathArray.push(...itemarray);
    // }
    if (isDirectlyConnected.length > 0) {
      console.log('directly connected')
      let item =[
        {
          floor_plan_id: isDirectlyConnected[0].from.to_floor_id,
          from: selTraversibleDetails.from,
          to: `vertical_${isDirectlyConnected[0].from.enc_id}`,
          to_vt_id: isDirectlyConnected[0].from.enc_id,
          floor_plan: isDirectlyConnected[0].from.to_floor_id,
          vt_name: isDirectlyConnected[0].from.vt_name,
        },
        {
          floor_plan_id: isDirectlyConnected[0].to.to_floor_id,
          from: selTraversibleDetails.to,
          to: `vertical_${isDirectlyConnected[0].to.enc_id}`,
          to_vt_id: isDirectlyConnected[0].to.enc_id,
          floor_plan: isDirectlyConnected[0].to.to_floor_id,
          vt_name: isDirectlyConnected[0].to.vt_name,
        },
      ];
      console.log(item,"item")
      pathArray.push(...item);
    } else if (isIndirectlyConnected.length > 0) {
      console.log('in directly connected')
      let allconnectedFloors = isIndirectlyConnected.filter((items) => {
        return checkIfItemIsPresent(items)
      })
      let nearestFloor = findNearestFloor(allconnectedFloors,fromvt,tovt) 
      console.log(allconnectedFloors, nearestFloor, "nearestfloor")
      if (nearestFloor) {
        let item = [
          {
            floor_plan_id: fromvt[0].to_floor_id,
            from: selTraversibleDetails.from,
            to: `vertical_${fromvt[0].enc_id}`,
            to_vt_id: fromvt[0].enc_id,
            floor_plan: fromvt[0].floor_plan,
            vt_name: fromvt[0].vt_name,
          },
          {
            floor_plan_id: nearestFloor.fromTransports[0].to_floor_id,
            from: `vertical_${nearestFloor.fromTransports[0].enc_id}`,
            to: `vertical_${nearestFloor.toTransports[0].enc_id}`,
            to_vt_id: nearestFloor.toTransports[0].enc_id,
            floor_plan: nearestFloor.toTransports[0].floor_plan,
            vt_name: nearestFloor.toTransports[0].vt_name,
          },
          {
            floor_plan_id: tovt[0].to_floor_id,
            from: selTraversibleDetails.to,
            to: `vertical_${tovt[0].enc_id}`,
            to_vt_id: tovt[0].enc_id,
            floor_plan: tovt[0].floor_plan,
            vt_name: tovt[0].vt_name,
          },
        ];
        console.log(item,"item")
        pathArray.push(...item);
      } 
    } else {
      console.log('else connected')
      const isAccending =
        floorIndex(selTraversibleDetails?.from_floor_id) <
        floorIndex(selTraversibleDetails?.to_floor_id);
      for (
        let i = floorIndex(selTraversibleDetails?.from_floor_id);
        isAccending
          ? i <= floorIndex(selTraversibleDetails?.to_floor_id)
          : i >= floorIndex(selTraversibleDetails?.to_floor_id);

      ) {
        const element = i;

        const selectedVTS = allVerticalTransports?.filter(
          (item) => {
            return data?.icon_id == item?.icon_id && element == floorIndex(item?.floor_plan_id)
          }
        );

        let topMostItem;
        if (i != floorIndex(selTraversibleDetails?.to_floor_id)) {
          // topMostItem = checkTopMostConnetedVT(selectedVTS, allVerticalTransports);
          topMostItem = checkTopFloorVTS(
            selectedVTS,
            isAccending,
            allVerticalTransports
          );
          topMostItem = topMostItem.filter(
            (item) => result?.vt_id !== item?.vt_id
          );

          if (topMostItem.length === 0) {
            const diffrentTypeVT = allVerticalTransports.filter(
              (item) =>
                floorIndex(item?.floor_plan_id) ===
                floorIndex(result?.floor_plan_id) &&
                result?.vt_id !== item?.vt_id
            );
            // topMostItem = checkTopMostConnetedVT(diffrentTypeVT, allVerticalTransports);
            topMostItem = checkTopFloorVTS(
              diffrentTypeVT,
              isAccending,
              allVerticalTransports
            );
            topMostItem = topMostItem.filter(
              (item) => result?.vt_id !== item?.vt_id
            );
          }
        } else {
          topMostItem = [result];
        }
        // Find the shortest VT for the current floor
        let fromData;
        if (i === floorIndex(selTraversibleDetails?.from_floor_id)) {
          fromData = selTraversibleDetails?.from;
        } else if (i == floorIndex(selTraversibleDetails?.to_floor_id)) {
          fromData = selTraversibleDetails?.to;
        } else {
          fromData = `vertical_${result?.vtd_id}`;
        }
        const indexID = floorPlans[i]?.enc_id;
        handlePontsAndEdges(indexID);
        let shortestVt = findShortestVTS(
          convertVerticalPinData(topMostItem),
          fromData
        );

        const from =
          i === floorIndex(selTraversibleDetails?.from_floor_id)
            ? selTraversibleDetails?.from
            : `vertical_${result?.vtd_id}`;
        let item = {
          floor_plan_id: shortestVt?.to_floor_id,
          from: from,
          to:
            i === floorIndex(selTraversibleDetails?.to_floor_id)
              ? selTraversibleDetails?.to
              : shortestVt?.name,
          to_vt_id: shortestVt?.vertical_transport_id,
          floor_plan: shortestVt?.floor_plan,
          vt_name: shortestVt?.vt_name,
        };
        pathArray.push(item);
        if (
          floorIndex(result?.floor_plan_id) !=
          floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          result = findHighestFloorPlanId(
            allVerticalTransports,
            item,
            isAccending
          );
          if (i === floorIndex(result?.floor_plan_id)) {
            break;
          } else {
            i = floorIndex(result?.floor_plan_id);
          }
        } else {
          break;
        }
      }

    }

    if (!(pathArray[pathArray?.length - 1]?.floor_plan_id == selTraversibleDetails?.to_floor_id)) {
      if (anySameConnection) {
        console.log("through any connection")
        // console.log(anyConnection,"anyConnection")
        let changevts = anySameConnection.map((item) => {
          let convertedVt = convertVerticalPinData([item.transportItem])
          return {floor:item.floor,convertedVt:convertedVt[0]}
        })
        // console.log(changevts, "changevts")
          function mergeArray(arr) {
            // Don't touch the first and last items, so extract them first
            // const firstItem = arr[0];
            // const lastItem = arr[arr.length - 1];
            
            // // Handle the middle items
            // const middleItems = arr.slice(1, -1);
        
            // Use a map to track floors and merge them
            const floorMap = new Map();
        
            arr.forEach(item => {
                const floor = item.floor;
                if (floorMap.has(floor)) {
                    // If the floor is already in the map, merge the `convertedVt` data
                    const existing = floorMap.get(floor);
                    // Convert convertedVt into an array and merge
                    existing.convertedVt = [].concat(existing.convertedVt, item.convertedVt);
                } else {
                    // Otherwise, add the floor as a new entry
                    floorMap.set(floor, { ...item });
                }
            });
        
            // Recreate the array with first, merged middle items, and last
            // const mergedArray = [firstItem, ...floorMap.values(), lastItem];
            const mergedArray = [...floorMap.values()];
        
            return mergedArray;
        }
        let resultarray = mergeArray(changevts)
        // console.log(resultarray,"resultarray")
        let itemarray = resultarray.map((item, i) => {
          // console.log(i,"i")
          if (i == 0) {
            if (item.convertedVt.length) {
              // let itemFind = resultarray[i+1].convertedVt.find((arritems) => item.convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              let itemFind = item.convertedVt.find((arritems) => resultarray[i+1].convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${itemFind.enc_id}`,
                to_vt_id: itemFind.enc_id,
                floor_plan: item.floor,
                vt_name: itemFind.vt_name,
              }
            } else {
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${item.convertedVt.enc_id}`,
                to_vt_id: item.convertedVt.enc_id,
                floor_plan: item.floor,
                vt_name: item.convertedVt.vt_name,
              }
            }
          } else if (i == resultarray.length - 1) {
            if (item.convertedVt.length) {
              // let itemFind = resultarray[i-1].convertedVt.find((arritems) => item.convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              let itemFind = item.convertedVt.find((arritems) => resultarray[i+1].convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${itemFind.enc_id}`,
                to_vt_id: itemFind.enc_id,
                floor_plan: item.floor,
                vt_name: itemFind.vt_name,
              }
            } else {
            return {
              floor_plan_id:item.floor,
              from: selTraversibleDetails.to,
              to: `vertical_${item.convertedVt.enc_id}`,
              to_vt_id: item.convertedVt.enc_id,
              floor_plan: item.floor,
              vt_name: item.convertedVt.vt_name,
            }
            }
          } else {
            if (item.convertedVt.length) {
              return {
                floor_plan_id:item.floor,
                from: `vertical_${item.convertedVt[0].enc_id}`,
                to: `vertical_${item.convertedVt[1].enc_id}`,
                to_vt_id: item.convertedVt[1].enc_id,
                floor_plan: item.floor,
                vt_name: item.convertedVt[1].vt_name,
              }
            } else {
              return false
            }
          }
        })
        // console.log(,"itemarray")
        pathArray = itemarray.filter((item) => item?.floor_plan);
        if (fromvt[0]?.name != itemarray[0]?.to) {
          toast.warning(
            "Sorry! The chosen vertical transport has no connection to your destination. we will find another way"
          );
        }
      }else if (anyConnection) {
        console.log("through any connection")
        // console.log(anyConnection,"anyConnection")
        let changevts = anyConnection.map((item) => {
          let convertedVt = convertVerticalPinData([item.transportItem])
          return {floor:item.floor,floorName:item.floor_plan_name,convertedVt:convertedVt[0]}
        })
        // console.log(changevts, "changevts")
        function getFilteredArray(array) {
          const lastObject = array[array.length - 1];
          const lastVt = lastObject.convertedVt;
      
          // Check if the last object's convertedVt is an object with a vertical_transport_id
          if (typeof lastVt === "object" && lastVt.vertical_transport_id) {
              const verticalTransportId = lastVt.vertical_transport_id;
      
              // Iterate through the array to find a match
              for (let i = 0; i < array.length - 1; i++) {
                const convertedVt = array[i].convertedVt;
                const prevvt = array[i - 1] ? array[i - 1]?.convertedVt : []
      
                  // Check if convertedVt is an array or object and find matching vertical_transport_id
                  if (
                      (Array.isArray(convertedVt) &&
                          convertedVt.some(vt => vt.vertical_transport_id === verticalTransportId)) ||
                      (typeof convertedVt === "object" && convertedVt.vertical_transport_id === verticalTransportId)
                  ) {
                    let id ;
                    if (Array.isArray(prevvt)) {
                      let index = prevvt.length - 1
                      id = prevvt[index] ? prevvt[index].vertical_transport_id : null
                    } else {
                      id = prevvt.vertical_transport_id
                      // console.log(convertedVt,prevvt.vertical_transport_id,"convertedVt find")
                    }
                    if (id) {
                      array[i].convertedVt = Array.isArray(convertedVt)
                      ? convertedVt.filter((item) => [verticalTransportId, id].includes(item.vertical_transport_id))
                      : convertedVt;
                      return [...array.slice(0, i + 1), lastObject];
                    }
                  }
              }
          }
      
          // Return the last object only if no matches are found
          return array;
        }
        
        function mergeArray(arr) {
            console.log(arr,"mergeArray arr")
            // Don't touch the first and last items, so extract them first
            // const firstItem = arr[0];
            // const lastItem = arr[arr.length - 1];
            
            // // Handle the middle items
            // const middleItems = arr.slice(1, -1);
        
            // Use a map to track floors and merge them
            const floorMap = new Map();
        
            arr.forEach(item => {
                const floor = item.floor;
                // const floorName = item.floorName;
                if (floorMap.has(floor)) {
                    // If the floor is already in the map, merge the `convertedVt` data
                    const existing = floorMap.get(floor);
                    // Convert convertedVt into an array and merge
                    existing.convertedVt = [].concat(existing.convertedVt, item.convertedVt);
                } else {
                    // Otherwise, add the floor as a new entry
                    floorMap.set(floor, { ...item });
                }
            });
        
            // Recreate the array with first, merged middle items, and last
            // const mergedArray = [firstItem, ...floorMap.values(), lastItem];
            const mergedArray = [...floorMap.values()];
          const result = getFilteredArray(mergedArray);
          console.log(result,"merged array result")
            return result;
            // return mergedArray;
        }
        let resultarray = mergeArray(changevts)
        console.log(resultarray,"resultarray")
        let itemarray = resultarray.map((item, i) => {
          // console.log(i,"i")
          if (i == 0) {
            if (item.convertedVt.length) {
              // let itemFind = resultarray[i+1].convertedVt.find((arritems) => item.convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              let itemFind = item.convertedVt.find((arritems) => resultarray[i+1].convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${itemFind.enc_id}`,
                to_vt_id: itemFind.enc_id,
                floor_plan: item.floor,
                vt_name: itemFind.vt_name,
              }
            } else {
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${item.convertedVt.enc_id}`,
                to_vt_id: item.convertedVt.enc_id,
                floor_plan: item.floor,
                vt_name: item.convertedVt.vt_name,
              }
            }
          } else if (i == resultarray.length - 1) {
            if (item.convertedVt.length) {
              // let itemFind = resultarray[i-1].convertedVt.find((arritems) => item.convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              let itemFind = item.convertedVt.find((arritems) => resultarray[i+1].convertedVt.some((arr2items) => arr2items.vertical_transport_id === arritems.vertical_transport_id))
              return {
                floor_plan_id:item.floor,
                from: selTraversibleDetails.from,
                to: `vertical_${itemFind.enc_id}`,
                to_vt_id: itemFind.enc_id,
                floor_plan: item.floor,
                vt_name: itemFind.vt_name,
              }
            } else {
            return {
              floor_plan_id:item.floor,
              from: selTraversibleDetails.to,
              to: `vertical_${item.convertedVt.enc_id}`,
              to_vt_id: item.convertedVt.enc_id,
              floor_plan: item.floor,
              vt_name: item.convertedVt.vt_name,
            }
            }
          } else {
            if (item.convertedVt.length) {
              return {
                floor_plan_id:item.floor,
                from: `vertical_${item.convertedVt[0].enc_id}`,
                to: `vertical_${item.convertedVt[1].enc_id}`,
                to_vt_id: item.convertedVt[1].enc_id,
                floor_plan: item.floor,
                vt_name: item.convertedVt[1].vt_name,
              }
            } else {
              return false
            }
          }
        })
        console.log(itemarray,"itemarray")
        pathArray = itemarray.filter((item) => item?.floor_plan);
        if (fromvt[0]?.name != itemarray[0]?.to) {
          toast.warning(
            "Sorry! The chosen vertical transport has no connection to your destination. we will find another way"
          );
        }
      }
    }

    setSelTraversibleDetails((prev) => ({
      ...prev,
      pathArray,
      is_miltiple: true,
    }));
    handlePontsAndEdges(selTraversibleDetails?.from_floor_id);
    toggleVerticalClose();
    if (
      pathArray[pathArray?.length - 1]?.floor_plan_id ==
      selTraversibleDetails?.to_floor_id
    ) {
      showPath(pathArray[0]?.from, pathArray[0]?.to);
      localStorage.setItem("pathLength",pathArray.length)
      localStorage.setItem("currentLength",1)
    } else {
      setSelTraversibleDetails((prev) => ({
        ...prev,
        pathArray,
        is_miltiple: false,
      }));
      // toast.warning(
      //   "Sorry! The chosen vertical transport has no connection to your destination."
      // );
    }
  };






  const handleEndDirectionclick = () => {
    setSelTraversibleDetails();
    setFloorID(selFloorPlanDtls?.enc_id);
    removeFabricObjectsBId(canvas, "short_path");
    removeFabricObjectsByName(canvas, "highlight_node");
    renderTraversiblePaths();
  };

  const handleWheelchairCheck = (e) => {
    const isWheelchair = e.target.checked;
    if (isWheelchair) {
      const verticalTransport = verticalTransports?.map((item) => ({
        ...item,
        noAccess: item?.is_wheelchair === 1 ? false : true,
      }));
      setVerticalTransports(verticalTransport);
      const sortWithWheelChair = allVerticalTransports?.filter(
        (item) => item?.is_wheelchair === 1
      );
      setAllVerticalTransports(sortWithWheelChair);
    } else {
      const verticalTransport = verticalTransports?.map((item) => ({
        ...item,
        noAccess: false,
      }));
      getProjectById();
      setTimeout(() => {
        setVerticalTransports(verticalTransport);
      }, 1000);
    }
    // console.log(allVerticalTransports,'allVerticalTransports');
  };

  const switchFloor = async (id, type) => {
    setToolTraversible("Draw");
    const nextFloor = floorPlanSelect?.find((item) => id == item?.enc_id);
    console.log(nextFloor);
    setDropDownFloor({
      value: nextFloor?.enc_id,
      label: nextFloor?.floor_plan,
      id: nextFloor?.enc_id,
      plan: nextFloor?.plan,
      dec_id: nextFloor?.dec_id,
    });
    let returnValue;
    returnValue = await switchFloorPlan(nextFloor?.enc_id, type);
    return returnValue;
  };

  const switchFloorPlan = async (floor_id, type) => {
    console.log(type, "type");
    let modifiedData;
    let value;
    try {
      const response = await getRequest(`floor-plan/${floor_id}`);
      const data = response.data ?? [];

      value = {
        ...data,
        floor_plan: data?.floor_plan,
        refImg: data?.cropped_path_base64,
        plan: data?.cropped_image,
        border_color: data?.border_color,
        fill_color: data?.fill_color,
        border_thick: data?.border_thick,
      };
      const decodedTexts = JSON.parse(data?.text);
      var decodedString = JSON.parse(data?.tracings);
      var arrayOfObjects = JSON.parse(decodedString);
      var arrayOfTexts = decodedTexts ? JSON.parse(decodedTexts) : "";
      var decodedCircle = JSON.parse(data?.circle_data);
      var objectCircle = JSON.parse(decodedCircle);

      modifiedData = data?.vertical_transports?.map((el) => ({
        ...el,
        position: el?.positions ? JSON.parse(el?.positions) : "",
      }));
      // console.log(modifiedData, "modifiedData");
      setTexts(arrayOfTexts ?? []);
      setVerticalTransports(modifiedData);
      setTracings(arrayOfObjects ?? []);
      setTracingCircle(objectCircle ?? [])
      setSelFloorPlanDtls(value);
      if (type == "switch") {
        const select_Vt = modifiedData?.find(
          (item) =>
            selTraversibleDetails?.to_item?.vertical_transport_id ==
            item?.vertical_transport_id
        );
        console.log(select_Vt, "select_Vt");
        const vt_Value = `vertical_${select_Vt?.enc_id}`;
        handleTraversibleData(
          value,
          graph,
          setSelTraversibleDetails,
          findShortestPath,
          renderTraversiblePaths,
          selTraversibleDetails,
          "switchFloor",
          vt_Value
        );
        let toData = {
          name: selTraversibleDetails?.to,
        };
      } else {
        handleTraversibleData(
          value,
          graph,
          setSelTraversibleDetails,
          findShortestPath,
          renderTraversiblePaths,
          selTraversibleDetails,
          "switchFloor"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      getLocationList(floor_id);
      getProductList(floor_id);
      getBeaconList(floor_id);
      getAmenityList(floor_id);
      getSafetyList(floor_id);
      // getAdvertisementList()
      return value;
    }
  };

  // function removeDuplicates(array) {
  //   const seen = new Set();
  //   return array.filter((obj) => {
  //     const duplicate = seen.has(obj.icon_id);
  //     seen.add(obj.icon_id);
  //     return !duplicate;
  //   });
  // }

  function removeDuplicates(arr) {
    const uniqueObjects = {};

    arr.forEach((obj) => {
      if (!(obj.icon_id in uniqueObjects)) {
        uniqueObjects[obj.icon_id] = obj;
      } else if (obj.is_wheelchair === 1) {
        uniqueObjects[obj.icon_id] = obj;
      }
    });

    return Object.values(uniqueObjects);
  }

  useEffect(() => {
    if (selTraversibleDetails?.post === true) {
      uploadTraversibleData(
        selFloorPlanDtls,
        graph,
        handleEnableDisable,
        getProjectById
      );
    }
  }, [selTraversibleDetails]);

  const onExitClick = () => {
    if (isDirty) {
      swal({
        title: "Are you sure you want to exit?",
        text: "You currently have pending changes. If you exit they'll be saved as a draft.",
        icon: "warning",
        buttons: [
          {
            text: "No",
            value: "No",
            visible: true,
            className: "btn-danger",
            closeModal: true,
          },
          {
            text: "Yes",
            value: "Yes",
            visible: true,
            className: "btn-success",
            closeModal: true,
          },
        ],
      }).then((value) => {
        switch (value) {
          case "Yes":
            resetCanvasTransform();
            if (activeTab === "products" && addNewProduct) {
              document.getElementById("productSubmitBtn")?.click();
            } else if (activeTab === "locations" && addNewLocation) {
              document.getElementById("locationSubmitBtn")?.click();
            } else if (activeTab === "settings") {
              document.getElementById("prpjectSettingsBtn")?.click();
            } else if (activeTab === "floorDetails" && addNewFloor) {
              document.getElementById("FloorPlanAddBtn")?.click();
            } else if (activeTab === "beacons" && addNewQrCodeBeacon) {
              document.getElementById("beaconSubmitBtn")?.click();
            } else if (activeTab === "safety" && addNewSafety) {
              document.getElementById("safetySubmitBtn")?.click();
            } else if (activeTab === "amenitys" && addNewAmenity) {
              document.getElementById("amenitySubmitBtn")?.click();
            } else if (activeTab === "verticalTransport" && addNewVertical) {
              document.getElementById("transportSubmitBtn")?.click();
            } else if (activeTab === "advertisements" && addNewAd) {
              document.getElementById("advertisementSubmitBtn")?.click();
            }
            resetCanvasTransform();
            setTimeout(() => {
              navigate(`/project-list`);
            }, 800);
            break;
          default:
            break;
        }
      });
    } else {
      resetCanvasTransform();
      navigate(`/project-list`);
    }
  };

  const getProjectById = async () => {
    // setLoading(true);
    try {
      const response = await getRequest(`project/${id}`);
      const data = response.data ?? [];
      let value = {
        ...data,
        width: data?.width ? Number(data?.width) : null,
        height: data?.height ? Number(data?.height) : null,
      };
      setProjectSettingData(value);
      setProjectSettings(value);
      setTracingIntialValue(value);
      const sort = value?.vt_details?.sort(
        (a, b) => b.floor_plan_id - a.floor_plan_id
      );
      setAllVerticalTransports(sort);
      setAllPointsAndEdges(value?.fp_details);
      setCroppedImage(value.logo ? image_url + value.logo : null);
      handleEnableDisable();
      canvasBackGroundColor(value?.background_color, canvas);
    } catch (error) {
      // console.log(error);
    } finally {
      // setLoading(false);
      setSavingTimer(false);
    }
  };
  const getFloorsList = async (type) => {
    getFloors(id, setFloorPlans, setFloorPlansPathSort);
  };

  const PlanExpiryDetails = async () => {
    try {
      const reqUrl = `check-package/${id}`;
      const response = await getRequest(reqUrl);
      console.log(response, "delete");

      const data = response?.data ?? [];
      setPlanDetails(data);
      console.log(data, "check-package");

      if (
        data?.plan?.basic_expired == 1 ||
        data?.plan?.additional_expired == 1 ||
        data?.plan?.additional_count == 1
      ) {
        setStripeModal(true);
      } else {
        publishYesClick();
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const handlePublish = async (proId, values, isAccepted, ip) => {
    let data = {
      id: Number(id),
      // type: Number(typeId)
    };
    try {
      const reqUrl = `publish`;
      const response = await postRequest(reqUrl, data);
      handleEnableDisable();
      setLoadingPublish(false);
      getProjectById();
      if (typeId > 1) {
        onSideBarIconClick(activeTab);
      }
      if (typeId == 2) {
        getFloorsList();
      }
      if (typeId == 3) {
        getLocationList(floorID);
      }
      if (typeId == 4) {
        getProductList(floorID);
      }
      if (typeId == 5) {
        getBeaconList(floorID);
      }
      if (typeId == 6) {
        getAmenityList(floorID);
      }
      if (typeId == 7) {
        getSafetyList(floorID);
      }
      if (typeId == 8) {
        getVerticalTransportList(floorID);
      }
      if (typeId == 9) {
        getAdvertisementList();
      }
      const result = response?.response?.data ?? [];
      if (response?.type === 1) {
        toast.success(result?.message);
        // navigate(`/view-floor/${id}`);
      } else {
        toast.error(response?.errormessage);
      }
    } catch (error) {
      // console.log(error);
    }
    return;
  };

  const publishYesClick = (proId, values, isAccepted, ip) => {
    if (activeTab === "products" && addNewProduct) {
      document.getElementById("productSubmitBtn")?.click();
    } else if (activeTab === "locations" && addNewLocation) {
      document.getElementById("locationSubmitBtn")?.click();
    } else if (activeTab === "settings") {
      document.getElementById("prpjectSettingsBtn")?.click();
    } else if (activeTab === "floorDetails" && addNewFloor) {
      document.getElementById("FloorPlanAddBtn")?.click();
    } else if (activeTab === "beacons" && addNewQrCodeBeacon) {
      document.getElementById("beaconSubmitBtn")?.click();
    } else if (activeTab === "safety" && addNewSafety) {
      document.getElementById("safetySubmitBtn")?.click();
    } else if (activeTab === "amenitys" && addNewAmenity) {
      document.getElementById("amenitySubmitBtn")?.click();
    } else if (activeTab === "verticalTransport" && addNewVertical) {
      document.getElementById("transportSubmitBtn")?.click();
    } else if (activeTab === "advertisements" && addNewAd) {
      document.getElementById("advertisementSubmitBtn")?.click();
    }

    setTimeout(() => {
      handlePublish(proId, values, isAccepted, ip);
    }, 800);
  };

  useEffect(() => {
    handleEnableDisable();
  }, [typeId, floorID]);

  const handleDiscard = async () => {
    let data = {
      id: Number(id),
    };
    try {
      const reqUrl = `discard`;
      const response = await postRequest(reqUrl, data);
      console.log(response, typeId);
      handleEnableDisable();
      if (typeId == 1) {
        getProjectById();
        setFloorID(null);
        getFloorDropdown("discard");
      }
      if (typeId > 1) {
        onSideBarIconClick(activeTab);
        setFloorID(null);

        getFloorDropdown("discard");
        let floor;
        setSelFloorPlanDtls((prev) => {
          floor = prev;
          return prev;
        });
        // setAddNewFloor(false);
        console.log(floor);
        // obj=''
      }
      if (typeId == 2) {
        getFloorsList();
      }
      if (typeId == 3) {
        getLocationList(floorID);
        // setAddNewLocation(false);
        // onSideBarIconClick(activeTab)
      }
      if (typeId == 4) {
        getProductList(floorID);
        // setAddNewProduct(false);
      }
      if (typeId == 5) {
        getBeaconList(floorID);
        // setAddNewQrCodeBeacon(false);
      }
      if (typeId == 6) {
        getAmenityList(floorID);
        // setAddNewAmenity(false);
      }
      if (typeId == 7) {
        getSafetyList(floorID);
        // setAddNewSafety(false);
      }
      if (typeId == 8) {
        getVerticalTransportList(floorID);
        // setAddNewVertical(false);
      }
      if (typeId == 9) {
        getAdvertisementList();
        // setAddNewVertical(false);
      }
      revertPackage(id);
      const result = response?.response?.data ?? [];
      toast.success(result?.message);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleEnableDisable = async () => {
    EnableDisable(id, setIsPublish, setIsDiscard);
  };

  const handleSubmitProject = async (values, setFieldError) => {
    const formdata = new FormData();
    if (croppedImage?.startsWith("data:image")) {
      const base64Logo = croppedImage;
      formdata.append(`logo`, base64Logo);
    } else {
      const trimmedImageUrl = values?.logo
        ? values?.logo?.replace(image_url, "")
        : "";
      formdata.append(`logo`, trimmedImageUrl);
    }
    formdata.append(
      `customer_id`,
      values?.enc_customer_id ?? getCurrentUser()?.user?.common_id
    );
    formdata.append(`project_name`, values?.project_name);
    // formdata.append(`location_tags`, values?.location_tags == null || values?.location_tags == '' ? [] : values?.location_tags);
    // formdata.append(`product_tags`, values?.product_tags == null || values?.product_tags == '' ? [] : values?.product_tags);
    formdata.append(`background_color`, values?.background_color);
    formdata.append(`fill_color`, values?.fill_color);
    formdata.append(`border_thick`, values?.border_thick);
    formdata.append(`border_color`, values?.border_color);
    formdata.append(`inactive_color`, values?.inactive_color);
    formdata.append(`location_color`, values?.location_color);
    formdata.append(`product_color`, values?.product_color);
    formdata.append(`start_color`, values?.start_color);
    formdata.append(`beacon_color`, values?.beacon_color);
    formdata.append(`amenity_color`, values?.amenity_color);
    formdata.append(`safety_color`, values?.safety_color);
    formdata.append(`level_change_color`, values?.level_change_color);
    formdata.append(`navigation_color`, values?.navigation_color);
    formdata.append(
      `error_reporting_email`,
      values?.error_reporting_email ?? ""
    );
    formdata.append(`navigation_thick`, values?.navigation_thick ?? "3");

    if (values?.enc_id) {
      formdata.append(`_method`, "PUT");
      formdata.append(`id`, values?.enc_id);
      formdata.append(`is_published`, "0");
      formdata.append(`discard`, "1");
      formdata.append(`publish`, "1");
    }
    try {
      const reqUrl = values?.enc_id ? `project/${values?.enc_id}` : `project`;
      const response = await postRequest(reqUrl, formdata, true);
      console.log(response);
      const data = response.response?.data ?? [];
      if (response.type === 1) {
        getProjectById();
        toggle();
        toast.success(data?.message);
      } else {
        SetBackEndErrorsAPi(response, setFieldError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onpanClick = () => {
    setPanTool(!panTool);
    if (activeTab == "floorDetails") {
      setToolActive("");
      setSelTracingId();
    }
    if (activeTab == "traversable") {
      setToolTraversible();
      stopPathDrawing();
    }
  };

  return (
    <>
      <ProjectHeaderDiv
        projectSettings={projectSettings}
        savingTimer={savingTimer}
        isDiscard={isDiscard}
        isPublish={isPublish}
        projectSettingData={projectSettingData}
        isDirty={isDirty}
        discardClick={discardClick}
        publishClick={publishClick}
        toggle={toggle}
        onExitClick={onExitClick}
        handleDiscard={handleDiscard}
        publishYesClick={publishYesClick}
      />
      <div className="bp-container  ">
        {isCommonSidebarVisible && (
          <BPCommonSideBar
            activeTab={activeTab}
            onIconClick={onSideBarIconClick}
            floorPlans={floorPlans}
            selLocationDtls={selLocationDtls}
            selProductDtls={selProductDtls}
            selFloorPlanDtls={selFloorPlanDtls}
            selBeaconDtls={selBeaconDtls}
            selAmenityDtls={selAmenityDtls}
            selSafetyDtls={selSafetyDtls}
            selVerticalDtls={selVerticalDtls}
            setTypeId={setTypeId}
            projectSettings={projectSettings}
          />
        )}
        {!isCommonSidebarVisible && (
          <>
            {activeTab === "floorDetails" && (
              <FloorPlanDtlsBar
                floorPlans={floorPlans}
                setFloorPlans={setFloorPlans}
                setFloorPlansPathSort={setFloorPlansPathSort}
                selFloorPlanDtls={selFloorPlanDtls}
                setSelFloorPlanDtls={setSelFloorPlanDtls}
                tracings={tracings}
                setTracings={setTracings}
                setTracingCircle={setTracingCircle}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setTempPolygon={setTempPolygon}
                id={id}
                getFloorDropdown={getFloorDropdown}
                setProjectSettings={setProjectSettings}
                projectSettings={projectSettings}
                setLocations={setLocations}
                locations={locations}
                getLocationList={getLocationList}
                getProductList={getProductList}
                getBeaconList={getBeaconList}
                getAmenityList={getAmenityList}
                getSafetyList={getSafetyList}
                getVerticalTransportList={getVerticalTransportList}
                setProducts={setProducts}
                products={products}
                setFloorID={setFloorID}
                addNewFloor={addNewFloor}
                setAddNewFloor={setAddNewFloor}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setDropDownFloor={setDropDownFloor}
                handleTraversibleData={handleTraversibleData}
                graph={graph}
                setVerticalTransports={setVerticalTransports}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                getFloorsList={getFloorsList}
                handleEnableDisable={handleEnableDisable}
                canvasBackgroundImageHandler={canvasBackgroundImageHandler}
                zoomInOut={zoomInOut}
                setZoomInOut={setZoomInOut}
                texts={texts}
                setTexts={setTexts}
                setToolActive={setToolActive}
                floorID={floorID}
                canvasContainerRef={canvasContainerRef}
                clearPinsList={clearPinsList}
                removePins={removePins}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                onMapDivClick={onMapDivClick}
                getProjectById={getProjectById}
                setTracingIntialValue={setTracingIntialValue}
                setPanTool={setPanTool}
                totalPinCount={totalPinCount}
                resetCanvasTransform={resetCanvasTransform}
                setTextStyleValue={setTextStyleValue}
                renderTracings={renderTracings}
                handlezoomPost={handlezoomPost}
                setLoadingSacle={setLoadingSacle}
                setFloorPlanModal={setFloorPlanModal}
                setSelImageOrSvgValues={setSelImageOrSvgValues}
                removeFabricObjectsByName={() => removeFabricObjectsByName(canvas, "tracing")}
                renderTracingCircles={renderTracingCircles}
                renderTexts={renderTexts}
                getSvgFileAsRefImage={getSvgFileAsRefImage}
              />
            )}
            {activeTab === "settings" && (
              <PSSideBar
                projectSettings={projectSettings}
                setProjectSettings={setProjectSettings}
                id={id}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                getFloorPlanByid={getFloorPlanByid}
                setFloorID={setFloorID}
                setDropDownFloor={setDropDownFloor}
                getFloorDropdown={getFloorDropdown}
                floorPlanSelect={floorPlanSelect}
                getProjectById={getProjectById}
                setCroppedImage={setCroppedImage}
                croppedImage={croppedImage}
                setLoading={setLoading}
                loading={loading}
                projectSettingData={projectSettingData}
                setProjectSettingData={setProjectSettingData}
                floorID={floorID}
                setCommonSidebarVisible={setCommonSidebarVisible}
                canvasBackGroundColor={canvasBackGroundColor}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                canvas={canvas}
              />
            )}
            {activeTab === "locations" && (
              <LocationsSideBar
                locations={locations}
                setLocations={setLocations}
                selLocationDtls={selLocationDtls}
                setSelLocationDtls={setSelLocationDtls}
                selFloorPlanDtls={selFloorPlanDtls}
                id={id}
                floorID={floorID}
                floorIDs={floorIDs}
                projectSettings={projectSettings}
                addNew={addNewLocation}
                setAddNew={setAddNewLocation}
                hours={hours}
                setHours={setHours}
                promotions={promotions}
                setPromotions={setPromotions}
                isBoundary={isBoundary}
                setIsBoundary={setIsBoundary}
                getLocationList={getLocationList}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                boundaryAttributes={boundaryAttributes}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                totalPinsUsed={totalPinsUsed}
                totalPinCount={totalPinCount}
                setFloorID={setFloorID}
                locationList={locationList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                floorPlanSelect={floorPlanSelect}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                stopPathDrawing={stopPathDrawing}
                resetCanvasTransform={resetCanvasTransform}
                canvas={canvas}
                onEditLocation={onEditLocation}
                setStoredObjects={setStoredObjects}
              />
            )}

            {activeTab === "products" && (
              <ProductSideBar
                products={products}
                setProducts={setProducts}
                selProductDtls={selProductDtls}
                setSelProductDtls={setSelProductDtls}
                selFloorPlanDtls={selFloorPlanDtls}
                id={id}
                floorID={floorID}
                floorIDs={floorIDs}
                setAddNew={setAddNewProduct}
                addNew={addNewProduct}
                projectSettings={projectSettings}
                getProductList={getProductList}
                images={images}
                setImages={setImages}
                specifications={specifications}
                setSpecifications={setSpecifications}
                onEditProduct={onEditProduct}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                totalPinsUsed={totalPinsUsed}
                totalPinCount={totalPinCount}
                setFloorID={setFloorID}
                productList={productList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                dropDownFloor={dropDownFloor}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                stopPathDrawing={stopPathDrawing}
                resetCanvasTransform={resetCanvasTransform}
                canvas={canvas}
                setStoredObjects={setStoredObjects}
              />
            )}
            {activeTab === "beacons" && (
              <QrcodeBeaconSideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewQrCodeBeacon}
                addNew={addNewQrCodeBeacon}
                selBeaconDtls={selBeaconDtls}
                setSelBeaconDtls={setSelBeaconDtls}
                projectSettings={projectSettings}
                beacons={beacons}
                setBeacons={setBeacons}
                selFloorPlanDtls={selFloorPlanDtls}
                getBeaconList={getBeaconList}
                onEditBeacon={onEditBeacon}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                totalPinsUsed={totalPinsUsed}
                totalPinCount={totalPinCount}
                setFloorID={setFloorID}
                beaconList={beaconList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                stopPathDrawing={stopPathDrawing}
                resetCanvasTransform={resetCanvasTransform}
                prefilledMessage={prefilledMessage}
                setPrefilledMessage={setPrefilledMessage}
                setStoredObjects={setStoredObjects}
              />
            )}

            {activeTab === "amenitys" && (
              <AmenitySideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewAmenity}
                addNew={addNewAmenity}
                selAmenityDtls={selAmenityDtls}
                setSelAmenityDtls={setSelAmenityDtls}
                projectSettings={projectSettings}
                amenities={amenities}
                setAmenities={setAmenities}
                selFloorPlanDtls={selFloorPlanDtls}
                getAmenityList={getAmenityList}
                onEditAmenity={onEditAmenity}
                setAminityIcons={setAminityIcons}
                aminityIcons={aminityIcons}
                // getAmenityIconDropDown={getAmenityIconDropDown}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                setFloorID={setFloorID}
                amenityList={amenityList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                resetCanvasTransform={resetCanvasTransform}
                setStoredObjects={setStoredObjects}
              />
            )}
            {activeTab === "safety" && (
              <SafetySideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewSafety}
                addNew={addNewSafety}
                selSafetyDtls={selSafetyDtls}
                setSelSafetyDtls={setSelSafetyDtls}
                projectSettings={projectSettings}
                safeties={safeties}
                setSafeties={setSafeties}
                selFloorPlanDtls={selFloorPlanDtls}
                getSafetyList={getSafetyList}
                onEditSafety={onEditSafety}
                setSafetyIcons={setSafetyIcons}
                safetyIcons={safetyIcons}
                getSafetyIconDropDown={getSafetyIconDropDown}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                setFloorID={setFloorID}
                safetyList={safetyList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                resetCanvasTransform={resetCanvasTransform}
                setStoredObjects={setStoredObjects}
              />
            )}

            {activeTab === "traversable" && (
              <TraversableSideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewTraversablePath}
                addNew={addNewTraversablePath}
                projectSettings={projectSettings}
                selFloorPlanDtls={selFloorPlanDtls}
                options={dropValues}
                setSelTraversibleDetails={setSelTraversibleDetails}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                selTraversibleDetails={selTraversibleDetails}
                toggleVertical={toggleVertical}
                switchFloor={switchFloor}
                switchFloorPlan={switchFloorPlan}
                graph={graph}
                verticalTransports={verticalTransports}
                setCommonSidebarVisible={setCommonSidebarVisible}
                showPath={showPath}
                allVerticalTransports={allVerticalTransports}
                setFloorID={setFloorID}
                handleEndDirectionclick={handleEndDirectionclick}
                setPanTool={setPanTool}
                canvas={canvas}
                setSelectedPaths={setSelectedPaths}
                generateAutoConnections={generateAutoConnections}
              />
            )}
            <VerticalTransportModal
              modal={modalVertical}
              setModal={setModalVertical}
              toggle={toggleVertical}
              close={toggleVerticalClose}
              verticalTransport={removeDuplicates(verticalTransports)}
              // verticalTransport={removeDuplicates(allVerticalTransports)}
              ChangeSvgColorPassingBE={ChangeSvgColorPassingBE}
              onSelectVerticalTransport={onSelectVerticalTransport}
              allVerticalTransports={allVerticalTransports}
              destinationData={floorPlanSelect?.find(
                (item) => item?.enc_id == selTraversibleDetails?.to_floor_id
              )}
              currentFloorVTS={verticalTransports}
              handleWheelchairCheck={handleWheelchairCheck}
            />

            {activeTab === "verticalTransport" && (
              <VerticalTransportSideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewVertical}
                addNew={addNewVertical}
                selVerticalDtls={selVerticalDtls}
                setselVerticalDtls={setselVerticalDtls}
                projectSettings={projectSettings}
                setVerticalTransportlist={setVerticalTransportlist}
                verticalTransportlist={verticalTransportlist}
                selFloorPlanDtls={selFloorPlanDtls}
                setSelFloorPlanDtls={setSelFloorPlanDtls}
                getVerticalTransportList={getVerticalTransportList}
                onEditVerticaltransport={onEditVerticaltransport}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setVerticalFloorId={setVerticalFloorId}
                verticalFloorId={verticalFloorId}
                setVerticalIcons={setVerticalIcons}
                verticalIcons={verticalIcons}
                getVerticalTransportIconDropDown={
                  getVerticalTransportIconDropDown
                }

                getFloorPlanByid={getFloorPlanByid}
                setVerticalTransports={setVerticalTransports}
                setTracings={setTracings}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
                setFloorID={setFloorID}
                resetCanvasTransform={resetCanvasTransform}
                setStoredObjects={setStoredObjects}
              />
            )}
            {activeTab === "advertisements" && (
              <AdvertisementSideBar
                id={id}
                floorID={floorID}
                setAddNew={setAddNewAd}
                addNew={addNewAd}
                selAd={selAd}
                setSelAd={setSelAd}
                projectSettings={projectSettings}
                safeties={safeties}
                setSafeties={setSafeties}
                selFloorPlanDtls={selFloorPlanDtls}
                setAdList={setAdList}
                onEditAd={onEditAd}
                setSafetyIcons={setSafetyIcons}
                safetyIcons={safetyIcons}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}
                setSavingTimer={setSavingTimer}
                savingTimer={savingTimer}
                handleEnableDisable={handleEnableDisable}
                setFloorID={setFloorID}
                adList={adList}
                getFloorPlanByid={getFloorPlanByid}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                setCommonSidebarVisible={setCommonSidebarVisible}
                getAdvertisementList={getAdvertisementList}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setPanTool={setPanTool}
                setIsValid={setIsValid}
              />
            )}
          </>
        )}

        <div className="bp-sub-2" ref={canvasContainerRef}>
          {activeTab === "floorDetails" && addNewFloor && (
            <FloorPlanDtls
              selTracingId={selTracingId}
              projectSettings={projectSettings}
              selFloorPlanDtls={selFloorPlanDtls}
              setSelFloorPlanDtls={setSelFloorPlanDtls}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              floorID={floorID}
              onChangeTracingMetadata={onChangeTracingMetadata}
              onDeleteTracing={onDeleteTracing}
              tracingIntialValue={tracingIntialValue}
              setTracingIntialValue={setTracingIntialValue}
              onSaveTracingStyle={onSaveTracingStyle}
              toolActive={toolActive}
              setToolActive={setToolActive}
              textStyleHandler={textStyleHandler}
              selObject={selObject}
              setSelObject={setSelObject}
              stopPathDrawing={stopPathDrawing}
              activeText={activeText}
              setProjectSettings={setProjectSettings}
              setPanTool={setPanTool}
              resizeAndScaleCanvas={resizeAndScaleCanvas}
              setTextStyleValue={setTextStyleValue}
              onSelectReferanceImage={onSelectReferanceImage}
              importSvg={importSvg}
              handleDeleteRefImage={handleDeleteRefImage}
              fileKey={fileKey}
              duplicateObject={duplicateObject}
            />
          )}
          <ReferenceImageModal
            modal={floorPlanModal}
            values={selFloorPlanDtls}
            toggle={toggleReferanceImg}
            handleScaleSubmit={handleScaleSubmit}
            selImageOrSvgValues={selImageOrSvgValues}
            setSelImageOrSvgValues={setSelImageOrSvgValues}
            loading={loadingScale}
            setSelFloorPlanDtls={setSelFloorPlanDtls}
          />

          {activeTab === "traversable" && addNewTraversablePath && (
            <TraversablePathTools
              selTracingId={selTracingId}
              projectSettings={projectSettings}
              selFloorPlanDtls={selFloorPlanDtls}
              setSelFloorPlanDtls={setSelFloorPlanDtls}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              floorID={floorID}
              tracingIntialValue={tracingIntialValue}
              onSaveTracingStyle={onSaveTracingStyle}
              toolActive={toolTraversible}
              setToolActive={setToolTraversible}
              deleteSelectedObjects={deleteSelectedObjects}
              selectedPaths={selectedPaths}
              setPanTool={setPanTool}
              panTool={panTool}
              setTraversibleHistory={setTraversibleHistory}
              generateAutoConnections={generateAutoConnections}
            />
          )}
          <div
            className={`tracing-wrpr `}
            style={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
            ref={drop}
            id="map-div"
            onClick={onMapDivClick}
          >
            <div className="pin-drag-drop-div" ref={activeTab === 'products' ? dropProduct : dropLocation}>
              <CanvasDiv
                mapDivSize={mapDivSize}
                canvas={canvas}
                onScrollBarMove={onScrollBarMove}
                canvasCenter={canvasCenter}
                currentZoom={currentZoom}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                height: `${mapDivSize.height - 166}`,
                opacity: 0.3,
                pointerEvents: "none",
              }}
            />
            {!addNewFloor && (
              <div className={`bp-select-wrpr`}>
                <CustomSelect
                  options={floorPlanSelect.map((floor) => ({
                    value: floor.enc_id,
                    label: floor?.floor_plan,
                    id: floor?.enc_id,
                    plan: floor?.plan,
                    dec_id: floor?.dec_id,
                  }))}
                  setSelectedOption={
                    activeTab === "verticalTransport" && addNewVertical
                      ? onLevelDDChangeVT
                      : onLevelDDChange
                  }
                  selectedOption={dropDownFloor}
                  value={floorID}
                  from="floorplan"
                />
              </div>
            )}
            {!addNewFloor && (
              <TotalPinsDiv
                activeTab={activeTab}
                totalPinsUsed={totalPinsUsed}
                projectSettings={projectSettings}
                addNewFloor={addNewFloor}
              />
            )}
            {activeTab != "traversable" && (
              <div className="pan-bar" id="panBar">
                <div
                  className="tool-icons-pan"
                  style={{
                    // backgroundColor: panTool ? "#f0f8fc" : "#f5f6f7",
                    backgroundColor: "transparent",
                    color: panTool ? "#26a3db" : "#6a6d73",
                  }}
                  onClick={() => {
                    onpanClick();
                  }}
                >
                  <MdPanTool />
                </div>
              </div>
            )}
            <UncontrolledTooltip placement="top" target={`panBar`}>
              Pan
            </UncontrolledTooltip>
          </div>
        </div>
      </div>

      <EditProjectModal
        modal={modal}
        toggle={toggle}
        initialValues={projectSettingData}
        validationSchema={validationSchema}
        handleSubmitProject={handleSubmitProject}
      />

      <PaymentForm
        toggleStripe={toggleStripe}
        stripeModal={stripeModal}
        planDetails={planDetails}
        project_id={id}
        fromStatus={false}
        from="project"
        // handlePublish={handlePublish}
        handlePublish={publishYesClick}
        loadingPublish={loadingPublish}
        setLoadingPublish={setLoadingPublish}
      />
    </>
  );
};
export default ViewFloor;
